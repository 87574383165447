import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from 'react-toastify'
import jobApi from '../../../Shared/services/job'

const getItems = count =>
  Array.from({ length: count }, (v, k) => k).map(k => ({
    id: `id-${k}`,
    content: `item ${k}`
  }));

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;

const getItemStyle = (isDisabled, isDragging, draggableStyle) => {

    return {
        cursor:isDisabled ? "" : "pointer",
  // some basic styles to make the items look a bit nicer
  textDecoration: isDisabled ?  "line-through" : "",
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDisabled ? "#ccc" :   isDragging ? "#00b074" : "#eeeeee",
  color:isDisabled ? "white" :  isDragging ? "white" : "black",
  border:"1px solid #ccc",
  display:"flex", 
  justifyContent:"space-between",

  // styles we need to apply on draggables
  ...draggableStyle
}
};

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "grey" : "#eeeeee",
  padding: grid,

});


export const HRWorkflow = ({toggle, isOpen, steps, onSaved, workflowId}) => {
    const [saveLoading, setSaveLoading] = useState(false)
   const [items, setItems] = useState([])
   useEffect(()=>{
    console.log("ss", steps)
    setItems((prev)=> steps.map((item, index) => ({
        id:item.step.toString(), content:item.name,isDisabled:item.isDisabled
    })))
   }, [steps.length])
    const  onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    console.log("rrrr", result)
    console.log("ottmee", items)

    const reorderItems = reorder(
      items,
      result.source.index,
      result.destination.index
    );

   setItems(reorderItems)


  }
  const saveSteps = async () =>{
    setSaveLoading(true)
    try {
    const stepsToSave  = items.map((item, index) => {
        return {
            name: item.content,step: index + 1 , isDisabled: item.isDisabled
        }
    })
 
    const response = await jobApi.updateWorkflow(workflowId, stepsToSave)
    onSaved(stepsToSave)
    toast("Workflow have been saved", { type: 'success' })
    }
    catch(error) {
        toast("Something went wrong while saving the list, please try again or contact support@workabl.co", { type: 'error' })
    }    
    setSaveLoading(false)
}

  const toggleDisable = ({id, isDisabled}) => {
      setItems(prev => prev.map(item => {
          if (item.id == id) {
            return  {...item, isDisabled : !isDisabled}
          }
          else return item
      }))
  }

  return (
    <div>
   
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>HR Workflow</ModalHeader>
        <ModalBody>
        <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {items.map((item, index) => (
                <Draggable 
                
                isDragDisabled={item.isDisabled}
                key={item.id} draggableId={item.id} index={index}>
                  {(provided, snapshot) => (
                    <div
                     
                    ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        item.isDisabled,
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                        <p style={{margin:0}}>
                      {item.content}
                      </p>
                   {item.isDisabled ? <i style={{cursor:"pointer"}} onClick={() => toggleDisable(item)}  className="fas fa-check heading-default-color"></i> : 
                    <i style={{cursor:"pointer"}} onClick={() => toggleDisable(item)} className="fas fa-times"></i>
                   }   
                      
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

         </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={saveSteps}>{saveLoading ? <ClipLoader color={"#fff"} loading={true} cssOverride={{maringLeft:"1rem"}} size={30} />  : "Save"}</Button>{' '}
          <Button color="secondary" onClick={toggle}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

