import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import notification from '../../Shared/services/notification'

export const fetchNotifications = createAsyncThunk(
  'notifications',
  async (pageNumber) => {
    console.log('fetch', pageNumber)
    const response = await notification.get(pageNumber)
    console.log('dta', response.data)
    return response.data
  }
)

export const fetchLatestTenNotifications = createAsyncThunk(
  'notifications/latest',
  async (pageNumber) => {
    const response = await notification.getLatest(pageNumber)
    console.log('response', response)
    return response.data
  }
)

export const viewAllNotification = createAsyncThunk(
  'notifications/read',
  async () => {
    const response = await notification.read()
    return response.data
  }
)

export const notificationSlice = createSlice({
  name: 'counter',
  initialState: {
    error: false,
    latestTenNotificationError: false,
    latestNotificationLoading: false,
    loading: true,
    notifications: [],
    latestTenNotification: [],
    unseenNotiicationCount: 0,
    pageNumber: 1,
    total: 0,
  },
  reducers: {
    setUnseenNotificationCount: (state, action) => {
      state.unseenNotiicationCount = 0
    },
  },
  extraReducers: {
    [fetchNotifications.fulfilled]: (state, action) => {
      if (action.payload.count) {
        state.total = action.payload.count
      }
      state.error = false
      state.loading = false
      state.pageNumber = state.pageNumber + 1
      state.notifications = [...action.payload.results]
    },
    [fetchNotifications.pending]: (state, action) => {
      state.loading = true
      state.notifications = []
    },
    [fetchNotifications.rejected]: (state, action) => {
      console.log('Actionerror', action)
      state.loading = false
      state.error = 'Something went wrong'
    },
    [fetchLatestTenNotifications.fulfilled]: (state, action) => {
      state.latestTenNotificationError = false
      state.latestNotificationLoading = false

      state.latestTenNotification = [...action.payload.results]
      state.unseenNotiicationCount = action.payload.count
    },
    [fetchLatestTenNotifications.pending]: (state, action) => {
      state.latestNotificationLoading = true
      // state.notifications = []
    },
    [fetchLatestTenNotifications.rejected]: (state, action) => {
      console.log('Actionerror', action)
      state.latestNotificationLoading = false
      state.latestTenNotificationError = 'Something went wrong'
    },
    [viewAllNotification.fulfilled]: (state, action) => {
      state.unseenNotiicationCount = 0
    },
  },
})

// Action creators are generated for each case reducer function
export const { setUnseenNotificationCount } = notificationSlice.actions
export default notificationSlice.reducer
