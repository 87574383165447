/* eslint-disable react/jsx-key */
import React from "react";
import { BASE_URL } from "../constants";

const JobPreview = ({ job }) => {
  return (
    <div className="preview-listing-detail">
      <div className="mb-4">
        <div className="row">
          <div className="col-md-6">
            <h6 className="font-size-4 text-black-2 font-weight-semibold line-height-reset d-block">
              Training Type
            </h6>
            <p className="mb-0 text-gray font-size-4">
              {job.trainingType.label}
            </p>
          </div>
          <div className="col-md-6">
            <h6 className="font-size-4 text-black-2 font-weight-semibold line-height-reset d-block">
              Title
            </h6>
            <p className="mb-0 text-gray font-size-4">{job.title}</p>
          </div>
        </div>
      </div>
      <div className="mb-4">
        <div className="row">
          <div className="col-md-6">
            <h6 className="font-size-4 text-black-2 font-weight-semibold line-height-reset d-block">
              Paid
            </h6>
            {job.isPaid === "true" ? <p>Yes</p> : <p>No</p>}
          </div>
          <div className="col-md-6">
            <h6 className="font-size-4 text-black-2 font-weight-semibold line-height-reset d-block">
              Hourly Rate
            </h6>
            <p className="mb-0 text-gray font-size-4">${job.hourlyRate}/hour</p>
          </div>
        </div>
      </div>
      <div className="mb-4">
        <div className="row">
          <div className="col-md-6">
            <h6 className="font-size-4 text-black-2 font-weight-semibold line-height-reset d-block">
              Duration
            </h6>
            <p className="mb-0 text-gray font-size-4">{job.trDuration} days</p>
          </div>
          <div className="col-md-6">
            <h6 className="font-size-4 text-black-2 font-weight-semibold line-height-reset d-block">
              # of Openings
            </h6>
            <p className="mb-0 text-gray font-size-4">{job.noOfOpenings}</p>
          </div>
        </div>
      </div>
      <div className="mb-4">
        <div className="row">
          <div className="col-md-6">
            <h6 className="font-size-4 text-black-2 font-weight-semibold line-height-reset d-block">
              Training Description
            </h6>
            <p
              className="mb-0 text-gray font-size-4"
              style={{ whiteSpace: "break-spaces" }}
            >
              {job.description}
            </p>
          </div>
        </div>
      </div>
      <div className="mb--0">
        {job.startDates.length !== 0
          ? job.startDates.map((j) => {
              let dateObject = j.date;
              let date = dateObject.getDate();
              let month = dateObject.getMonth() + 1;
              let year = dateObject.getFullYear();
              let fullDate = `${month}/${date}/${year} `;
              let timeObject = j.time;
              let hours = timeObject.getHours();
              let hoursTwelve = ((hours + 11) % 12 + 1);
              let amPm;
              if(hours >= 12) {
                amPm = 'PM'
              }else {
                amPm = 'AM'
              }
              let minutes = timeObject.getMinutes();
              let seconds = timeObject.getSeconds();
              let fullTime = `${hoursTwelve}:${minutes} ${amPm}`;
              return (
                <div className="row mb-4">
                  <div className="col-md-6">
                    <h6 className="font-size-4 text-black-2 font-weight-semibold line-height-reset d-block">
                      Start Date
                    </h6>
                    <p className="mb-0 text-gray font-size-4">{fullDate}</p>
                  </div>
                  <div className="col-md-6">
                    <h6 className="font-size-4 text-black-2 font-weight-semibold line-height-reset d-block">
                      Start Time
                    </h6>
                    <p className="mb-0 text-gray font-size-4">{fullTime}</p>
                  </div>
                </div>
              );
            })
          : null}
      </div>
      <div className="mb-4">
        <label
          htmlFor="my-select"
          className="font-size-4 text-black-2 font-weight-semibold line-height-reset d-block"
        >
          Work place images
        </label>
        <div className="row">
          {job.photos.map((photo, index) => {
            return (
              <div className="col-xs-4 mb-4">
                <img
                  key={index}
                  src={`${BASE_URL}${photo}`}
                  alt=""
                  height="100"
                  width="100"
                />
              </div>
            );
          })}
        </div>
      </div>
      <div className="mb-4">
        <label
          htmlFor="my-select"
          className="font-size-4 text-black-2 font-weight-semibold line-height-reset d-block"
        >
          Skills
        </label>
        <ul className="list-unsyled d-flex flex-wrap">
          {job.skills.map((skill, index) => {
            return (
              <li
                className="col-xs-4 mb-4 mr-3"
                key={index}
                style={{
                  textTransform: "capitalize",
                }}
              >
                <span className="btn-blue px-3 py-2" style={{}}>
                  {skill}
                </span>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default JobPreview;
