import React from "react";
import { Link } from "react-router-dom";
const MenuItem = ({ title, path, icon, className }) => {
  return (
    <li className={className}>
      <Link
        to={path}
        className="pl-6 pr-2 py-1 my-5 font-weight-semibold flex-y-center"
      >
        <i className={`icon ${icon} mr-4`}></i>
        {title}
      </Link>
    </li>
  );
};
export default MenuItem;
