/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from "react";
import "../../dashboard/Dashboard";
import { formatDate, formatToMMDDYY } from "../../../Shared/utils";
import { BASE_URL } from "../../../constants/index";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Popover,
  PopoverHeader,
  PopoverBody,
} from "reactstrap";

import { DashboardListHead } from "../../../components";

import jobApi from "../../../Shared/services/job";
import { toast } from "react-toastify";

import moment from "moment";
import Tooltip from "../../../components/Tooltip";

const ManageJobApplicationsByCounsellor = ({
  application = {},
  onAccept,
  location,
  history,
  match,
}) => {
  const index = 1;

  // const { jobTitle } = { jobTitle: location ? location.state.jobTitle : "" };

  const [applications, setapplications] = useState([]);

  const [steps, setSteps] = useState([]);

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getInitialData();
  }, []);

  const getInitialData = async () => {
    try {
      const res = await jobApi.getJobApplicationAndWorkflow(
        match.params.applicantId
      );
      const data = res.data.data;
      if (data.length > 0) {
        console.log("datatataa", data);
        setSteps(JSON.parse(data[0].job.jobWorkflow.steps));
        setapplications(data);
      }

      // setData(workflowData);
    } catch (error) {
      // setapplications(res.data.data)
      console.log("error", error);
      toast("Something went wrong, please try again", { type: "error" });
    }
    setLoading(false);
  };

  const getCurrentStep = (application) => {
    let currentStep = null;

    steps.forEach((item) => {
      if (item.step == application.currentStepIndex) {
        currentStep = item;
      }
    });
    return (
      currentStep?.name ||
      (application.status == 1 ? "All steps completed" : "------")
    );
  };
  const getCurrentStepNotes = (application) => {
    let notes = "";
    application.jobApplicationNotes.forEach((note) => {
      if (note.stepIndex == application.currentStepIndex) {
        notes = note.notes;
      }
    });
    return notes;
  };

  const getTableHeader = () => {
    let headers = [
      { title: "Status", style: { width: "10%" } },
      { title: "Step" },
      { title: "Oppurtunity" },
      { title: "Employer" },
      { title: "Applied On", style: { width: "10%" } },
      { title: "Notes" },
    ];

    return headers;
  };

  return (
    <section className="user-main page-container bg-default-2">
      <div className="user-right">
        <div className="container">
          <div className="pb-14">
            {loading ? (
              <div className="d-flex">
                <i className="fa fa-spinner"></i>
              </div>
            ) : (
              <div className="bg-white shadow-8 rounded p-4 p-lg-8">
                <div className="table-responsive data-table">
                  <table className="table table-striped">
                    <thead className="table-success">
                      <DashboardListHead columns={getTableHeader()} />
                    </thead>
                    <tbody>
                      {applications.map((application, index) => {
                        return (
                          // <JobRow job={job} index={index} />
                          <tr key={index} className="border border-color-2">
                            <td>
                              {application.status === 0 && (
                                <span className="font-size-4 font-weight-normal text-black-2 mb-0">
                                  <span className="d-xl-none">Status:</span>{" "}
                                  Pending
                                </span>
                              )}
                              {application.status === 1 && (
                                <span className="font-size-4 font-weight-normal text-black-2 mb-0">
                                  Accepted On{" "}
                                  {formatToMMDDYY(application.updatedAt)}
                                </span>
                              )}
                              {application.status === 2 && (
                                <span className="font-size-4 font-weight-normal text-black-2 mb-0">
                                  Rejected On{" "}
                                  {formatToMMDDYY(application.updatedAt)}
                                </span>
                              )}
                              {application.status === 4 && (
                                <span className="font-size-4 font-weight-normal text-black-2 mb-0">
                                  Expired On{" "}
                                  {formatToMMDDYY(application.updatedAt)}
                                </span>
                              )}
                              {application.status === 3 && (
                                <span className="font-size-4 font-weight-normal text-black-2 mb-0">
                                  Canceled On{" "}
                                  {formatToMMDDYY(application.updatedAt)}
                                </span>
                              )}
                            </td>

                            <td>
                              <span className="font-size-4 font-weight-normal text-black-2 mb-0">
                                {getCurrentStep(application)}
                              </span>
                            </td>

                            <td>
                              <span className="font-size-4 font-weight-normal text-black-2 mb-0">
                                {application?.job?.company?.title}
                              </span>
                            </td>
                            <td>
                              <span className="font-size-4 font-weight-normal text-black-2 mb-0">
                                {application.job.trainingType}
                              </span>
                            </td>
                            <td>{formatDate(application.createdAt)}</td>

                            <td>
                              <span className="font-size-4 font-weight-normal text-black-2 mb-0">
                                {getCurrentStepNotes(application)}
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <button
                    onClick={() => history.goBack()}
                    className="btn btn-outline-info"
                  >
                    <i
                      className="fa fa-arrow-left"
                      style={{ marginRight: "1em" }}
                    ></i>
                    Go Back
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ManageJobApplicationsByCounsellor;
