export const validateJobPost = (job) => {
  let obj = {}
  let status = true
  let valid = {}
  for (let key in job) {
    valid[key] = true
  }

  if (job.title.trim().length === 0) {
    valid['title'] = false
    obj['title'] = {
      isValid: false,
      message: 'Title is required',
    }
    status = false
  }

  if (
    !job.description ||
    job.description.trim().length === 0 ||
    job.description.length < 250
  ) {
    valid['description'] = false
    obj['description'] = {
      isValid: false,
      message: 'Description is required, with a minimum of 250 characters',
    }
    status = false
  }

  if (!job.trainingType) {
    valid['trainingType'] = false
    obj['trainingType'] = {
      isValid: false,
      message: 'Please Select training type.',
    }
    status = false
  }

  if (!job.trDuration) {
    valid['trDuration'] = false
    obj['trDuration'] = {
      isValid: false,
      message: 'Please select training duration',
    }
    status = false
  }

  if (job.skills.length === 0) {
    valid['skills'] = false
    obj['skills'] = {
      isValid: false,
      message: 'Please add atleast one skill',
    }
    status = false
  }

  if (job.startDates.length === 0) {
    valid['startDates'] = false
    status = false
  }

  return {
    status: status,
    isSubmitted: true,
    validations: valid,
  }
}
