import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  JobsSearchImage,
  JobsSearchCImage,
} from '../../../../../Shared/Images/Image';

import { useSelector } from 'react-redux';
import { BASE_URL } from '../../../../../constants';
import { formatDate } from '../../../../../Shared/utils';
import { getDuration } from '../../../utils';
import moment from 'moment';

const JobPost = ({ job }) => {
  const { isVerified, login } = useSelector((state) => state.auth);
  const [toaster, showToaster] = useState(false);
  const [favourite, setFavourite] = useState(false);
  const history = useHistory();

  const showToast = () => {
    if (!login) {
      // toast('Please login to see the job details', {
      //   type: 'error',
      //   autoClose: 2000,
      // })
      history.push('/jobSearch/login');
    } else {
      toast('Please verify your email to see the job details', {
        type: 'error',
        autoClose: 2000,
      });
    }
  };

  const renderStartDate = () => {
    console.log('start dateee', job.jobStartDate);
    let startDateArray = job.jobStartDate.split(',');
    const userOffset = new Date().getTimezoneOffset();
    if (startDateArray.length == 1) {
      return moment
        .utc(startDateArray[0])
        .add(userOffset * -1, 'minutes')
        .format('MM-DD-YYYY hh:mm A');
    }
    return (
      <div className="startDate-dropdown">
        <div className="startDate-dropdown-content">
          {startDateArray.map((date, index) => {
            return (
              <p key={index}>
                {moment
                  .utc(date)
                  .add(userOffset * -1, 'minutes')
                  .format('MM-DD-YYYY hh:mm A')}
              </p>
            );
          })}
        </div>
        <p>Multiple Dates</p>
      </div>
    );
  };

  const addDefaultSrc = (ev) =>
    (ev.target.src = './images/jbsearch/defaultJob.jpg');

  const addtoFav = () => {
    setFavourite(!favourite);
  };

  const getJobSkill = () => {
    let jobSkill = '';
    if (job.skill) {
      job.skill.split(',').forEach((skill, index) => {
        if (index <= 2) {
          if (jobSkill) {
            jobSkill =
              jobSkill + ', ' + skill.charAt(0).toUpperCase() + skill.slice(1);
          } else {
            jobSkill = skill.charAt(0).toUpperCase() + skill.slice(1);
          }
        }
        if (index == 2) {
          jobSkill = jobSkill + '...';
        }
      });
    }
    return jobSkill;
  };

  return (
    <>
      {isVerified ? (
        <div className="item">
          {/* <i
            className={
              favourite ? "fas fa-heart pointer" : "far fa-heart pointer"
            }
            onClick={addtoFav}
          ></i> */}
          <Link
            className="d-block"
            target="_blank"
            to={'/JobDetail/' + job.jobId}
          >
            <div className="d-md-flex">
              <div className="display-wrapper">
                <img
                  onError={addDefaultSrc}
                  className="dp"
                  src={`${BASE_URL}${job.workplaceImage}`}
                  alt=""
                />
              </div>
              <div className="text pl-md-6">
                {job.isApplied ? (
                  <span
                    className="badge"
                    style={{
                      position: 'absolute',
                      right: '1em',
                      top: '-15px',
                      fontSize: '14px',
                      border: '1px solid',
                      color: '#50bfa5',
                    }}
                  >
                    Applied
                  </span>
                ) : (
                  <></>
                )}
                <h3>{job.title}</h3>
                <div className="d-sm-flex meta-bar">
                  <p className="org">{job.companyName}</p>
                  <p className="price-text">
                    <span className="price">${job.hourlyRate}/Hr</span>
                  </p>
                </div>
                <p className="cont">{job.description.slice(0, 160) + '...'}</p>
              </div>
            </div>
          </Link>
          <div className="row justify-content-between points">
            <div className="col-6 col-md-3 point-list d-flex">
              <i className="fas fa-box"></i>
              <div className="point">
                <h5>Opportunity Type</h5>
                <p>{job.trainingType}</p>
              </div>
            </div>
            <div className="col-6 col-md-3 point-list d-flex">
              <i className="fas fa-road"></i>
              <div className="point">
                <h5>Job Distance</h5>
                <p>{job.distance} miles</p>
              </div>
            </div>
            <div className="col-6 col-md-3 point-list d-flex">
              <i className="fas fa-business-time"></i>
              <div className="point">
                <h5>Duration</h5>
                <p>{getDuration(job.duration)}</p>
              </div>
            </div>
            <div className="col-6 col-md-3 point-list d-flex">
              <i className="fas fa-calendar-alt"></i>
              <div className="point">
                <h5>Start Dates</h5>
                {renderStartDate()}
              </div>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
      {!isVerified && (
        <div onClick={showToast} className="hover-link">
          <div className="item">
            <div className="d-md-flex">
              <div className="display-wrapper">
                <img
                  className="dp"
                  src={`${BASE_URL}${job.workplaceImage}`}
                  alt=""
                />
              </div>
              <div className="text pl-md-6">
                {job.isApplied ? (
                  <span
                    className="badge"
                    style={{
                      position: 'absolute',
                      right: '1em',
                      top: '-15px',
                      fontSize: '14px',
                      border: '1px solid',
                      color: '#50bfa5',
                    }}
                  >
                    Applied
                  </span>
                ) : (
                  <></>
                )}
                <h3>{job.title}</h3>
                <div className="d-sm-flex meta-bar">
                  <p className="org">{job.companyName}</p>
                  <p className="price-text">
                    <span className="price">${job.hourlyRate}/Hr</span>
                  </p>
                </div>
                <p className="cont">{job.description.slice(0, 160) + '...'}</p>
              </div>
            </div>
            <div className="row justify-content-between points">
              <div className="col-6 col-md-3 point-list d-flex">
                <i className="fas fa-box"></i>
                <div className="point">
                  <h5>Opportunity Type</h5>
                  <p>{job.trainingType}</p>
                </div>
              </div>
              <div className="col-6 col-md-3 point-list d-flex">
                <i className="fas fa-road"></i>
                <div className="point">
                  <h5>Job Distance</h5>
                  <p>{job.distance} miles</p>
                </div>
              </div>
              <div className="col-6 col-md-3 point-list d-flex">
                <i className="fas fa-business-time"></i>
                <div className="point">
                  <h5>Duration</h5>
                  <p>{getDuration(job.duration)}</p>
                </div>
              </div>
              <div className="col-6 col-md-3 point-list d-flex">
                <i className="fas fa-calendar-alt"></i>
                <div className="point">
                  <h5>Start Dates</h5>
                  {renderStartDate()}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default JobPost;
