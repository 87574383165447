import { Chat } from "pages/principal/chat";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button } from "reactstrap";

export default function UserMessages() {
  const { empProfile, loading, user } = useSelector((state) => state.auth);
  const history = useHistory();
  useEffect(() => {
    if (!user.parentUserId) {
      history.push("/");
    }
  });
  return (
    <section className="user-main page-container bg-default-2">
      <div className="user-right">
        <div className="container">
          <Chat isUser={true} toUserId={user.parentUserId} />
        </div>
      </div>
    </section>
  );
}
