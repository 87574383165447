import jobApi from '../../Shared/services/job'
import {
  setJobFilterLoading,
  setJobLoading,
  setPagination,
  setJobs,
} from '../reducers/search-reducer'
export const JOB_SEARCH_SUCCESS = 'JOB_SEARCH_SUCCESS'
export const JOB_SEARCH_FAIL = 'JOB_SEARCH_FAIL'
export const GET_JOBS = 'GET_JOBS'
export const FILTER_SUCCESS = 'FILTER_SUCCESS'
export const FILTER_FAILED = 'FILTER_FAILED'
export const FILTER_LOADING = 'FILTER_LOADING'
export const FILTER_START = 'FILTER_START'

export const getjobs = (dispatch) => {
  dispatch({
    type: GET_JOBS,
    payload: {},
  })
}

export const getJobById = (ID) => (dispatch) => {
  dispatch({
    type: GET_JOBS,
    payload: {
      id: ID,
    },
  })
}

export const search = (query) => (dispatch) => {
  return jobApi.search(query).then(
    (response) => {
      dispatch({
        type: JOB_SEARCH_SUCCESS,
        payload: response.data.data,
      })

      // return Promise.resolve();
    },
    (error) => {
      dispatch({
        type: JOB_SEARCH_FAIL,
      })
      // return Promise.reject();
    }
  )
}

export const filterJob = (query, page, limit) => async (dispatch) => {
  try {
    jobApi.cancel()
    const { dateRange, trainingType, actualRadius } = query

    dispatch(setJobFilterLoading(true))
    let queryToSend = {
      ...query,
      dateRange: {
        start: dateRange.start || new Date().toJSON(),
        end:
          dateRange.end ||
          new Date(
            new Date().setFullYear(new Date().getFullYear() + 1)
          ).toJSON(),
      },
      limit: limit,
      page: page,
      radius: query.actualRadius,
    }
    delete queryToSend.actualRadius
    delete queryToSend.address

    // const dates = [];
    // query.startDates.forEach((itm) => dates.push(itm+"T00:00:00"));
    // console.log(dates);
    // return jobApi.filterJob({...query, startDates: dates}, page, limit).then((res) => {
    let { data } = await jobApi.filterJob(queryToSend)
    dispatch(setJobs(data.data))

    if (data.totalDocs != null) {
      dispatch(setPagination({ totalCount: data.totalDocs }))
    }
  } catch (err) {
    console.log('Error', err)
  }
  dispatch(setJobFilterLoading(false))
}
