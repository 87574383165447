import { SHOW_MESSAGE, CLEAR_MESSAGE } from "../actions/message-actions";

const initialState = {
  message: "",
};
// : { login: false, roleId:1, user: null };

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_MESSAGE:
      return {
        ...state,
        message: payload.message ? payload.message : payload,
      };

    case CLEAR_MESSAGE:
      return {
        ...state,
        message: "",
      };

    default:
      return state;
  }
}
