import Rectangle62402x from '../../Assets/images/job-details/Rectangle 6240@2x.png'
import Rectangle6245 from '../../Assets/images/job-details/Rectangle 6245.png'
import Image4425972x from '../../Assets/images/job-details/442597@2x.png'
import JobsSearchImage from '../../Assets/images/jbsearch/entrepreneur-593378_1920.png'
import JobsSearchCImage from '../../Assets/images/jbsearch/Rectangle 106.png'
import ProfileUserImage from '../../Assets/images/profile/Untitled-1.jpg'
import TableImage from '../../Assets/images/table/Ellipse 113.png'
import TeamImage1 from '../../Assets/images/profile/JoeSaboeTrendlines.png'

import CashSvg from '../../Assets/images/job-details/svgs/cash.svg'
import ClockSvg from '../../Assets/images/job-details/svgs/clock.svg'
import ContractSvg from '../../Assets/images/job-details/svgs/contract.svg'
import Calender1Svg from '../../Assets/images/job-details/svgs/Calendar.svg'
import LocationSvg from '../../Assets/images/job-details/svgs/location.svg'
import Calender2Svg from '../../Assets/images/job-details/svgs/calendar2.svg'
import SearchJobIcon from '../../Assets/images/home/job-seeker.svg'
import CategoriesJobIcon from '../../Assets/images/home/categories.svg'
import PlaceholderJobIcon from '../../Assets/images/home/placeholder.svg'
import SearchIcon from '../../Assets/images/home/glass.svg'
import EditIcon from '../../Assets/images/svgs/icons/edit.svg'
import AddCircleIcon from '../../Assets/images/svgs/icons/Icon feather-plus-circle.svg'
import PhoneIcon from '../../Assets/images/svgs/icons/Icon feather-phone-call.svg'
import CheckIcon from '../../Assets/images/svgs/icons/Icon-awesome-check-circle.svg'
import EmailIcon from '../../Assets/images/svgs/icons/Icon simple-email.svg'
import LocationIcon from '../../Assets/images/svgs/icons/Icon material-location-on.svg'
import LinkedIcon from '../../Assets/images/svgs/icons/Icon awesome-linkedin.svg'
import TwitterIcon from '../../Assets/images/svgs/icons/Icon feather-twitter.svg'
import GenderIcon from '../../Assets/images/svgs/icons/Icon awesome-male.svg'
import BookIcon from '../../Assets/images/svgs/icons/book.svg'
import EIcon from '../../Assets/images/svgs/icons/Path 10417.svg'
import UploadIcon from '../../Assets/images/svgs/icons/upload.svg'
import SIcon from '../../Assets/images/svgs/icons/Icon feather-search.svg'
import ListDotsIcon from '../../Assets/images/svgs/icons/list-dots.svg'
import UserTableIcon from '../../Assets/images/svgs/icons/user-table.svg'
import Calender1Icon from '../../Assets/images/svgs/icons/calendar(1).svg'
import CheckCircleIcon from '../../Assets/images/svgs/icons/Icon awesome-check-circle.svg'
import JobTypeIcon from '../../Assets/images/svgs/icons/jobType.svg'
import HomeImage from '../../Assets/images/home/Group 16356@2x.png'
import PlayIcon from '../../Assets/images/svgs/icons/play-button.svg'

import Problem from '../../Assets/images/svgs/icons/problem.svg'
import Solution from '../../Assets/images/svgs/icons/solution.svg'
import WhatWillDo from '../../Assets/images/svgs/icons/what-will-do.svg'

import FPlaceholder from '../../Assets/images/svgs/icons/placeholder.svg'
import FMail from '../../Assets/images/svgs/icons/contact-mail.svg'

import FacebookIcon from '../../Assets/images/svgs/icons/facebook.svg'
import InstagramIcon from '../../Assets/images/svgs/icons/instagram.svg'
import TweetIcon from '../../Assets/images/svgs/icons/twitter.svg'

export {
  Rectangle62402x,
  LocationSvg,
  Rectangle6245,
  Image4425972x,
  CashSvg,
  ClockSvg,
  ContractSvg,
  Calender1Svg,
  Calender2Svg,
  SearchJobIcon,
  CategoriesJobIcon,
  PlaceholderJobIcon,
  SearchIcon,
  JobsSearchImage,
  JobsSearchCImage,
  ProfileUserImage,
  EditIcon,
  AddCircleIcon,
  PhoneIcon,
  CheckIcon,
  EmailIcon,
  LocationIcon,
  LinkedIcon,
  TwitterIcon,
  GenderIcon,
  BookIcon,
  EIcon,
  UploadIcon,
  SIcon,
  ListDotsIcon,
  UserTableIcon,
  CheckCircleIcon,
  Calender1Icon,
  JobTypeIcon,
  TableImage,
  HomeImage,
  PlayIcon,
  Problem,
  Solution,
  WhatWillDo,
  TeamImage1,
  FPlaceholder,
  FMail,
  FacebookIcon,
  InstagramIcon,
  TweetIcon,
}
