import React from "react";

const Tags = ({ tags = [], tagObjects = [], mainClass = "", renderTag }) => {
  return (
    <div>
      <ul className={`list-unstyled ${mainClass}`}>
        {tags.length > 0 &&
          tags.map((tag, i) => {
            return renderTag ? (
              renderTag(tag, i)
            ) : (
              <li key={i}>
                <span>{tag}</span>
              </li>
            );
          })}
        {tagObjects.length > 0 &&
          tagObjects.map((tag, i) => {
            return renderTag ? (
              renderTag(tag.skill, i)
            ) : (
              <li key={i}>
                <span>{tag.skill}</span>
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default Tags;
