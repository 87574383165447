import React, { useEffect, useState } from "react";
import jobApi from "../../../Shared/services/job";
import Loading from "../../../components/Loading";
import JobList from "pages/users/JobSearch/JobList/JobList";
import axios from "axios";
import { BASE_URL } from "../../../constants";

import apiInstance from "../../../Shared/services/instance";
import { Link } from "react-router-dom";
import { formatToMMDDYY } from "Shared/utils";
import { Button } from "reactstrap";

export default function ViewJobs() {
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  useEffect(() => {
    getJobs();
  }, []);
  const getJobs = async () => {
    try {
      const { data } = await apiInstance.get(
        "jobs/get/mine?showSubEmployerJobs=true"
      );
      setJobs(data.data);
      setError(false);
    } catch (err) {
      setError("Something went wrong, please try again");
    }
    setLoading(false);
  };
  return (
    <div>
      {loading ? (
        <Loading size={50} color="#00b074" />
      ) : (
        <div>
          {error && (
            <div
              style={{
                background: "red",
                padding: "1rem",
                borderRadius: "12px",
                margin: "0.5rem",
                color: "white",
              }}
            >
              {error}
            </div>
          )}
          <div className="d-flex align-items-center justify-content-between mb-6">
            <h5 className="font-size-4 font-weight-normal text-gray">
              <span className="heading-default-color mr-1">
                {jobs.length == 0 && "No Jobs  found."}
              </span>
            </h5>
          </div>
          {jobs.map((job, index) => {
            return (
              <div key={index} className="col-xxl-6 dpc-gap">
                <div className="card-display">
                  <header>
                    <Link to={"/jobdetail/" + job.id}>
                      <h2>{job.title}</h2>
                    </Link>
                    <div
                      className={
                        job.status === 0
                          ? "labels d-flex align-items-start justify-content-between wrapped"
                          : "labels d-flex align-items-start justify-content-between"
                      }
                    >
                      <p>Type &ndash; {job.trainingType}</p>

                      {job.status === 0 && (
                        <div className="status badge-info">
                          Posted on {formatToMMDDYY(job.createdAt)}
                        </div>
                      )}
                      {job.status === 1 && (
                        <div className="status badge-primary">Applied</div>
                      )}
                      {job.status === 2 && (
                        <>
                          <Button
                            style={{
                              backgroundColor: "#00b074",
                              borderRadius: "17px",
                              height: "34px",
                              minWidth: "110px",
                            }}
                          ></Button>
                          <div className="status badge-danger">
                            Closed on {formatToMMDDYY(job.updatedAt)}
                          </div>
                        </>
                      )}
                    </div>
                  </header>
                  <div className="meta-info d-flex justify-content-between">
                    <div className="item">
                      <h5>Applications</h5>
                      <p>
                        {job.applications.length > 0 && (
                          <Link
                            to={{
                              pathname:
                                "/dashboard/manage/applications/" + job.id,
                              state: {
                                applications: job.applications,
                                jobTitle: job.title,
                              },
                            }}
                          >
                            Show ({job.applications.length})
                          </Link>
                        )}
                        {job.applications.length === 0 && (
                          <span>No Application</span>
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="group-btn">
                    <button
                      disabled={job.status >= 1}
                      className="delete bttn btn-danger"
                    >
                      <i className="far fa-trash-alt"></i>
                    </button>
                    <Link
                      to={"/job/edit/" + job.id}
                      className="edit bttn btn-success"
                    >
                      <i className="fas fa-pencil-alt"></i>
                    </Link>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
