import industryApi from '../../Shared/services/industry';

export const INDUSTRY_LOADING = 'INDUSTRY_LOADED';
export const INDUSTRY_LOADED_SCCESS = "INDUSTRY_LOADED_SUCCESS";
export const INDUSTRY_LOADED_FAILED = "INDUSTRY_LOADED_FAILED";



export const loadIndusteries = (dispatch) => {

    industryApi.get().then((res) =>{
        dispatch({
            type: INDUSTRY_LOADED_SCCESS,
            payload:res.data.data
        })
    })
    .catch(() => {
        dispatch({
            type: INDUSTRY_LOADED_FAILED
        })
    })

}


