import React, { useState } from 'react'
import { Tooltip } from 'reactstrap'

const CustomTooltip = ({ key, text, toolTipText }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false)

  const toggle = () => setTooltipOpen(!tooltipOpen)
  const id = 'tooltip' + key
  if (toolTipText) {
    return (
      <div>
        <span href="#" id={id}>
          {text}
        </span>

        <Tooltip
          placement="right"
          isOpen={tooltipOpen}
          target={id}
          toggle={toggle}
        >
          {toolTipText}
        </Tooltip>
      </div>
    )
  }
  return (
    <span href="#" id={id}>
      {text}
    </span>
  )
}

export default CustomTooltip
