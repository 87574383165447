import React, { useEffect, useState } from "react";
import jobApi from "../../../Shared/services/job";
import Loading from "../../../components/Loading";
import JobList from "pages/users/JobSearch/JobList/JobList";
import axios from "axios";
import { BASE_URL } from "../../../constants";

import apiInstance from "../../../Shared/services/instance";
import { Link } from "react-router-dom";
import { formatToMMDDYY } from "Shared/utils";
import { Button, Table } from "reactstrap";
import DataTable from "react-data-table-component";
import moment from "moment";
import { errorToast } from "components/Toast";

export default function ViewChildUsers() {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [columns, setColumns] = useState([
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Role",
      selector: (row) => row.role.name,
      sortable: true,
    },
    {
      name: "Created At",
      selector: (row) => row.createdAt,
      sortable: true,
      format: (row, index) => moment(row.createdAt).format("MM-DD-YYYY"),
    },

    {
      name: "Created At",
      cell: function Action(row) {
        return (
          <UnLink
            updateUsers={() => {
              setUsers((prev) => prev.filter((item) => item.id != row.id));
            }}
            userId={row.id}
          />
        );
      },
    },
  ]);

  const [error, setError] = useState(false);
  useEffect(() => {
    getUsers("");
  }, []);
  const getUsers = async () => {
    try {
      const { data } = await apiInstance.get("user/get-child-users");
      setUsers(data.data);
      setError(false);
    } catch (err) {
      setError("Something went wrong, please try again");
    }
    setLoading(false);
  };

  return (
    <div>
      {loading ? (
        <Loading size={50} color="#00b074" />
      ) : (
        <div style={{ background: "white", borderRadius: "12px" }}>
          {error && (
            <div
              style={{
                background: "red",
                padding: "1rem",
                borderRadius: "12px",
                margin: "0.5rem",
                color: "white",
              }}
            >
              {error}
            </div>
          )}
          <div className="d-flex align-items-center justify-content-between mb-6">
            <h5 className="font-size-4 font-weight-normal text-gray">
              <span className="heading-default-color mr-1">
                {users.length == 0 && (
                  <p style={{ padding: "1rem", textAlign: "center" }}>
                    No Users found.
                  </p>
                )}
              </span>
            </h5>
          </div>

          {users.length != 0 && <DataTable columns={columns} data={users} />}
        </div>
      )}
    </div>
  );
}

const UnLink = ({ userId, updateUsers }) => {
  const [loading, setLoading] = useState(false);
  const unlinkUsers = async () => {
    setLoading(true);
    try {
      const { data } = await apiInstance.post("user/unlink-users/" + userId);
      console.log("data", data);
      if (data.success) {
        updateUsers(userId);
      } else {
        errorToast(data.message || "Something went wrong, please try again");
      }
    } catch (err) {
      errorToast("Something went wrong, please try again");
    }
    setLoading(false);
  };
  return (
    <Button onClick={unlinkUsers} color="danger" type="button">
      {loading ? (
        <Loading size={20} />
      ) : (
        <>
          Unlink
          <i
            className="fa fa-unlink"
            style={{ cursor: "pointer", marginLeft: "0.5rem" }}
          ></i>
        </>
      )}
    </Button>
  );
};

UnLink.displayName = "UnLink";
