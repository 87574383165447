import {
  JOB_SEARCH_FAIL,
  JOB_SEARCH_SUCCESS,
  GET_JOBS,
  FILTER_LOADING,
  FILTER_FAILED,
  FILTER_SUCCESS,
} from '../actions/jobs-actions'
import { schema, normalize, arrayOf } from 'normalizr'
const query = {
  search: '',
  geoLocation: { lat: 0, lng: 0 },
  radius: 10,
  trDuration: { min: null, max: null },
  trType: [],
  hourlyRate: { min: 0, max: 1000 },
  startDates: { start: null, end: null },
  zipCode: '',
  sort: 'createdAt',
}

const jobSchema = new schema.Entity('job')
const jobsSchema = new schema.Array(jobSchema)

const jobs = {
  loading: false,
  query: query,
  jobs: [],
}

export default function (state = jobs, action) {
  const { type, payload } = action

  switch (type) {
    case JOB_SEARCH_SUCCESS:
      return {
        ...state,
        jobs: action.payload,
      }

    case JOB_SEARCH_FAIL:
      return {
        ...state,
      }

    case GET_JOBS:
      return state

    case FILTER_LOADING:
      return {
        ...state,
        query: {
          ...state.query,
          ...payload,
        },

        loading: true,
      }

    case FILTER_SUCCESS:
      return {
        ...state,
        loading: false,
        jobs: action.payload.jobs,
        totalDocs: action.payload.totalDocs,
        limit: action.payload.limit,
        page: action.payload.page,
      }

    case FILTER_FAILED:
      return {
        ...state,
        loading: false,
      }

    default:
      return state
  }
}
