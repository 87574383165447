import React, { Component, Fragment, useEffect, useState } from "react";

import "./index.css";
import { useSelector, useDispatch } from "react-redux";
import PlacesAutocomplete from "react-places-autocomplete";
import { search, filterJob } from "../../store/actions/jobs-actions";

import poweredByGoogleImg from "../../Assets/images/googleMaps_compliance/powered_by_google_on_white.png";

import { GOOGLE_MAP_KEY } from "../../constants";
import axios from "axios";
import _ from "lodash";
const AutocompleteInput = ({
  value,
  setValue,
  parentClass,
  setAddress,
  inputClass,
  setLoading,
  setLatlong,
  placeholder,
  callSetValueOnSelect = true,
  searchOptions = {},
  inputStyle = {},
}) => {
  const [isReadonly, setIsReadOnly] = useState(true);
  const [shouldShowGoogleImage, setShouldShowGoogleImage] = useState(false);
  const { query } = useSelector((state) => state.search);
  const dispatch = useDispatch();
  const {
    search: {
      pagination,
      query: { geoLocation, keyword, address },
    },
  } = useSelector((state) => state);

  const renderResults = (suggestions, loading, getSuggestionItemProps) => {
    if (suggestions.length > 0) {
      // setError(false)
    }
    return (
      <div
        className="autocomplete-dropdown-container"
        style={{ backgroundColor: "#fff", zIndex: 99999, position: "absolute" }}
      >
        {loading && <div className="suggestion-item">Loading...</div>}

        {suggestions.map((suggestion, index) => {
          const className = suggestion.active
            ? "suggestion-item--active"
            : "suggestion-item";
          // inline style for demonstration purpose
          return (
            <div
              style={{
                paddingBottom: ".5rem",
                paddingTop: ".3rem",
                display: "block",
                top: 45,
                width: 320,
                backgroundColor: "#fff",
                fontWeight: 300,
                fontSize: 16,
                whiteSpace: "pre",
                borderBottomeLeftRadius: "4px",
                borderBottomRightRadius: "4px",
                zIndex: 2,
              }}
              key={index}
              {...getSuggestionItemProps(suggestion, {
                className,
              })}
            >
              <span>{suggestion.description}</span>
            </div>
          );
        })}
        {shouldShowGoogleImage && (
          <img
            src={poweredByGoogleImg}
            style={{
              paddingLeft: "0.3rem",
              paddingBottom: "0.5rem",
              paddingTop: "0.3rem",
              zIndex: 9999,
            }}
          />
        )}
      </div>
    );
  };

  function checkArray(array) {
    if (_.isArray(array)) return true;
    return false;
  }

  const handleSelect = async (address) => {
    setLoading(true);
    setShouldShowGoogleImage(false);
    try {
      let formattedAddres = address.replace(/ /g, "+");
      let url = `https://maps.googleapis.com/maps/api/geocode/json?address=${formattedAddres}&key=${GOOGLE_MAP_KEY}`;
      let { data } = await axios.get(url);

      if (data.status === "OK") {
        if (!checkArray(data.results)) {
          return {
            error: true,
            message: "An error occured while formatting data",
          };
        }
        let lat = _.get(data.results, ["0", "geometry", "location", "lat"]);
        let lng = _.get(data.results, ["0", "geometry", "location", "lng"]);
        let latlng = lat + "," + lng;
        let detailAddress = extractAddressFromGooleReverseGeocode(
          data.results[0].address_components
        );
        setLatlong({
          lat,
          lng,
          ...detailAddress,
        });
      } else {
        return {
          error: true,
          message: "An error occured fetching reults from google",
        };
      }
    } catch (error) {
      console.log("Error", error);
    }
    if (callSetValueOnSelect) setValue(address);
    setLoading(false);

    /*   dispatch(filterJob(query, pagination.page, pagination.limit))
      .then(() => {
        console.log('success search')
      })
      .catch(() => console.log('failed search')) */
  };
  const onError = (status, clearSuggestions) => {};
  return (
    <PlacesAutocomplete
      onError={onError}
      value={value}
      types
      onSelect={handleSelect}
      searchOptions={searchOptions}
      onChange={(addr) => {
        setValue(addr);
      }}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div>
          <input
            onFocus={(event) => {
              setShouldShowGoogleImage(true);

              if (event.target.autocomplete) {
                event.target.autocomplete = "whatever";
              }
            }}
            onBlur={() => {
              setIsReadOnly(true);
              setShouldShowGoogleImage(false);
            }}
            style={{ background: "white", ...inputStyle }}
            autoComplete="turn this off"
            {...getInputProps({
              placeholder: placeholder,
              className: inputClass,
            })}
          />

          {renderResults(suggestions, loading, getSuggestionItemProps)}
        </div>
      )}
    </PlacesAutocomplete>
  );
};

export default AutocompleteInput;

function extractAddressFromGooleReverseGeocode(address_components) {
  let line1 = "",
    line2 = "",
    city = "",
    state = "",
    zipcode = "";
  address_components.forEach((addr) => {
    if (addr.types.includes("street_number")) {
      line1 = addr.long_name;
    }
    if (addr.types.includes("route")) {
      line1 = line1 + " " + addr.long_name;
    }
    if (addr.types.includes("sublocality")) {
      line2 = addr.long_name;
    }
    if (addr.types.includes("locality")) {
      line2 = addr.long_name;
    }

    if (addr.types.includes("administrative_area_level_2")) {
      city = addr.long_name;
    }

    if (addr.types.includes("administrative_area_level_1")) {
      state = addr.long_name;
    }

    if (addr.types.includes("postal_code")) {
      zipcode = addr.long_name;
    }
  });
  return {
    line1,
    line2,
    state,
    city,
    zipcode,
  };
}

/* import React from 'react'

export default function index() {
  return <div></div>
}
 */
