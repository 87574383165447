import api from "./instance";

const tagsApi = {
  createTag: (body) => api.post("tags", body),
  getTags: () => api.get("tags"),
  updateUserTag: (userId, tagId) =>
    api.put("tags/updateUserTag/" + userId, { tagId }),
  getAllUserByTagId: (tagId) => api.delete("tags/updateUserTag/" + tagId),
  sendGroupMessage: (tagId, message) =>
    api.post("tags/sendMessageToAllUserOfATag/" + tagId, { message }),
};
export default tagsApi;
