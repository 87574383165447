import { ROLES } from "../../constants";
import {
  LOGOUT,
  USER_LOGIN,
  USER_REGISTER,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  USER_LOADING,
  USER_PROFILE_LOADED_FAILED,
  USER_PROFILE_LOADING,
  USER_PROFILE_LOADED_SUCCESS,
  EMPLOYER_PROFILE_LOADED_FAILED,
  EMPLOYER_PROFILE_LOADED_SUCCESS,
  EMPLOYER_PROFILE_LOADING,
  EMPLOYER_UPDATE_INFO_FAILED,
  EMPLOYER_UPDATE_ADDRESS_FAILED,
  EMPLOYER_UPDATE_ADDRESS_LOADING,
  EMPLOYER_UPDATE_ADDRESS_SUCCESS,
  EMPLOYER_UPDATE_INFO_LOADING,
  EMPLOYER_UPDATE_INFO_SUCCESS,
  USER_UPDATE_INFO_FAILED,
  USER_UPDATE_INFO_SUCCESS,
  USER_UPDATE_INFO_LOADING,
  EMPLOYER_PROFILE_CREATE_SUCCESS,
  USER_PROFILE_CREATE_SUCCESS,
  EMPLOYER_UPDATE_LOGO,
} from "../actions/user-action";

const user = JSON.parse(localStorage.getItem("user"));

const initialState = user
  ? {
      login: true,
      roleId:
        user.role === "Applicant"
          ? ROLES.applicant
          : user.role == "Career Counselor"
          ? ROLES.career_counsellor
          : ROLES.employer,
      user,
      isUser: user.role === "Applicant",
      isVerified: user.isVerified ? true : false,
      hasProfile: user.hasProfile ? true : false,
      loading: false,
    }
  : {
      login: false,
      roleId: ROLES.employer,
      user: null,
      userProfile: {},
      empProfile: {},
      isUser: false,
      isVerified: false,
      hasProfile: false,
      loading: false,
    };

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case EMPLOYER_PROFILE_CREATE_SUCCESS:
      return {
        ...state,
        hasProfile: true,
      };
    case USER_PROFILE_CREATE_SUCCESS:
      return {
        ...state,
        hasProfile: true,
      };

    case USER_LOADING:
      return {
        ...state,
        loading: true,
      };

    case REGISTER_SUCCESS:
      return {
        ...state,
        login: true,
        user: payload,
        isUser: payload.role === "Applicant",
        isVerified: false,
        hasProfile: false,
        loading: false,
        roleId:
          payload.role == "Applicant"
            ? ROLES.applicant
            : payload.role == "Career Counselor"
            ? ROLES.career_counsellor
            : ROLES.employer,
      };

    case LOGOUT:
      return {
        ...state,
        login: false,
        isUser: false,
        user: {},
        isVerified: false,
        hasProfile: false,
        loading: false,
        userProfile: null,
        empProfile: null,
      };

    case REGISTER_FAIL:
      return {
        ...state,
        login: false,
        isUser: false,
        loading: false,
      };

    case LOGIN_SUCCESS:
      return {
        ...state,
        login: true,
        user: payload,
        isUser: payload.role === "Applicant" ? true : false,
        hasProfile: payload.hasProfile,
        isVerified: payload.isVerified,
        loading: false,
        roleId:
          payload.role == "Applicant"
            ? ROLES.applicant
            : payload.role == "Career Counselor"
            ? ROLES.career_counsellor
            : ROLES.employer,
      };

    case LOGIN_FAIL:
      return {
        ...state,
        login: false,
        isUser: false,
        loading: false,
      };

    case EMPLOYER_PROFILE_LOADING:
      return {
        ...state,
        loading: true,
      };

    case EMPLOYER_PROFILE_LOADED_FAILED:
      return {
        ...state,
        empProfile: null,
        loading: false,
      };

    case EMPLOYER_PROFILE_LOADED_SUCCESS:
      return {
        ...state,
        empProfile: payload,
        loading: false,
      };

    case USER_PROFILE_LOADING:
      return {
        ...state,
        loading: true,
      };

    case USER_PROFILE_LOADED_FAILED:
      return {
        ...state,
        userProfile: null,
        loading: false,
      };

    case USER_PROFILE_LOADED_SUCCESS:
      return {
        ...state,
        userProfile: payload,
        hasProfile: true,
        loading: false,
      };

    case USER_UPDATE_INFO_LOADING:
      return {
        ...state,
        loading: true,
        userProf: payload,
      };

    case EMPLOYER_UPDATE_ADDRESS_SUCCESS:
      return {
        ...state,
        loading: false,
        empProfile: {
          ...state.empProfile,
          address: payload.address,
        },
      };
    case EMPLOYER_UPDATE_LOGO:
      return {
        ...state,
        loading: false,
        empProfile: {
          ...state.empProfile,
          logo: payload,
        },
      };

    case EMPLOYER_UPDATE_ADDRESS_FAILED:
      return {
        ...state,
        loading: false,
      };

    case USER_UPDATE_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        userprofile: payload,
      };

    case USER_UPDATE_INFO_FAILED:
      return {
        ...state,
        loading: false,
      };

    case EMPLOYER_UPDATE_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case EMPLOYER_UPDATE_INFO_LOADING:
      return {
        ...state,
        loading: true,
        empProfile: {
          ...state.empProfile,
          ...payload,
        },
      };

    case EMPLOYER_UPDATE_ADDRESS_LOADING:
      return {
        ...state,
        loading: true,
        empProfile: {
          ...state.empProfile,
          ...payload,
        },
      };

    default:
      return state;
  }
}
