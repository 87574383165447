export const OtherRoutes = [];

export const RoutesArray = [
  {
    key: "dashboard",
    name: "Dashboard",
    path: "/",
  },
  {
    key: "login",
    name: "Login",
    path: "/login",
  },
];
