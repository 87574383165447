import React from 'react'
import ClipLoader from 'react-spinners/ClipLoader'
export default function Loading({ color = '#ccc', style, size = 100 }) {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '5rem',
        marginBottom: '5rem',
        ...style,
      }}
    >
      <ClipLoader color={color} loading={true} size={size} />
    </div>
  )
}
