import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, ListGroupItem, ListGroupItemHeading, ListGroupItemText, ListGroup } from 'reactstrap';

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from 'react-toastify'
import jobApi from '../../../Shared/services/job'
import moment from 'moment';
import _ from "lodash"
import { BASE_URL } from '../../../constants';

export const ViewAttachments = ({toggle, isOpen, attachments, steps}) => {
    
    const getCurrentStep = (stepIndex) =>{
        let currentStep = null
        console.log("ss", stepIndex,steps)
        steps.forEach(item => {
          if (item.step == stepIndex) {
            currentStep = item
          }
        })
        return currentStep
      }

    const getStepName = (stepIndex) =>{
        const step = getCurrentStep(stepIndex)
        return step.name
    }
    const groupAttachments = () =>{
        const grouped = {}        
        const sorted = attachments.sort((a,b)=> new Date(a.createdAt) - new Date(b.createdAt))
        sorted.forEach(item => {
            if (grouped[item.stepIndex]) {
                grouped[item.stepIndex].push(item)
            }
            else {
                grouped[item.stepIndex] =  [item]
            }
        })
        return grouped
    }
    return (
    <div>
   
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>Notes</ModalHeader>
        
        <ModalBody>
        <ListGroup>
            {attachments.length == 0 && (
                <ListGroupItemText>
                    No Attachments found
                </ListGroupItemText>
            )}
        {Object.keys(groupAttachments()).map(key => {
            const item = groupAttachments()[key]
           return (
           <>
            <ListGroupItemHeading style={{fontSize:"1.2rem"}}>
               <span>{key}. {getStepName(key)}</span>
            </ListGroupItemHeading>
            {item.map(i => {
                return (
                    
                    <ListGroupItemText key={i.id} style={{fontSize:"1rem", marginLeft:"1rem", display:"flex", justifyContent:"space-between"}}>
                    File - <a target={"_blank"} rel="noreferrer" href={BASE_URL  + i.filename}> {i.filename.slice(0,10)} </a><span style={{fontSize:"0.9rem", fontWeight:100}}>{moment(i.createdAt).format("MM-DD-YYYY  hh:mm")}</span>
           
                </ListGroupItemText>
                
                
                )
            })}
           
           </>
           ) 
        })}
  
 
</ListGroup>

         </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

