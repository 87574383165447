import React from 'react'
const Home = React.lazy(() => import('./main/Main'))
const Login = React.lazy(() => import('./login/Login'))
const Forgotpassword = React.lazy(() =>
  import('./forgotpassword/Forgotpassword')
)
const ForgotpasswordStep2 = React.lazy(() =>
  import('./forgotpassword/Forgotpassword2')
)
const Resetpass = React.lazy(() => import('./resetpass/Resetpass'))
const Signup = React.lazy(() => import('./signup/Signup'))
const EmployeeProfile = React.lazy(() => import('./employees/profile/Profile'))
const Dashboard = React.lazy(() => import('./dashboard/Dashboard'))
const ManageApplications = React.lazy(() =>
  import('./users/ManageApplications/ManageApplication')
)
const PostJob = React.lazy(() => import('./employees/postJob/PostJob'))
// User routes //
const profileUser = React.lazy(() => import('./users/Profiles/ProfileUser'))
const jobSearch = React.lazy(() => import('./users/JobSearch/JobSearch'))
const JobDetail = React.lazy(() => import('./users/JobDetail/JobDetail'))
const CreateUserProfile = React.lazy(() =>
  import('./users/Profiles/CreateProfile/CreateProfile')
)
const CreateEmployerProfile = React.lazy(() =>
  import('./employees/profile/CreateProfile')
)
const UserDashboard = React.lazy(() => import('./dashboard/UserDashboard'))
const ManageJobs = React.lazy(() => import('./employees/manageJobs/ManageJobs'))
const VerifyEmail = React.lazy(() => import('./verifyEmail/verifyEmail'))
const NotificationSetting = React.lazy(() =>
  import('./employees/NotificationSetting')
)
export {
  Home,
  Login,
  Forgotpassword,
  ForgotpasswordStep2,
  Resetpass,
  Signup,
  EmployeeProfile,
  Dashboard,
  UserDashboard,
  ManageApplications,
  ManageJobs,
  PostJob,
  CreateUserProfile,
  CreateEmployerProfile,
  profileUser,
  jobSearch,
  JobDetail,
  VerifyEmail,
  NotificationSetting,
}
