import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export const appReducer = createSlice({
  name: 'app',
  initialState: {
    shouldNavigateToLogin: false,
  },
  reducers: {
    setShouldNavigateToLogin: (state, action) => {
      state.shouldNavigateToLogin = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
/* export const { setUnseenNotificationCount } = notificationSlice.action */
export const { setShouldNavigateToLogin } = appReducer.actions
export default appReducer.reducer
