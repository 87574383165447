import React, { Component, Fragment, useState } from "react";
import { Link, useHistory, useRouteMatch, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { BASE_URL, ROLES } from "../../constants/index";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
// import "./header.css";

import { notificationViewed } from "../../store/actions/notification-actions";
import { resetState } from "../../store/reducers/search-reducer";
import Loading from "../Loading";
import Error from "../Error";
import {
  useWindowSize,
  useWindowWidth,
  useWindowHeight,
} from "@react-hook/window-size";
import {
  setUnseenNotificationCount,
  viewAllNotification,
} from "../../store/reducers/notification-reducer";
import ClickAwayListener from "react-click-away-listener";

export const Header = () => {
  const [showNotificationDropdown, setShowNotificationDropdown] =
    useState(false);
  // const login = localStorage && localStorage.getItem("login");
  const history = useHistory();
  const { pathname } = useLocation();
  const { login, isUser, hasProfile, isVerified, user } = useSelector(
    (state) => state.auth
  );
  const {
    latestTenNotification,
    unseenNotiicationCount,
    latestTenNotificationError,
    latestNotificationLoading,
  } = useSelector((state) => state.notification);
  const [dropdownOpen, setdropdownOpen] = useState(false);
  const [hasViewed, sethasViewed] = useState(false);

  const dispatch = useDispatch();
  const width = useWindowWidth();
  // let isUser = pathname.split("/")[1] == "user";
  let publicRoutes = [
    // { title: "Home", url: `/${pathname.split("/")[1]}` }
  ];
  let routes = [...publicRoutes];

  if (login && isUser) {
    routes = [...routes];

    if (login && isVerified && !hasProfile) {
      routes = [...routes, { title: "Profile", url: "/create-profile" }];
    }

    if (hasProfile) {
      routes = [...routes, { title: "Profile", url: "/profile" }];
    }
  }

  if (login && !isUser) {
    routes = [
      ...routes,
      // { title: "Profile", url: "/employee/profile"  },
      // { title: "Dashboard", url: "/employee/dashboard" },
    ];

    if (isVerified && !hasProfile) {
      routes = [...routes, { title: "Profile", url: "/create-profile" }];
    }

    if (isVerified && hasProfile) {
      routes = [...routes, { title: "profile", url: "/profile" }];
    }
  }

  const toggle = () => {
    setdropdownOpen(!dropdownOpen);
  };

  const onViewNotification = () => {
    setShowNotificationDropdown(!showNotificationDropdown);
    dispatch(setUnseenNotificationCount(0));
    dispatch(viewAllNotification());
  };

  const handleLogoutClicked = () => {
    localStorage.clear();
    dispatch({ type: "LOGOUT" });
    history.push("/");
  };
  const renderPrincipalCounsellor = () => {
    if (
      (!isUser && user.isPrincipalCounsellor) ||
      user.role == "Career Counselor"
    ) {
      return (
        <li className="nav-item">
          <Link className="nav-link" to="/principal-cunsellor">
            {user.role == "Career Counselor"
              ? "Career Counselor"
              : "Principal Counselor"}
          </Link>
        </li>
      );
    }
    return <></>;
  };
  const renderPrincipalEmployer = () => {
    if (!isUser && user.isPrincipalEmployer)
      return (
        <li className="nav-item">
          <Link className="nav-link" to="/principal-employer">
            Principal Employer
          </Link>
        </li>
      );
    return <></>;
  };
  return (
    <>
      <header className="site-header site-header--menu-right bg-default site-header--absolute site-header--sticky mobile-sticky-enable">
        <div className="container">
          <nav className="navbar site-navbar offcanvas-active navbar-expand-lg  px-0 py-0">
            <div className="brand-logo mr-auto">
              <Link to="/">
                <img
                  src="./images/svgs/logo/workabl_trans_img.png"
                  alt=""
                  className="pointer light-version-logo default-logo"
                  width="224px"
                />
              </Link>
            </div>

            <ul className="navbar-nav main-menu d-none d-xl-flex">
              <li key={"09"} className="nav-item">
                <Link
                  className={`nav-link ${"/" == pathname ? "active" : ""}`}
                  to={{ pathname: "/" }}
                >
                  Home
                </Link>
              </li>
              {login && (
                <>
                  {isVerified && hasProfile ? (
                    <>
                      <li className="nav-item">
                        <Link className="nav-link" to="/dashboard">
                          Dashboard
                        </Link>
                      </li>
                      <li>
                        {user.parentUserId && (
                          <Link
                            className="nav-link"
                            to={{ pathname: "/messages" }}
                          >
                            Messages
                          </Link>
                        )}
                      </li>
                    </>
                  ) : (
                    ""
                  )}
                  {!isUser && isVerified && hasProfile ? (
                    <>
                      <li className="nav-item">
                        <Link className="nav-link" to="/postJob">
                          Post Training Opportunity
                        </Link>
                      </li>
                    </>
                  ) : (
                    ""
                  )}
                  {renderPrincipalEmployer()}
                  {renderPrincipalCounsellor()}
                </>
              )}
            </ul>

            {!login && (
              <Link
                className="btn big-btn btn-success logbtn text-uppercase ml-xl-4"
                to="/login"
              >
                Login
              </Link>
            )}

            {login && (
              <div className="ml-4 ml-xl-2">
                <div className="dropdown user-tab">
                  <button
                    className="btn dropdown-toggle"
                    type="button"
                    style={{ fontWeight: "normal" }}
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {user.role == "Employer" &&
                      hasProfile && ( //this is to verify logo image will be present
                        <img
                          src={
                            user.logo
                              ? BASE_URL + user.logo
                              : "./images/avatar.jpg"
                          }
                          className="icon circle-40"
                        />
                      )}
                    {user.role == "Applicant" &&
                      hasProfile && ( //this is to verify profilePic will be present
                        <img
                          src={
                            user.profilePic
                              ? BASE_URL + user.profilePic
                              : "./images/avatar.jpg"
                          }
                          className="icon circle-40"
                        />
                      )}
                    Hi {user.name.split(" ").slice(0, 1)}
                    {/*only first name shown for users without profile yet*/}
                  </button>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <h5 className="user-label d-xs-none">
                      Hi {user.name.split(" ").slice(0, 1)}
                    </h5>
                    <Link
                      className="dropdown-item d-xl-none"
                      to={{ pathname: "/" }}
                    >
                      Home
                    </Link>

                    {routes.map((r, i) => {
                      return (
                        <Link
                          key={i}
                          onClick={() => {
                            if (r.onClick) r.onClick();
                          }}
                          className="dropdown-item"
                          to={{ pathname: r.url }}
                        >
                          {r.title}
                        </Link>
                      );
                    })}

                    {isVerified && hasProfile ? (
                      <Link className="dropdown-item d-xl-none" to="/dashboard">
                        Dashboard
                      </Link>
                    ) : (
                      ""
                    )}
                    {!isUser && isVerified && hasProfile ? (
                      <Link className="dropdown-item d-xl-none" to="/postJob">
                        Post Training Opportunity
                      </Link>
                    ) : (
                      ""
                    )}

                    <Link
                      className="dropdown-item"
                      to="/"
                      onClick={() => handleLogoutClicked()}
                    >
                      Logout
                    </Link>
                  </div>
                </div>
              </div>
            )}

            {login && hasProfile && (
              <div className="header-btn-devider ml-5 align-items-center">
                {/* <div>
              <a href="#" className="px-3 ml-7 font-size-7 notification-block flex-y-center position-relative">
                <i className="fas fa-bell heading-default-color"></i>
                <span className="font-size-3 count font-weight-semibold text-white bg-primary circle-24 border border-width-3 border border-white">3</span>
              </a>
            </div>              */}
                <div className="dropdown">
                  {/* <a id="dLabel" role="button" data-toggle="dropdown" data-target="#" href="/page.html">
                  ss
              
                </a> */}
                  {isVerified && (
                    <a
                      onClick={() => onViewNotification()}
                      id="dLabel"
                      role="button"
                      className="px-3 font-size-7 notification-block flex-y-center position-relative"
                    >
                      <i className="fas fa-bell heading-default-color"></i>

                      {unseenNotiicationCount == 0 ||
                      unseenNotiicationCount == "0" ? (
                        ""
                      ) : (
                        <span className="font-size-3 count font-weight-semibold text-white bg-primary circle-24 border border-width-3 border border-white">
                          {unseenNotiicationCount}
                        </span>
                      )}
                    </a>
                  )}
                  {showNotificationDropdown && (
                    <ClickAwayListener
                      onClickAway={() => setShowNotificationDropdown(false)}
                    >
                      <ul
                        style={{ display: "block" }}
                        className="dropdown-menu notifications"
                        role="menu"
                        aria-labelledby="dLabel"
                      >
                        <div className="notification-heading d-flex justify-content-between">
                          <h4 className="menu-title font-size-6 font-weight-normal mb-0">
                            Notifications
                          </h4>
                        </div>
                        <li className="divider"></li>
                        <div
                          className="notifications-wrapper"
                          style={{ maxHeight: "260px" }}
                        >
                          {latestNotificationLoading && <Loading />}
                          {latestTenNotificationError && (
                            <Error message={latestTenNotificationError} />
                          )}
                          {!latestNotificationLoading &&
                            !latestTenNotificationError &&
                            latestTenNotification.length == 0 && (
                              <h6 style={{ textAlign: "center" }}>
                                You have no new notifications
                              </h6>
                            )}
                          {latestTenNotification.map((itm, index) => (
                            <Notification
                              setShowNotificationDropdown={
                                setShowNotificationDropdown
                              }
                              key={index}
                              notification={itm}
                            />
                          ))}
                          {latestTenNotification.length > 0 && (
                            <div style={{ textAlign: "center" }}>
                              <button
                                onClick={() => {
                                  setShowNotificationDropdown(false);
                                  history.push("/notifications");
                                }}
                                style={{
                                  border: 0,
                                  background: "#00b074",
                                  fontSize: "1rem",
                                  padding: "1rem",
                                  paddingTop: "0.5rem",
                                  paddingBottom: "0.5rem",
                                  borderRadius: "30px",
                                  display: "inline-block",
                                  color: "#fff",
                                  margin: 0,
                                }}
                              >
                                View All Notifications
                              </button>
                            </div>
                          )}
                        </div>
                      </ul>
                    </ClickAwayListener>
                  )}
                </div>
              </div>
            )}
          </nav>
        </div>
      </header>
      {/* <div
        className="menu-overlay"
        data-toggle="collapse"
        data-target="#mobile-menu"
      ></div> */}
    </>
  );
};

export default Header;

export const Notification = ({ notification, setShowNotificationDropdown }) => {
  const history = useHistory();

  const createLink = (notiCode, id) => {
    let link = "";
    switch (notiCode) {
      case "survey":
        link = "/take/survey/" + id;
        break;
      case "NEW_APP":
        link = `/dashboard/manage/applications/${id}`;
        break;
      case "APPLICATION_ACCEPTED":
        link = `/dashboard/manage/application`;

        break;
      case "APPLICATION_REJECTED":
        link = `/dashboard/manage/application`;
        break;
      default:
        break;
    }

    return link;
  };
  const handleClick = () => {
    if (setShowNotificationDropdown) setShowNotificationDropdown(false);
    const link = createLink(notification.notiCode, notification.data);
    history.push(link);
  };

  return (
    <Link
      onClick={handleClick}
      className="content"

      // to={"/employee/dashboard/manage/applications/" + notification.jobId}
    >
      <div className="notification-item">
        <div className="media">
          {/* <div classNameName="notification-img">
              <img src="./image/header-profile.png" alt="" />
            </div> */}
          <div className="media-body pl-3">
            <p className="font-size-4 mb-0">
              <strong>{notification.title}</strong>
            </p>
            <p className="font-size-4 mb-0">{notification.body}</p>
          </div>
        </div>
      </div>
    </Link>
  );
};
