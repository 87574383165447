import api from "./instance";
import axios from "axios";

const job = {
  create: (job) => api.post("jobs", job),
  search: (query) => api.get("jobs/search?search=" + query),
  getMine: () => api.get("jobs/get/mine"),
  getApplicationsByJob: (id) => api.get("application/get/byjob/" + id),
  getWorkflow: (id) => api.get("workflow/" + id),
  getJobApplicationAndWorkflow: (id) =>
    api.get("workflow/getJobApplicationAndWorkflow/" + id),

  saveJobApplicationNotes: (
    jobApplicationId,
    notes,
    currentStepIndex,
    name,
    email,
    files
  ) =>
    api.post("workflow/saveJobApplicationNotes", {
      jobApplicationId,
      currentStepIndex,
      notes,
      applicantName: name,
      applicantEmail: email,
      files,
    }),
  proceedToNextStep: (data) => api.post("workflow/nextStep", data),
  rejectApplication: (data) => api.post("workflow/rejectApplication", data),
  hireOnSpot: (data) => api.post("workflow/hireOnSpot", data),
  updateWorkflow: (id, steps) => api.put("workflow/" + id, { steps }),
  getDetail: (id, token) =>
    api.get("jobs/detail/" + id, {
      headers: {
        "x-auth-token": token || "",
      },
    }),
  createApplication: (application) => api.post("application", application),
  getMyApplications: () => api.get("application/get/mine"),
  cancelApplication: (jobApplicationID) =>
    api.put("application/action/cancel", { jobApplicationID }),
  acceptApplication: (id, application) =>
    api.put("application/action/accept/" + id, application, {
      cancelToken: axios.CancelToken.source().token,
    }),
  filterJob: (query) =>
    api.post(`jobs/v2/filter`, query, {
      cancelToken: axios.CancelToken.source().token,
    }),
  filterJobWithoutQuery: () =>
    api.post(`jobs/v2/filter`, {
      cancelToken: axios.CancelToken.source().token,
    }),

  cancel: () => {},
  updateJob: (job) => api.put("jobs/update/job", job),
  addStartDate: (date, jobId) =>
    api.post("jobs/add/startdate/single?jobID=" + jobId, { date }),
  removeStartDate: (id, jobId) =>
    api.delete("jobs/delete/startdate/" + id + "/" + jobId),
  addSkill: (skill, jobID) =>
    api.post("jobs/add/oneskill?jobID=" + jobID, { skill }),
  removeSkill: (id, jobID) => api.delete("jobs/delete/skills/" + id),
  addJobPhoto: (jobID, image) =>
    api.post("jobs/add/photo?jobID=" + jobID, { image }),
  deleteJobPhoto: (id) => api.delete("jobs/delete/photo/" + id),
  closeJob: (jobID) => api.post("jobs/close/job", { jobID }),
  updateJobAnswer: (id, answer) =>
    api.post("jobs/udpate/answer/to/jobqestion", { id, answer }),
  getAcceptedJobApplicationDetails: async (jobId) =>
    await api.get(`application/acceptedJobApplicationDetails/${jobId}`),
};
export default job;
