import {
  INDUSTRY_LOADED_FAILED,
  INDUSTRY_LOADED_SCCESS,
  INDUSTRY_LOADING,
} from "../actions/industry-action";

import industryApi from "../../Shared/services/industry";

const initialState = {
  loading: false,
  industeries: [],
};

// : { login: false, roleId:1, user: null };

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case INDUSTRY_LOADING:
      return {
        ...state,
        loading: true,
      };

    case INDUSTRY_LOADED_SCCESS:
      return {
        ...state,
        loading: false,
        industeries: payload,
      };
    default:
      return state;
  }
}
