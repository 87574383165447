import React from "react";
import { Route } from "react-router-dom";
import {
  EmployeeProfile,
  ManageApplications,
  CreateEmployerProfile,
  ManageJobs,
  VerifyEmail,
} from "./pages";
import NotFound from "./pages/notfound/NotFound";
import ProtectedRoute from "./ProtectedRoute";
import {
  Home,
  Dashboard,
  PostJob,
  profileUser,
  jobSearch,
  JobDetail,
  CreateUserProfile,
  UserDashboard,
} from "./pages";
import EditJob from "./pages/employees/edit job/EditJob";
import { StaticPage } from "./pages/static-page/StaticPage";
import Notification from "./pages/notification/Notification";
import Survey from "./pages/survey/survey";
import ResetPass from "./pages/resetpass/Resetpass";
import PrincipalEmployer from "pages/principal/principal-employer";
import PrincipalCounsellor from "pages/principal/principal-counsellor";
import ManageJobApplicationsByCounsellor from "pages/principal/principal-counsellor/view-applicant-application";
import UserMessages from "pages/users/Message";

export const commonRoutes = [
  {
    name: "Home",
    path: [
      "/",
      "/login",
      "/forgotpassword",
      "/confirm_forgotpassowrd",
      "/resetpass",
      "/signup",
    ],
    exact: true,
    component: Home,
  },
  {
    name: "Job search",
    path: "/jobSearch",
    component: jobSearch,
  },

  {
    name: "Verify Email",
    path: "/verify/email",
    component: VerifyEmail,
  },
  {
    name: "Static pages",
    path: "/static/:ID",
    component: StaticPage,
  },
  {
    name: "Take Survey",
    path: "/take/survey/:ID",
    component: Survey,
    exact: true,
  },
  {
    path: "/reset/password",
    component: ResetPass,
  },

  {
    name: "Job detail",
    path: "/JobDetail/:ID",
    component: JobDetail,
  },
  {
    name: "Not Found",
    path: "*",
    component: NotFound,
    exact: true,
  },
];
export const employerEmailNotVerifiedRoutes = [
  {
    name: "Create Employer Profile",
    path: "/create-profile",
    component: CreateEmployerProfile,
  },

  {
    name: "Profile",
    path: "/profile",
    component: EmployeeProfile,
  },
];

export const userEmailNotVerifiedRoutes = [
  {
    name: "Create User Profile",
    path: "/create-profile",
    component: CreateUserProfile,
  },
  {
    name: "Profile",
    path: "/profile",
    component: profileUser,
  },
];
export const employerRoutes = [
  {
    name: "Notifications",
    path: "/notifications",
    component: Notification,
  },
  {
    name: "Principal EMployer",
    path: "/principal-employer",
    component: PrincipalEmployer,
  },

  {
    name: "Create Employer Profile",
    path: "/create-profile",
    component: CreateEmployerProfile,
  },

  {
    name: "Post Job",
    path: "/postJob/:id?",
    component: PostJob,
  },
  {
    name: "Dashboard",
    path: "/dashboard",
    component: Dashboard,
  },

  {
    name: "Edit Job",
    path: "/job/edit/:ID",
    component: EditJob,
    exact: true,
  },
  {
    name: "Profile",
    path: "/profile",
    component: EmployeeProfile,
  },
  {
    name: "Notifications",
    path: "/notifications",
    component: Notification,
  },
  {
    name: "Principal Counsellor",
    path: "/principal-cunsellor",
    component: PrincipalCounsellor,
  },
  {
    name: "ManageApplicationByCounsellor",
    path: "/manage-application-by-counsellor/:applicantId",
    component: ManageJobApplicationsByCounsellor,
  },
];
export const userRoutes = [
  {
    name: "Notifications",
    path: "/notifications",
    component: Notification,
  },
  {
    name: "Create User Profile",
    path: "/create-profile",
    component: CreateUserProfile,
  },
  {
    name: "Profile",
    path: "/profile",
    component: profileUser,
  },
  {
    name: "Dashboard",
    path: "/dashboard",
    component: UserDashboard,
  },
  {
    name: "ManageApplicationByCounsellor",
    path: "/manage-application-by-counsellor/:applicantId",
    component: ManageJobApplicationsByCounsellor,
  },
  {
    name: "Principal Counsellor",
    path: "/principal-cunsellor",
    component: PrincipalCounsellor,
  },
  {
    name: "User Messages",
    path: "/messages",
    component: UserMessages,
  },
];
export const notLoggedInUserRoutes = [{ ...commonRoutes }];
