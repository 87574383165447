import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, ListGroupItem, ListGroupItemHeading, ListGroupItemText, ListGroup } from 'reactstrap';

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from 'react-toastify'
import jobApi from '../../../Shared/services/job'
import moment from 'moment';


export const ViewNotes = ({toggle, isOpen, notes, steps}) => {
    
    const getCurrentStep = (stepIndex) =>{
        let currentStep = null
        console.log("ss", stepIndex,steps)
        steps.forEach(item => {
          if (item.step == stepIndex) {
            currentStep = item
          }
        })
        return currentStep
      }

    const getStepName = (stepIndex) =>{
        const step = getCurrentStep(stepIndex)
        return step.name
    }
    return (
    <div>
   
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>Notes</ModalHeader>
        
        <ModalBody>
        <ListGroup>
            {notes.length == 0 && (
                <ListGroupItemText>
                    No Notes found
                </ListGroupItemText>
            )}
        {notes.sort((a,b) => new Date(a.createdAt) - new Date(b.createdAt)) .map(item => {
           return (
           <>
            <ListGroupItemHeading style={{fontSize:"1.2rem", display:"flex", justifyContent:"space-between", alignItems:"center"}}>
               <span>{item.stepIndex}. {getStepName(item.stepIndex)}</span> <span style={{fontSize:"0.9rem", fontWeight:100}}>{moment(item.createdAt).format("MM-DD-YYYY  hh:mm")}</span>
            </ListGroupItemHeading>
            <ListGroupItemText style={{fontSize:"1rem", marginLeft:"1rem"}}>
                Note - {item.notes}
            </ListGroupItemText>
           </>
           ) 
        })}
  
 
</ListGroup>

         </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

