import React, { useState } from 'react'
import { toast } from 'react-toastify'
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Spinner,
} from 'reactstrap'
import jobApi from '../../../Shared/services/job'
import anayliticsApi from '../../../Shared/services/analytics'
import { ANALYTICS_ACTIONS, ANALYTICS_LABELS } from '../../../constants'
import moment from 'moment'
import { useEffect } from 'react'
const AcceptApplication = ({
  applicationID,
  toggle,
  onAccepted,
  address,
  selectedApplication,
}) => {
  const [loading, setloading] = useState(false)
  const [message, setmessage] = useState('')
  const [application, setapplication] = useState({
    phone: '+92333333',
    email: 'lkjlkjlkj',
    date: new Date(),
    time: '12:00',
    address: '',
    notes: '',
  })

  useEffect(() => {
    const { job } = selectedApplication
    const { company } = job
    const { JobAddress } = job
    const { user } = company
    const momentDate = moment(selectedApplication.startDate)
    const date = momentDate.format('MM-DD-YYYY')

    const time = momentDate.format('hh:mm A')

    setapplication({
      name: user.name,
      phone: company.phone,
      date: date,
      time: time,
      address: `${JobAddress.line1}, ${JobAddress.city}, ${JobAddress.state}, ${JobAddress.zipcode}`,
      email: user.email,
      notes: '',
      applicantId: selectedApplication.userId,
      jobId: selectedApplication.jobId,
    })
  }, [])

  const handleChange = (event) => {
    const { value, type, name, checked } = event.target
    setapplication({
      ...application,
      [name]: type === 'checkbox' ? checked : value,
    })
  }

  const onSubmit = (event) => {
    // alert(applicationID);
    event.preventDefault()

    jobApi
      .acceptApplication(applicationID, {
        ...application,
        date: selectedApplication.startDate,
      })
      .then((res) => {
        anayliticsApi.create(
          ANALYTICS_LABELS.applicationAccept,
          null,
          ANALYTICS_ACTIONS.empAcceptedApplication //Job ID Should be there
        )
        toast('Application Accepted Successfully', { type: 'success' })
        onAccepted()
        toggle()
      })
      .catch((err) => {
        toast(err.message, { type: 'error' })
        toggle()
      })
  }
  console.log('applicatiodnewfwef', application)
  return (
    <>
      <div className="container">
        <div className="content">
          <Row>
            <Col md="12">
              {message && message.length > 0 && (
                <p className="danger">{message}</p>
              )}
              <Form onSubmit={onSubmit}>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label>Contact Name</Label>
                      <Input
                        onChange={handleChange}
                        value={application.name}
                        required
                        disabled
                        placeholder="name ..."
                        name="name"
                        type="text"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label>Contact Phone</Label>
                      <Input
                        onChange={handleChange}
                        value={application.phone}
                        required
                        disabled
                        placeholder="phone ..."
                        name="phone"
                        type="text"
                      />
                    </FormGroup>
                  </Col>
                </Row>

                {/* description  */}
                <Row>
                  <Col>
                    <FormGroup>
                      <Label>Contact Email</Label>
                      <Input
                        onChange={handleChange}
                        value={application.email}
                        required
                        disabled
                        placeholder="email ..."
                        name="email"
                        type="text"
                      />
                    </FormGroup>
                  </Col>
                </Row>

                {/* description  */}
                <Row>
                  <Col>
                    <FormGroup>
                      <Label>Date of Training Opportunity</Label>
                      <Input
                        onChange={handleChange}
                        value={application.date}
                        required
                        disabled
                        placeholder="date ..."
                        name="date"
                        type="text"
                      />
                    </FormGroup>
                  </Col>
                </Row>

                {/* industry  */}
                <Row>
                  <Col>
                    <FormGroup>
                      <Label>Time of Training Opportunity</Label>
                      <Input
                        onChange={handleChange}
                        value={application.time}
                        required
                        disabled
                        placeholder="Select Time"
                        name="time"
                        type="text"
                      />
                    </FormGroup>
                  </Col>
                </Row>

                {/* Adress line1  */}
                <Row>
                  <Col>
                    <FormGroup>
                      <Label>Address</Label>
                      <Input
                        onChange={handleChange}
                        value={application.address}
                        required
                        disabled
                        placeholder="Address ..."
                        name="address"
                        type="textarea"
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <FormGroup>
                      <Label>Notes</Label>
                      <Input
                        onChange={handleChange}
                        value={application.notes}
                        placeholder="Notes ..."
                        name="notes"
                        type="textarea"
                      />
                    </FormGroup>
                  </Col>
                </Row>

                {/* create button  */}
                <Row>
                  <div className="update ml-auto mr-auto">
                    <Button
                      className="btn-round btn btn-secondary"
                      color="secondary"
                      type="button"
                      onClick={() => toggle()}
                    >
                      Cancel
                      {loading && <Spinner color="primary" />}
                    </Button>
                    <Button
                      style={{ marginLeft: '1rem' }}
                      className="btn-round"
                      color="primary"
                      type="submit"
                    >
                      Submit
                      {loading && <Spinner color="primary" />}
                    </Button>
                  </div>
                </Row>
              </Form>
              {/* </CardBody> */}
              {/* </Card> */}
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default AcceptApplication
