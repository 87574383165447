import React, { useState, useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import ImageUploader from "../../../components/ImageUploader";
const baseurl = "http://localhost:3000/";

const JobPhotos = (props) => {
  const { open, setopen, setJob, job } = props;

  const [counts, setcounts] = useState([1]);
  // useEffect(() => {
  //   setcounts([1]);
  // }, [])

  // const toggle = () => setModal(!modal);
  const imageUploadStart = (event) => {
    console.log(event);
  };
  const imageChanged = (event) => {
    setJob({ ...job, photos: [...job.photos, event] });
    // setphotos([...photos, event]);
    setcounts([...counts, Math.floor(Math.random() * 1200)]);
  };

  return (
    <div>
      {/* <Button color="danger" onClick={toggle}>{'buttonLabel'}</Button> */}
      <Modal
        onClosed={() => setcounts([1])}
        isOpen={open}
        toggle={() => setopen(!open)}
        className={"className"}
      >
        <ModalHeader toggle={() => setopen(!open)}>
          Workplace Images
        </ModalHeader>
        <ModalBody>
          {
            <div className={"flex"}>
              {counts.length > 0 &&
                counts.map((itm, index) => (
                  <ImageUploader
                    key={index}
                    style={{ border: "1px solid gray" }}
                    imageUploadStarts={(e) => imageUploadStart(e)}
                    onImageChange={(e) => imageChanged(e)}
                  />
                ))}
            </div>
          }

          {/* {
            photos.length > 0 && 
            <div className={"flex"}>
            {
            photos.map((photo, index) => (
              <img width="100" src={`${baseurl}${photo.replace('public', '')}`} key={index} alt=""/>
            ))
            }
            </div>
          } */}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => setopen(!open)}>
            Close
          </Button>{" "}
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default JobPhotos;
