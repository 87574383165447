import React from "react";

// const arr = ["xs", "md", "sm", "sm", "sm", "sm"];

// const DashboardListHead = ({ columns = [] }) => {
//   return (
//     <div className="applied-jt-header">
//       <ul className="p-2 br-20 list-unstyled d-flex align-items-center">
//         {columns.map((col, i) => {
//           return (
//             <li key={i} className={`cols-${arr[i]}`}>
//               <div className="d-flex align-items-center pl-4">
//                 <img src={col.src} />
//                 {col.title && <span className="aj-heading">{col.title}</span>}
//               </div>
//             </li>
//           );
//         })}
//       </ul>
//     </div>
//   );
// };

const DashboardListHead = ({ columns = [] }) => {
  return (
    <tr>
      {columns.map((col, i) => {
        console.log("cc", col)
        return (
          <th
            key={i}
            scope="col"
            style={col.style}
            className="border-0 font-size-4 font-weight-normal"
          >
            {col.title}
          </th>
        );
      })}
    </tr>
  );
};

export default DashboardListHead;
