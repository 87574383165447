import Loading from "components/Loading";
import React, { useEffect, useState } from "react";
import menuApi from "../../Shared/services/menu";
import "./static.css";

export const StaticPage = ({ match, location }) => {
  const [staticPage, setstaticPage] = useState(null);
  useEffect(() => {
    console.log(match, location);
    let ids = match.params.ID.split("-");
    window.scrollTo(0, 0);
    menuApi
      .getPageById(ids[ids.length - 1])
      .then((res) => {
        setstaticPage(res.data.data);
      })
      .catch((err) => console.log(err));
  }, []);
  function createMarkup(id) {
    return { __html: `${staticPage.content}` };
  }
  return (
    <div className="bg-default-3 pt-17 pt-lg-19 pt-xl-23 pb-10">
      <div className="cardbord-ui">
        <div className="title-bar">
          <div className="container">
            <h3 className="page-title circular-medium font-weight-normal mb-0">
              {staticPage?.title || <Loading size={75} color="#00b074" />}
            </h3>
          </div>
        </div>
      </div>
      {staticPage && (
        <div className="container">
          <div className="bg-white shadow-9 rounded-4 p-7 p-xl-9 mt-10 mb-3">
            <div className="static-ui" dangerouslySetInnerHTML={createMarkup()} />
          </div>
        </div>
      )}

    </div>
  );
};
