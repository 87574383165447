import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Error from '../../components/Error'
import { Notification } from '../../components/header/Header'
import Loading from '../../components/Loading'
import Pagination from '../../components/Pagination'
import { fetchNotifications } from '../../store/reducers/notification-reducer'

export default function NotificationPage() {
  console.log('notfoundd')
  const { notifications, total, pageNumber, loading, error } = useSelector(
    (state) => state.notification
  )
  useEffect(() => {
    dispatch(fetchNotifications(1))
  }, [])
  const dispatch = useDispatch()
  const handlePageChange = (nextPage) => {
    console.log('cha', nextPage.page)
    dispatch(fetchNotifications(nextPage.page))
  }
  return (
    <section
      className="bg-default-2 pt-16 pt-lg-22 pb-lg-27"
      style={{
        minHeight: 'calc(100vh - 50px)',
        paddingTop: '100px',
      }}
    >
      <div className="cs-container" style={{ height: '100%' }}>
        <div className="row" style={{ height: '100%' }}>
          <div className="col-md-12 bg-white rounded-4 pt-11 shadow-9">
            <h3 className="font-size-6 mb-0" style={{ marginLeft: '1rem' }}>
              Notifications
            </h3>
            <hr style={{ border: '1px solid #3e9f91' }} />
            {loading && <Loading />}
            {error && <Error message={error} />}
            {notifications.map((itm, index) => (
              <div key={index}>
                <Notification key={index} notification={itm} />
                <hr />
              </div>
            ))}
            {total > 10 && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '2rem',
                }}
              >
                <Pagination
                  pageLimit={10}
                  onChangePage={handlePageChange}
                  totalRecords={total}
                  currentPage={pageNumber}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}
