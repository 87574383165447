import { combineReducers } from 'redux'
import auth from './user-reducer'
import jobs from './jobs-reducer'
import message from './message-reducer'
import industry from './industry-rducer'
import notification from './notification-reducer'
import education from './education-reducer'
import search from './search-reducer'
import app from './app.reducer.js'
import common from './common-reducer'

// import message from "./message";

const appReducer = combineReducers({
  auth,
  jobs,
  message,
  industry,
  notification,
  education,
  search,
  common,
  app,
})

export default function rootReducer(state, action) {
  return appReducer(state, action)
}
