import api from './instance'
import {} from '..//utils/'
import { getMessageFromAxiosError } from '../../utils'
const media = {
  upload: (file) => api.post('media/upload', file),
  uploadBase64: (file) => api.post('media/upload/base64', file),
  uploadNew: async (file, setLoading) => {
    setLoading(true)
    try {
      let formData = new FormData()
      formData.append('image', file)
      const res = await api.post('media/upload', formData)
      setLoading(false)
      return res.data.image
    } catch (error) {
      const errorMessage = getMessageFromAxiosError(error)
      setLoading(false)
      throw errorMessage
    }
  },
}
export default media
