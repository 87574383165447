import React from "react";
export function RenderMessage({ messages }) {
  return (
    <ul style={{ listStyle: "none", marginLeft: "1rem" }}>
      {messages.map((item, index) => {
        if (typeof item == "string") {
          return <li style={{ color: "white" }}>{item}</li>;
        }
        if (typeof item == "object") {
          if (typeof item.message == "object") {
            return (
              <div>
                <h5 style={{ color: "white", fontSize: "0.9rem" }}>
                  {item.field}
                </h5>
                <ul style={{ listStyle: "none", marginLeft: "1rem" }}>
                  {Object.keys(item.message).map((key, index) => (
                    <li key={index} style={{ color: "white" }}>
                      {key} - {item.message[key]}
                    </li>
                  ))}
                </ul>
              </div>
            );
          } else {
            return (
              <li style={{ color: "white" }}>
                {item.field} - {item.message}
              </li>
            );
          }
        }
        return (
          <li key={index} style={{ color: "white" }}>
            Unknowkn Error
          </li>
        );
      })}
    </ul>
  );
}
