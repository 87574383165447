import React, { useEffect, useState } from 'react'
import JobPost from './JobPost/JobPost'
import { useSelector, useDispatch } from 'react-redux'
import { getJobById, getjobs } from '../../../../store/actions/jobs-actions'
import DotLoader from 'react-spinners/DotLoader'
import Loading from '../../../../components/Loading'
import { setCurrentJob } from '../../../../store/reducers/search-reducer'
const JobList = (props) => {
  const [currentjob, setjob] = useState(props.job)

  const {
    search: { jobFilterLoading, jobs, currentJob, jobLoading },
  } = useSelector((state) => state)
  const dispatch = useDispatch()

  useEffect(() => {
    setjob(props.job)
  }, [props.job])
  if (jobFilterLoading || jobLoading) {
    return <Loading />
  }
  return (
    <div className="search-results">
      {currentjob.map((itm, index) => (
        <div onMouseEnter={() => dispatch(setCurrentJob(itm))} key={index}>
          <JobPost job={itm} key={index} />
        </div>
      ))}
    </div>
  )
}
export default JobList
