import React, { useState } from 'react'
import { TableImage } from '../../Shared/Images/Image'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import ApplicationRow from '../employees/managejobapplications/ManageJobApplications'
import { formatDate } from '../../Shared/utils'
import './dashboard.css'
import AcceptApplication from '../employees/accept application/acceptApplicat'

const JobRow = ({ job, index }) => {
  const [applications, setapplications] = useState([])
  const [modal, setModal] = useState(false)
  const [acceptModal, setacceptModal] = useState(false)
  const [selectedID, setselectedID] = useState(null)
  const toggle = (_applications) => {
    if (_applications) {
      setapplications(_applications)
    }
    setModal(!modal)
  }
  return (
    <>
      <Modal isOpen={modal} toggle={toggle} size={'lg'}>
        <ModalHeader>
          <h3>Applications</h3>
        </ModalHeader>
        <ModalBody>
          <div className="container">
            {applications.length > 0 &&
              applications.map((app, index) => (
                <ApplicationRow application={app} key={index} />
              ))}
          </div>
        </ModalBody>
      </Modal>

      {job && (
        <li key={index}>
          <div className="card">
            <div className="card-body">
              <div className="list-unstyled applied-jt-table">
                <div className="cols-xs">
                  <div>
                    <span className="text-prime d-block circular-bold heading6 text-center">
                      {index + 1}
                    </span>
                  </div>
                </div>
                <div className="cols-xl">
                  <div className="d-flex align-items-center">
                    <div>
                      <img src={TableImage} alt="userIcon" />
                    </div>
                    <div className="ml-2">
                      <span className="d-block text-op1 p3-text p3-lg-text text-capitalize circular-medium">
                        {job.title}
                      </span>
                      <span className="d-block text-zero heading5  text-capitalize circular-medium">
                        {job.industry}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="cols-sm">
                  <div>
                    <span className="d-block text-op1 p3-text p3-lg-text text-capitalize circular-medium">
                      {formatDate(job.createdAt)}
                    </span>
                  </div>
                </div>
                <div className="cols-sm">
                  <div>
                    <span className="d-block text-op1 p3-text p3-lg-text text-capitalize circular-medium">
                      {job.trainingType}
                    </span>
                  </div>
                </div>
                <div className="cols-sm">
                  <div className="action-buttons">
                    <div className="d-flex">
                      <button className="btn p-0 ml-lg-3 ml-0">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18.756"
                          height="18.756"
                          viewBox="0 0 18.756 18.756"
                        >
                          <path
                            className="b"
                            d="M9.378,0a9.378,9.378,0,1,0,9.378,9.378A9.389,9.389,0,0,0,9.378,0Zm5.241,6.91L8.626,12.857a.922.922,0,0,1-1.293.024L4.16,9.989A.953.953,0,0,1,4.09,8.673a.93.93,0,0,1,1.316-.047l2.515,2.3L13.28,5.57a.947.947,0,1,1,1.34,1.34Z"
                          />
                        </svg>
                        <span
                          className="pt-1"
                          onClick={() => {
                            setapplications(job.applications)
                            toggle()
                          }}
                        >
                          View ({job.applications.length})
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
      )}
    </>
  )
}

export default JobRow
