import React, { Component, Fragment, useState, useEffect } from "react";
import { Drawer, DashboardListHead } from "../../components";
import {
  EmplistCols,
  UserlistCols,
  UserDrawerLinks,
  EmpDrawerLinks,
} from "./constants";
// import ApplicationRow from "./ApplicationRow";
import JobRow from "./JobRow";
import "./dashboard.css";
import {
  useHistory,
  Switch,
  Route,
  Redirect,
  useRouteMatch,
} from "react-router-dom";
import jobsApi from "../../Shared/services/job";
import { ManageJobs } from "..";
import ManageJobApplications from "../employees/managejobapplications/ManageJobApplications";
import Applicant from "../employees/applicant/Applicant";
const Dashboard = () => {
  const history = useHistory();

  let isUser = false;
  let listCols = isUser ? UserlistCols : EmplistCols;
  const [jobs, setjobs] = useState([]);
  const match = useRouteMatch();

  return (
    <section className="user-main page-container bg-default-2">
      <div className="user-right">
        <div className="container">
          <Switch>
            <Route path="/dashboard/manage/jobs" component={ManageJobs} />
            <Route
              path="/dashboard/manage/applications/applicant/:id"
              component={Applicant}
            />
            <Route
              path="/dashboard/manage/applications/:jobID"
              component={ManageJobApplications}
            />
            <Redirect from="/dashboard" to="/dashboard/manage/jobs" />
          </Switch>
        </div>
      </div>
    </section>
    // <section className="applied-job-section">
    //   <Drawer
    //     isUser={false}
    //   />
    // <div className="applied-job-right">
    //   <div className="pt-5">
    //       <div className="applied-jobs-header mb-4">
    //         <h2>Active Jobs Listings</h2>
    //       </div>
    //       <div className="search-applied-job mb-4">
    //         <div className="d-flex flex-row align-items-center flex-md-row flex-column">
    //           <h6 className="mb-0 heading4 circular-normal mb-md-0 mb-4">
    //             Showing 8 results
    //           </h6>
    //           <div className="form-group mb-0 position-relative ml-4">
    //             <input
    //               id="search-applied-input"
    //               className="form-control search-applied-input"
    //               type="text"
    //               name=""
    //               placeholder="Search Applied Job"
    //             />
    //             <label
    //               className="search-applied-label"
    //               htmlFor="search-applied-input"
    //             >
    //               <img src="./images/svgs/icons/Icon feather-search.svg" />
    //             </label>
    //           </div>
    //         </div>
    //       </div>
    //     <div className="applied-jobs-table">
    //       <div className="applied-jt-wrap" id="style-1">
    //         <DashboardListHead columns={listCols} />
    //         <div className="applied-jt-body">
    //           <ul className="list-unstyled applied-jt-list">
    //             {jobs.map((job, i) => {
    //               return isUser ? (
    //                 <JobRow key={i} index={i} />
    //               ) : (
    //                 <ApplicationRow job={job} key={i} index={i} />
    //               );
    //             })}
    //             {/* 4 */}
    //           </ul>
    //           {/* 3 */}
    //         </div>
    //       </div>
    //     </div>
    //     {/* 1 */}
    //   </div>
    // </div>
    // </section>
  );
};

export default Dashboard;
