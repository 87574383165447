import React from "react";

export const ErrorMessageHeader = ({ message }) => (
  <h5
    style={{
      color: "white",
      fontWeight: "bold",
      fontSize: "1rem",
      marginBottom: "0.5rem",
    }}
  >
    {message}
  </h5>
);
