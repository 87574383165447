import notificationApi from "../../Shared/services/notification";

export const NOTIFICATION_LOADING = "NOTIFICATION_LOADED";
export const NOTIFICATION_LOADED_SCCESS = "NOTIFICATION_LOADED_SUCCESS";
export const NOTIFICATION_LOADED_FAILED = "NOTIFICATION_LOADED_FAILED";

export const NOTIFICATION_ARRIVED = "NOTIFICATION_ARRIVED";
export const NOTIFICATION_VIEWED = "NOTIFICATION_VIEWED";

export const notificationArrived = (data) => (dispatch) => {
  dispatch({
    type: NOTIFICATION_ARRIVED,
    payload: data,
  });
};

export const notificationViewed = (dispatch) => {
  dispatch({
    type: NOTIFICATION_VIEWED,
  });
};

export const loadNotifications = (dispatch) => {
  dispatch({
    type: NOTIFICATION_LOADING,
  });
  notificationApi
    .get()
    .then((res) => {
      dispatch({
        type: NOTIFICATION_LOADED_SCCESS,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: NOTIFICATION_LOADED_FAILED,
      });
    });
};
