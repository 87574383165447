/* eslint-disable no-undef */
import user from "../../Shared/services/user";

import userService from "../../Shared/services/user";

import { useHistory } from "react-router-dom";
import { CLEAR_MESSAGE, SHOW_MESSAGE } from "./message-actions";
import { loadNotifications } from "./notification-actions";

export const USER_REGISTER = "USER_REGISTER";

export const USER_LOGIN = "USER_LOGIN";

export const REGISTER_SUCCESS = "REGISTER_SUCCESS";

export const REGISTER_FAIL = "REGISTER_FAIL";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";

export const LOGIN_FAIL = "LOGIN_FAIL";
export const USER_LOADING = "USER_LOADING";

export const USER_PROFILE_LOADING = "USER_PROFILE_LOADING";
export const USER_PROFILE_LOADED_SUCCESS = "USER_PROFILE_LOADED_SUCCESS";
export const USER_PROFILE_LOADED_FAILED = "USER_PROFILE_LOADED_FAILED";

export const EMPLOYER_PROFILE_LOADING = "EMPLOYER_PROFILE_LOADING";
export const EMPLOYER_PROFILE_LOADED_SUCCESS =
  "EMPLOYER_PROFILE_LOADED_SUCCESS";
export const EMPLOYER_PROFILE_LOADED_FAILED = "EMPLOYER_PROFILE_LOADED_FAILED";

export const EMPLOYER_UPDATE_INFO_LOADING = "EMPLOYER_UPDATE_INFO_LOADING";
export const EMPLOYER_UPDATE_INFO_SUCCESS = "EMPLOYER_UPDATE_INFO_SUCCESS";
export const EMPLOYER_UPDATE_INFO_FAILED = "EMPLOYER_UPDATE_INFO_FAILED";

export const USER_UPDATE_INFO_LOADING = "USER_UPDATE_INFO_LOADING";
export const USER_UPDATE_INFO_SUCCESS = "USER_UPDATE_INFO_SUCCESS";
export const USER_UPDATE_INFO_FAILED = "USER_UPDATE_INFO_FAILED";

export const EMPLOYER_UPDATE_ADDRESS_LOADING =
  "EMPLOYER_UPDATE_ADDRESS_LOADING";
export const EMPLOYER_UPDATE_ADDRESS_SUCCESS =
  "EMPLOYER_UPDATE_ADDRESS_SUCCESS";
export const EMPLOYER_UPDATE_ADDRESS_FAILED = "EMPLOYER_UPDATE_ADDRESS_FAILED";

export const EMPLOYER_PROFILE_CREATE_LOADING =
  "EMPLOYER_PROFILE_CREATE_LOADING";
export const EMPLOYER_PROFILE_CREATE_SUCCESS =
  "EMPLOYER_PROFILE_CREATE_SUCCESS";
export const EMPLOYER_PROFILE_CREATE_FAILED = "EMPLOYER_PROFILE_CREATE_FAILED";

export const USER_PROFILE_CREATE_LOADING = "USER_PROFILE_CREATE_LOADING";
export const USER_PROFILE_CREATE_SUCCESS = "USER_PROFILE_CREATE_SUCCESS";
export const USER_PROFILE_CREATE_FAILED = "USER_PROFILE_CREATE_FAILED";

export const EMPLOYER_UPDATE_LOGO = "EMPLOYER_UPDATE_LOGO";

export const LOGOUT = "LOGOUT";
export const logout = (dispatch) => {
  dispatch({
    type: LOGOUT,
  });
};

export const login = (email, password) => (dispatch) => {
  dispatch({
    type: USER_LOADING,
    payload: {},
  });

  return userService
    .login(email, password)
    .then((response, err) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: response.data.data,
      });

      if (!response.data.isUser) {
        dispatch(loadNotifications);
      }

      dispatch({
        type: CLEAR_MESSAGE,
        payload: {},
      });

      localStorage.setItem("user", JSON.stringify(response.data.data));
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("refreshToken", res.data.refreshToken);

      return Promise.resolve();
    })
    .catch((err) => {
      dispatch({
        type: LOGIN_FAIL,
      });

      dispatch({
        type: SHOW_MESSAGE,
        payload: err.response.message,
      });

      return Promise.reject();
    });
};

export const empRegister = (name, email, password, roleId) => (dispatch) => {
  dispatch({
    type: USER_LOADING,
    payload: {},
  });
  return userService.empRegister(name, email, password, roleId).then(
    (response) => {
      dispatch({
        type: REGISTER_SUCCESS,
        payload: response.data.data,
      });
      localStorage.setItem("user", JSON.stringify(response.data.data));
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("refreshToken", response.data.refreshToken);
      dispatch({
        type: CLEAR_MESSAGE,
        payload: {},
      });
      return Promise.resolve();
    },
    (error) => {
      console.log("---- emp err reg ---");
      console.log(error);
      console.log("---- emp err reg ---");
      dispatch({
        type: REGISTER_FAIL,
      });

      dispatch({
        type: SHOW_MESSAGE,
        payload: error.response.data.message,
      });

      return Promise.reject();
    }
  );
};

export const loading = (dispatch) => {
  dispatch({
    type: USER_LOADING,
  });
};

export const loadUserProfile = (dispatch) => {
  dispatch({
    type: USER_PROFILE_LOADING,
  });

  return userService
    .getProfile()
    .then((res) => {
      dispatch({
        type: USER_PROFILE_LOADED_SUCCESS,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: USER_PROFILE_LOADED_FAILED,
        payload: {},
      });
    });
};

export const loadEmployerProfile = (dispatch) => {
  dispatch({
    type: EMPLOYER_PROFILE_LOADING,
  });

  return userService
    .getEmpProfile()
    .then((res) => {
      dispatch({
        type: EMPLOYER_PROFILE_LOADED_SUCCESS,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: EMPLOYER_PROFILE_LOADED_FAILED,
        payload: {},
      });
    });
};

export const updateEmployerAddress = (prof) => (dispatch) => {
  let address = {
    line1: prof.line1,
    line2: prof.line2,
    city: prof.city,
    state: prof.state,
    zipcode: prof.zipcode,
    lat: prof.lat,
    lng: prof.lng,
  };

  dispatch({
    type: EMPLOYER_UPDATE_ADDRESS_LOADING,
    payload: prof,
  });

  return userService
    .updateEmployerAddress(address)
    .then((res) => {
      dispatch({
        type: EMPLOYER_UPDATE_ADDRESS_SUCCESS,
        payload: { address: prof },
      });
    })
    .catch((err) => {
      dispatch({
        type: EMPLOYER_UPDATE_ADDRESS_FAILED,
      });
    });
};

export const updateLogo = (logo) => (dispatch) => {
  return userService
    .updateCompanyLogo(logo.logo, logo.id)
    .then((res) => {
      dispatch({
        type: EMPLOYER_UPDATE_LOGO,
        payload: logo.logo,
      });
    })
    .catch(() => {});
};

export const updateEmployerInfo = (prof, industry) => (dispatch) => {
  console.log("action update emp:::", prof);
  console.log(prof);
  console.log("action update emp:::", prof);
  dispatch({
    type: EMPLOYER_UPDATE_INFO_LOADING,
    payload: prof,
  });

  return userService
    .updateEmployerInfo(prof, industry)
    .then((res) => {
      dispatch({
        type: EMPLOYER_UPDATE_INFO_SUCCESS,
      });
    })
    .catch((err) => {
      dispatch({
        type: EMPLOYER_UPDATE_INFO_FAILED,
      });
    });
};

/* export const updateUserProfile = (prof) => async (dispatch) => {
  try {
  
  } catch (error) {
    throw error
  }
} */

export const createdEmpProfile = (dispatch) => {
  let user = localStorage.getItem("user");
  user = JSON.parse(user);
  user.hasProfile = true;
  localStorage.setItem("user", JSON.stringify(user));

  dispatch({
    type: EMPLOYER_PROFILE_CREATE_SUCCESS,
  });
};

export const createdUserProfile = (dispatch) => {
  let user = localStorage.getItem("user");
  user = JSON.parse(user);
  user.hasProfile = true;
  localStorage.setItem("user", JSON.stringify(user));

  dispatch({
    type: USER_PROFILE_CREATE_SUCCESS,
  });
};
