import React from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from 'reactstrap'

export default function NotFound({ message, textStyle }) {
  console.log('notfoundd')
  const history = useHistory()
  return (
    <section
      className="notfound"
      style={{
        height: '80vh',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {message ? (
        <h1 style={{ fontSize: '2rem', ...textStyle }}>
          {message}
        </h1>
      ) : (
        <h1 style={{ fontSize: '2rem', ...textStyle }}>
          Page Not Found&nbsp;
          <span
            onClick={() => history.push("/")}
            className="pointer  "
            style={{
              color: "#00b074",
              borderBottom: "1px solid #00b074"
            }}>Click here to go to home page</span>
        </h1>
      )}

    </section >
  )
}
