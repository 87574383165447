import React, { useEffect, useRef, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";
import jobApi from "../../../Shared/services/job";
import { Collapse, CardBody, Card } from "reactstrap";
import moment from "moment";
import { GENERIC_ERROR_MESSAGE } from "../../../constants";
import FileUpload from "../../../Shared/services/media";

export const HRActionModal = ({
  toggle,
  isOpen,
  steps,
  jobApplication = {},
  addNotes,
  updateApplication,
  addAttachments
}) => {
  const [saveNotesLoading, setSaveNotesLoading] = useState(false);
  const [nextStepLoading, setNextStepLoading] = useState(false);
  const [rejectApplicationLoading, setRejectApplicationLoading] =
    useState(false);
  const [showRejectConfirmModal, setShowRejectConfirmModal] = useState(false)
  const [showHireOnSpotConfirmModal, setShowHireOnSpotConfirmModal] = useState(false)
  const fileInputEventRef = useRef(null)
  const [hireOnSpotLoading, setHireOnSpotLoading] = useState(false);
  const [attachments,setAttachments] = useState([])
  console.log("apappa", jobApplication);

  const [notes, setNotes] = useState("");

  useEffect(() => {
    setNotes(getCurrentStepNotes());
  }, [jobApplication.jobApplicationNotes.length]);

  const saveNotes = async () => {
    if (!notes) {
      toast("Please enter notes, before updating status", { type: "error" });
      return;
    }
    setSaveNotesLoading(true);
    try {
      //filesUploaded
      let filesUploaded = []
      if (attachments.length > 0) {
        filesUploaded = await uploadAtachments()
        if (filesUploaded.length > 0) {
          setAttachments(prev => [])
        }
        else return
      }
      const response = await jobApi.saveJobApplicationNotes(
        jobApplication.id,
        notes,
        jobApplication.currentStepIndex,
        jobApplication?.applicant?.name,
        jobApplication?.applicant?.email,
        filesUploaded.length > 0 ? filesUploaded : null
      );
      console.log("ee", response);
      const data = response.data.data;
      addNotes(data.notes);
      if (data.attachments) {
        addAttachments(data.attachments)
      }
      toast("Notes has been saved", { type: "success" });
    } catch (error) {
      toast(GENERIC_ERROR_MESSAGE, { type: "error" });
    }
    setSaveNotesLoading(false);
  };

  const isLastStep = () => {
    const currentStep = getCurrentStep();
    if (!currentStep) {
      return true;
    }
    const lastElement = [...steps].pop();
    return lastElement.step == currentStep.step;
  };

  const getCurrentStep = () => {
    let currentStep = null;

    steps.forEach((item) => {
      if (item.step == jobApplication.currentStepIndex) {
        currentStep = item;
      }
    });
    return currentStep;
  };
  const getCurrentStepName = () => {
    const currentStep = getCurrentStep();
    return currentStep?.name || "All steps completed";
  };

  const getApplicationDetailForSave = () => {
    const { job } = jobApplication;
    const { company } = job;
    const { JobAddress } = job;
    const { user } = company;
   
    const momentDate = moment(jobApplication.startDate);
    const date = momentDate.format("MM-DD-YYYY");

    const time = momentDate.format("hh:mm A");

    return {
      contactName: jobApplication?.applicant?.name,
      contactEmail: jobApplication?.applicant?.email,
      contactNumber: company.phone,
      address: `${JobAddress.line1}, ${JobAddress.city}, ${JobAddress.state}, ${JobAddress.zipcode}`,
      notes: notes,
      trainingDate: date,
      jobId: Number(jobApplication.jobId),
      applicantId: jobApplication.userId,
      phone: company.phone,
      date: date,
      time: time,
    };
  };



  const uploadAtachments = async () =>{
    const promiseAll = await attachments.map(async item => {
      try {
        let formData = new FormData();
        formData.append("image", item);
        const res = await FileUpload.upload(formData)
        return res.data.image
      }catch(error) {
          return null
      }

    })
    const finalFileUploadResult = await Promise.all(promiseAll)
    let hasNull = false
    
    finalFileUploadResult.forEach(item => {
      if (item == null) {
        hasNull = true
      }
    })
    if (hasNull) {
      setAttachments(prev => [])
      if (fileInputEventRef.current) {
        fileInputEventRef.current.target.value = null
      }
      
      toast("An error occured while uploading attachments, please try again", { type: "error" });
      return []
    }
    return finalFileUploadResult
  }

  const proceedToNextStep = async () => {
    if (!notes) {
      toast("Please enter notes, before updating status", { type: "error" });
      return;
    }

    let nextStepIndex = null;
    let nextStepText = "";
    const currentStep = getCurrentStep();
    steps.forEach((step) => {
      if (step.step > currentStep.step && !nextStepIndex && !step.isDisabled) {
        nextStepIndex = step.step;
        nextStepText = step.name;
      }
    });
    const lastElement = [...steps].pop();
    setNextStepLoading(true);
    let isAllStepCompleted = lastElement.step == currentStep.step;
    try {
      const dataToPost = {
        jobApplicationId: jobApplication.id,
        notes: notes,
        nextStepIndex: nextStepIndex,
        currentStepIndex: currentStep.step,
        isAllStepCompleted: isAllStepCompleted,
        applicantName: jobApplication?.applicant?.name,
        applicantEmail: jobApplication?.applicant?.email,
        nextStepText: nextStepText,
        applicantDetail: isLastStep() ? getApplicationDetailForSave() : null,
      };
      if (attachments.length > 0) {
        const filesUploaded = await uploadAtachments()
        if (filesUploaded.length > 0) {
          dataToPost.files = filesUploaded
          setAttachments(prev => [])
        }
        else return
      }
      const response = await jobApi.proceedToNextStep(dataToPost);
      const data = response.data.data;

      updateApplication(isAllStepCompleted ? null : nextStepIndex);
      if (lastElement.step == currentStep.step) {
        updateApplication({
          currentStepIndex: 1001,
          status: 1,
        });
      } else {
        updateApplication({ currentStepIndex: nextStepIndex });
      }
      addNotes(data.notes);
      if (data.attachments) {
        addAttachments(data.attachments)
      }
      setNotes("");
      toast(
        isAllStepCompleted
          ? "Applicant has been hired"
          : "Applicant has been moved to next step - " + nextStepText,
        { type: "success" }
      );
    } catch (error) {
      console.log("Ee", error);
      toast(GENERIC_ERROR_MESSAGE, { type: "error" });
    }
    setNextStepLoading(false);
  };

  const rejectApplication = async () => {
    setRejectApplicationLoading(true);
 
   try {
      const currentStep = getCurrentStep();

      const dataToPost = {
        jobApplicationId: jobApplication.id,
        notes: notes,
        currentStepIndex: currentStep.step,
        applicantName: jobApplication?.applicant?.name,
        applicantEmail: jobApplication?.applicant?.email,
      };
      if (attachments.length > 0) {
        const filesUploaded = await uploadAtachments()
        if (filesUploaded.length > 0) {
          dataToPost.files = filesUploaded
          setAttachments(prev => [])
        }
        else return
      }
      const response = await jobApi.rejectApplication(dataToPost);
      const data = response.data.data;
      updateApplication({ currentStepIndex: 1001, status: 2 });
      addNotes(data.notes);
      if (data.attachments) {
        addAttachments(data.attachments)
      }
      setNotes("");
      toast("Applicantion has been rejected", { type: "success" });
    } catch (error) {
      toast(GENERIC_ERROR_MESSAGE, { type: "error" });
    }
    setRejectApplicationLoading(false);
  
  };

  const hireOnSpot = async () => {
    
    setHireOnSpotLoading(true);
    
 
    try {
      const currentStep = getCurrentStep();
      if (attachments.length > 0) {
        const filesUploaded = await uploadAtachments()
        if (filesUploaded.length > 0) {
          dataToPost.files = filesUploaded
          setAttachments(prev => [])
        }
        else return
      }
      const dataToPost = {
        jobApplicationId: jobApplication.id,
        notes: notes,
        currentStepIndex: currentStep.step,
        applicantName: jobApplication?.applicant?.name,
        applicantEmail: jobApplication?.applicant?.email,
      };

      const response = await jobApi.hireOnSpot(dataToPost);
      const data = response.data.data;
      updateApplication({ currentStepIndex: 1001, status: 1 });
      addNotes(data.notes);
      if (data.attachments) {
        addAttachments(data.attachments)
      }
      setNotes("");
      toast("Applicant has been hired", { type: "success" });
    } catch (error) {
      toast(GENERIC_ERROR_MESSAGE, { type: "error" });
    }
    setHireOnSpotLoading(false);
   
  };

  const getCurrentStepNotes = () => {
    const currentStep = getCurrentStep();
    if (!currentStep) {
      return "";
    }
    let notes = "";
    if (jobApplication.jobApplicationNotes) {
      jobApplication.jobApplicationNotes.forEach((note) => {
        console.log("n", note.stepIndex, currentStep);
        if (note.stepIndex == currentStep.step) {
          notes = note.notes;
        }
      });
    }
    return notes;
  };

  return (
    <div>
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>{jobApplication?.applicant?.name} - {getCurrentStepName()}</ModalHeader>
        <ModalBody>
          {/* <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <p style={{fontSize:"1rem"}}>Job Title - {jobApplication?.job?.title}</p>
            <p style={{fontSize:"1rem"}}>Applicant Name - <b>{jobApplication?.applicant?.name}</b></p>
            <p style={{fontSize:"1rem"}}>Current Step - <b>{getCurrentStepName()}</b></p>
          </div> */}
          <div className="form-group">
            <label htmlFor="exampleInputEmail1">Message Applicant <span style={{fontSize:"0.7rem"}}>(Required *)</span></label>
            <textarea  placeholder="Enter Message..."   value={notes} onChange={(e) => setNotes(e.target.value)}   rows={5} type="text" className="form-control"  />
            
        </div>
  <div className="form-group">
    <label htmlFor="exampleInputPassword1">Attachments <span style={{fontSize:"0.7rem"}}>(Not required)</span></label>
    
    <input 
   
    multiple={true} onChange={(e) => {
      setAttachments([...e.target.files])
      fileInputEventRef.current = e
      }} style={{border:"1px solid #e5e5e5", fontSize:"0.8rem"}} className="form-control-file" type={"file"} />
    <small id="emailHelp" className="form-text text-muted">Multiple files can be selected</small>
    
  </div>

  
         
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "1rem",
              flexWrap: "wrap",
            }}
          >
            <button
              type="button"
              onClick={() => saveNotes()}
              disabled={!jobApplication.currentStepIndex}
              className="btn btn-post btn-listening mr-4 mb-4"
            >
              {saveNotesLoading ? (
                <ClipLoader
                  color={"#50bfa5"}
                  loading={true}
                  cssOverride={{ maringLeft: "1rem" }}
                  size={30}
                />
              ) : (
                "Send Message"
              )}
            </button>

            <button
              disabled={jobApplication.currentStepIndex == 1001}
              onClick={() => setShowRejectConfirmModal(true)}
              type="button"
              style={{ background: "red" }}
              className="btn btn-post btn-job "
            >
              {rejectApplicationLoading ? (
                <ClipLoader
                  color={"#fff"}
                  loading={true}
                  cssOverride={{ maringLeft: "1rem" }}
                  size={30}
                />
              ) : (
                "Reject Application"
              )}
            </button>
            <button
              disabled={jobApplication.currentStepIndex == 1001}
              onClick={() => proceedToNextStep()}
              type="button"
              className="btn btn-post btn-job mr-4"
            >
              {nextStepLoading ? (
                <ClipLoader
                  color={"#fff"}
                  loading={true}
                  cssOverride={{ maringLeft: "1rem" }}
                  size={30}
                />
              ) : isLastStep() ? (
                "Hire"
              ) : (
                "Advance to Next Step"
              )}
            </button>

            <button
              disabled={jobApplication.currentStepIndex == 1001}
              onClick={() => setShowHireOnSpotConfirmModal(true)}
              type="button"
              className="btn btn-post btn-job "
            >
              {hireOnSpotLoading ? (
                <ClipLoader
                  color={"#fff"}
                  loading={true}
                  cssOverride={{ maringLeft: "1rem" }}
                  size={30}
                />
              ) : (
                "Hire On Spot"
              )}
            </button>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={showRejectConfirmModal} toggle={() => setShowRejectConfirmModal(false)}>
        <ModalHeader toggle={toggle}>Confirmation </ModalHeader>
        <ModalBody>
                  <p>Are you sure you want to reject this application? This action cannot be undone</p>
        </ModalBody>
        <ModalFooter>
        <Button color="danger" onClick={()=>{
          setShowRejectConfirmModal(false)
          rejectApplication()
        }}>
            Confirm
          </Button>
   
          <Button color="secondary" onClick={()=> setShowRejectConfirmModal(false)}>
            Cancel
          </Button>
        </ModalFooter>
        </Modal>

        <Modal isOpen={showHireOnSpotConfirmModal} toggle={() => setShowHireOnSpotConfirmModal(false)}>
        <ModalHeader toggle={toggle}>Confirmation </ModalHeader>
        <ModalBody>
                  <p>Are you sure you want to hire this application and skip  the workflow sequence? This action cannot be undone</p>
        </ModalBody>
        <ModalFooter>
        <Button color="danger" onClick={()=>{
          setShowHireOnSpotConfirmModal(false)
         hireOnSpot()
        }}>
            Confirm
          </Button>
   
          <Button color="secondary" onClick={()=>setShowHireOnSpotConfirmModal(false)}>
            Cancel
          </Button>
        </ModalFooter>
        </Modal>
    </div>
  );
};
