import React, { useState, useEffect } from 'react'
import DatePicker from 'react-datepicker'
import fileApi from '../../../Shared/services/media'
import {
  Input,
  ModalBody,
  Modal,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Spinner,
} from 'reactstrap'
import trainingType from '../../../Shared/services/trainingTypes'
import jobApi from '../../../Shared/services/job'
import moment from 'moment'
import '../postJob/postjob.css'
import { convertToTime, createExcludedDates } from '../../../Shared/utils'
import JobPhotos from '../postJob/JobPhotos'
import { useHistory } from 'react-router-dom'
import { PrviewJob } from '../../../components'
import { toast } from 'react-toastify'
import { BASE_URL } from '../../../constants/index'
import AutocompleteInput from '../../../components/PlaceAutoComplete'
import './edit.css'
import { handleError } from '../../../utils'

const EditJob = (props) => {
  const [job, setJob] = useState({
    startDates: [], //JobDetail.startDates,
    photos: [], //JobDetail.photos,
    skills: [], //JobDetail.skills,
    questions: [],
    jobAddress: '',
    jobAddressId: null,
  })
  const [jobAddress, setJobAddress] = useState({
    isChanged: false,
    line1: '',
    line2: '',
    city: '',
    state: '',
    zipcode: '',
    lat: '',
    lng: '',
  })
  const [trainingTypes, settrainingTypes] = useState([])
  const [trDurations, setTrDurations] = useState([])
  // const [excludedDates, setexcludedDates] = useState([]);   // defined below
  // const [, setDates] = useState([]);
  const [skill, setskill] = useState('')
  // const [] = useState([]);
  const [open, setopen] = useState(false)
  // const [] = useState([]);
  const [preview] = useState(false)
  const [message] = useState('')
  // const [] = useState({});
  const [DurationErrorMessage, setDurationErrorMessage] = useState('')
  const [loading, setloading] = useState(true)

  const onJobAddressChanged = (addr) => {
    console.log('Addr', addr)
    console.log('jobaddr', jobAddress)
    setJobAddress({ ...jobAddress, ...addr, isChanged: true })
  }

  const getJobDetail = async () => {
    setloading(true)
    try {
      window.scrollTo(0, 100)
      const { data } = await jobApi.getDetail(Number(props.match.params.ID))
      const _job = JSON.parse(JSON.stringify(data))
      console.log('jobfewkjfwekfbb', _job)
      setJob({
        ...data,
        trDuration: _job.trDuration / 24,
        startDates: _job.startDates,
        photos: _job.photos,
        skills: _job.skills,
        questions: _job.questions,
        jobAddress: `${_job?.JobAddress?.line1 ? _job?.JobAddress?.line1 + ',' : ''
          } ${_job?.JobAddress?.city} ${_job?.JobAddress?.state} ${_job?.JobAddress?.zipcode
          }`,
        jobAddressId: _job?.JobAddress?.id,
      })
      const docs = await trainingType.get()
      settrainingTypes([...docs.data.data])
      let trainingTypeIdOfJob = 1
      docs.data.data.map((trType) => {
        if (_job.trainingType == trType.title) {
          trainingTypeIdOfJob = trType.id
        }
      })
      const res = await trainingType.getDurations(trainingTypeIdOfJob)
      setTrDurations([...res.data.data])
    } catch (err) {
      console.log('errorrr', err)
    }
    setloading(false)
  }
  useEffect(() => {
    getJobDetail()
  }, [])

  const handleChange = (event) => {
    console.log('eeee', event.target.name, event.target.value)
    const { type, checked, name, value } = event.target
    setJob({
      ...job,
      [name]: type === 'checkbox' ? checked : value,
    })
  }

  const updateJobPhotos = (photos) => {
    setJob({ ...job, photos: photos })
  }

  const onTrainingChange = (event) => {
    setJob({ ...job, trainingType: event.target.value })
    let ID = 0
    for (let i = 0; i < trainingTypes.length; i++) {
      if (trainingTypes[i].title === event.target.value) {
        ID = trainingTypes[i].id
        break
      }
    }

    trainingType.getDurations(ID).then((res) => {
      setTrDurations(res.data.data)
      console.log('Reee', res.data.data)
      setJob({
        ...job,
        trDuration: res.data.data[0].days,
        trainingType: event.target.value,
      })
    })
  }

  const deleteSkill = (id, index) => {
    jobApi
      .removeSkill(id, job.id)
      .then(
        (res) => {
          toast(res.data.message, { type: 'success' })
          job.skills.splice(index, 1)
          setJob({ ...job })
          // setSkills([...skills]);
        },
        (err) => {
          toast(err.response.data.message, { type: 'error' })
        }
      )
      .catch((err) => {
        toast(err.message, { type: 'error' })
      })
  }

  const addSkill = (e) => {
    e.preventDefault()
    jobApi
      .addSkill(skill, job.id)
      .then(
        (res) => {
          setskill('')
          // setSkills([...skills, res.data.data]);
          job.skills.push(res.data.data)
          setJob({ ...job })
          toast(res.data.message, { type: 'success' })
        },
        (err) => {
          toast(err.response.data.message, { type: 'error' })
        }
      )
      .catch((err) => {
        toast(err.message, { type: 'error' })
      })
  }

  const handleUpdateJobInfo = () => {
    console.log('o', jobAddress)
    // event.preventDefault();
    let dataToUpdate = {
      id: job.id,
      trainingType: job.trainingType,
      isPaid: job.isPaid,
      title: job.title,
      description: job.description,
      noOfOpenings: job.noOfOpenings,
      trDuration: parseInt(parseFloat(job.trDuration) * 24),
      hourlyRate: job.hourlyRate,
    }
    if (jobAddress.isChanged) {
      dataToUpdate.jobAddress = {
        ...jobAddress,
        jobAddressId: job.jobAddressId,
      }
    }
    console.log('dataato upd', dataToUpdate)
    jobApi
      .updateJob(dataToUpdate)
      .then((res) => {
        toast(res.data.message || res.message, { type: 'success' })
        updateExcludedDates()
      })
      .catch((err) => {
        toast(err.message, { type: 'error' })
        setDurationErrorMessage(err.response.data.message)
        toast(err.response.data.message, { type: 'error' })
      })
  }

  // ---------- job photos methods ---------
  const uploadFile = (e) => {
    setloading(true)
    console.log(e)
    const { files } = e.target
    let formdata = new FormData()
    formdata.append('image', files[0])
    fileApi
      .upload(formdata)
      .then((res) => {
        setloading(false)
        return jobApi.addJobPhoto(job.id, res.data.image)
      })
      .then(
        (jobphotoRes) => {
          job.photos.push(jobphotoRes.data.data)
          setJob({ ...job })
          // setjobPhotos([...jobPhotos, jobphotoRes.data.data]);
        },
        (err) => {
          toast(err.response.data.message, { type: 'error' })
        }
      )
      .catch((err) => {
        setloading(false)
        toast(err.message, { type: 'error' })
      })
  }

  const deleteJobPhoto = (id, index) => {
    jobApi
      .deleteJobPhoto(id)
      .then(
        (res) => {
          job.photos.splice(index, 1)
          setJob({ ...job })
          toast(res.data.message, { type: 'success' })
        },
        (err) => {
          toast(err.response.data.message, { type: 'error' })
        }
      )
      .catch((err) => {
        console.log(err)
        toast(err.message, { type: 'error' })
      })
  }

  // ------------- start dates --------------------
  const [excludedDates, setexcludedDates] = useState([])

  const [startDate, setstartDate] = useState({ date: '', time: '' })

  const updateExcludedDates = () => {
    let dates = createExcludedDates(job.startDates, job.trDuration)
    if (job.trainingType === 'Job Shadow') {
      setexcludedDates([...dates])
      // alert(JSON.stringify(dates));
    } else {
      setexcludedDates([])
    }
  }
  useEffect(() => {
    let dates = createExcludedDates(job.startDates, job.trDuration)
    if (job.trainingType === 'Job Shadow') {
      setexcludedDates([...dates])
      // alert(JSON.stringify(dates));
    } else {
      setexcludedDates([])
    }
  }, [job.startDates])

  const addStartDate = (date) => {
    console.log('dateeeeeee', date)
    jobApi
      .addStartDate(
        new Date(
          new Date(date.date).toDateString() + ' ' + date.time
        ).toUTCString(),
        job.id
      )
      .then((res) => {
        job.startDates.push(res.data.data)
        setJob({ ...job })
        updateExcludedDates()
        toast(res.data.message, { type: 'success' })
      })
      .catch((error) => {
        handleError(error)

        toast(message, { type: 'error' })
      })
  }

  const deleteStartDate = (id, index) => {
    jobApi
      .removeStartDate(id, job.id)
      .then(() => {
        toast('removed start date successfully', { type: 'success' })
        job.startDates.splice(index, 1)
        setJob({ ...job })
        updateExcludedDates()
      })
      .catch((err) => handleError(err))
  }

  const handleQuestionsChange = (value, qid, index, event) => {
    jobApi
      .updateJobAnswer(qid, value)
      .then((res) => {
        job.questions[index].answer = value
        setJob({ ...job })
        toast('Answer updated successfully', { type: 'success' })
      })
      .catch((err) => toast(err.message, { type: 'error' }))
  }

  const [activeTab, setactiveTab] = useState('1')
  return (
    <>
      {loading && (
        <div className="d-flex">
          <div style={{ margin: 'auto' }}>
            <Spinner color="info" />
          </div>
        </div>
      )}
      {job && (
        <section className="bg-default-2 pt-16 pt-lg-25 pb-lg-27">
          <Modal isOpen={preview}>
            <ModalBody>
              <PrviewJob job={job} />
            </ModalBody>
          </Modal>

          <JobPhotos
            setopen={setopen}
            open={open}
            job={job}
            setJob={setJob}
            updateJobPhotos={updateJobPhotos}
          />

          <div className="post-job">
            <div className="post-job-wrap py-5 my-2">
              <div className="my-width">
                <div className="d-flex flex-row">
                  <div className="col-md-12 col-lg-12 col-sm-12">
                    <div className="post-job-header">
                      <h2 className="circular-normal font-size-8 font-weight-semibold mb-0">
                        Edit Job Post
                      </h2>
                      <p className="font-size-4  pt-lg-0">
                        {DurationErrorMessage}
                      </p>
                      <p
                        style={{ color: 'red' }}
                        className="text-danger danger"
                      >
                        {message}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12 col-12 col-lg-12">
                    <div className="post-job">
                      <Nav tabs>
                        <NavItem>
                          <NavLink
                            onClick={() => setactiveTab('1')}
                            className={`${activeTab === '1' ? 'active pointer' : 'pointer'
                              }`}
                          >
                            Job Info
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            onClick={() => setactiveTab('2')}
                            className={`${activeTab === '2' ? 'active pointer' : 'pointer'
                              }`}
                          >
                            Start Dates
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            onClick={() => setactiveTab('3')}
                            className={`${activeTab === '3' ? 'active pointer' : 'pointer'
                              }`}
                          >
                            Skills
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            onClick={() => setactiveTab('4')}
                            className={`${activeTab === '4' ? 'active pointer' : 'pointer'
                              }`}
                          >
                            Job Photos
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            onClick={() => setactiveTab('5')}
                            className={`${activeTab === '5' ? 'active pointer' : 'pointer'
                              }`}
                          >
                            Job Questionaires
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                          <div className="row">
                            <div className="col-md-12 col-lg-12 col-sm-12">
                              <div className="card_pj">
                                <div className="post-job-body">
                                  <form>
                                    {/* training type  */}
                                    <div className="row align-items-center">
                                      <div className="col-lg-12 col-12">
                                        <div className="form-group position-relative">
                                          <label
                                            htmlFor="my-select"
                                            className="font-size-4 text-black-2 font-weight-semibold line-height-reset d-block"
                                          >
                                            Training Experience
                                          </label>
                                          {trainingTypes.length > 0 && (
                                            <Input
                                              name="trainingType"
                                              value={job.trainingType}
                                              onChange={onTrainingChange}
                                              type="select"
                                              id="trtype"
                                              className="form-control form-select-unique"
                                            >
                                              {trainingTypes.map((trt) => (
                                                <option
                                                  key={trt.id}
                                                  value={trt.title}
                                                >
                                                  {trt.title}
                                                </option>
                                              ))}
                                            </Input>
                                          )}
                                          <p className={'text-warning'}>
                                            <small>
                                              {' '}
                                              Warning! If you change the
                                              training Type, Your job duration
                                              will also be changed.
                                            </small>
                                          </p>
                                        </div>
                                      </div>
                                    </div>

                                    {/* training description  */}
                                    <div className="row align-items-center">
                                      <div className="col-lg-12 col-12">
                                        <div className="form-group position-relative">
                                          <label
                                            htmlFor="my-select"
                                            className="font-size-4 text-black-2 font-weight-semibold line-height-reset d-block"
                                          >
                                            Training Description
                                          </label>
                                          <Input
                                            name="description"
                                            value={job.description}
                                            placeholder="Hint: Please write 5 - 8 sentence or bullet point description of the training opportunity. Please include high-level activities, responsibilities, and types of tasks."
                                            onChange={handleChange}
                                            type="textarea"
                                            className="form-control"
                                            rows={5}
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    {/* title  */}
                                    <div className="row">
                                      <div className="col-lg-12 col-12">
                                        <div className="form-group position-relative">
                                          <label
                                            htmlFor="my-input"
                                            className="font-size-4 text-black-2 font-weight-semibold line-height-reset d-block"
                                          >
                                            Job Title{' '}
                                            <small>
                                              Try to summarise your project in
                                              one sentence.
                                            </small>
                                          </label>
                                          <input
                                            id="my-input"
                                            className="form-control"
                                            type="text"
                                            name="title"
                                            value={job.title}
                                            onChange={handleChange}
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className="col-lg-12 col-12">
                                        <div className="form-group position-relative">
                                          <label
                                            htmlFor="my-input"
                                            className="font-size-4 text-black-2 font-weight-semibold line-height-reset d-block"
                                          >
                                            Job Address
                                          </label>
                                          <AutocompleteInput
                                            setLoading={() => { }}
                                            setLatlong={onJobAddressChanged}
                                            setAddress={onJobAddressChanged}
                                            value={job.jobAddress}
                                            setValue={(addr) =>
                                              setJob({
                                                ...job,
                                                jobAddress: addr,
                                              })
                                            }
                                            inputClass={`form-control`}
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className="col-lg-12">
                                        <div className="form-group position-relative">
                                          <label
                                            htmlFor="my-select"
                                            className="font-size-4 text-black-2 font-weight-semibold line-height-reset d-block"
                                          >
                                            Duration
                                          </label>
                                          {trDurations.length > 1 && (
                                            <Input
                                              name="trDuration"
                                              value={job.trDuration}
                                              onChange={handleChange}
                                              type="select"
                                              className="form-control form-select-unique"
                                            >
                                              {trDurations.map((trd, index) => (
                                                <option
                                                  key={index}
                                                  value={trd.days}
                                                >
                                                  {trd.title}
                                                </option>
                                              ))}
                                            </Input>
                                          )}
                                          {trDurations.length === 1 &&
                                            trDurations[0].type === 'text' && (
                                              <Input
                                                name="trDuration"
                                                value={job.trDuration}
                                                onChange={handleChange}
                                                type="number"
                                                placeholder="no. of days "
                                              >
                                                {trDurations.map(
                                                  (trd, index) => (
                                                    <option
                                                      key={index}
                                                      value={trd.days}
                                                    >
                                                      {trd.title}
                                                    </option>
                                                  )
                                                )}
                                              </Input>
                                            )}
                                        </div>
                                      </div>
                                    </div>

                                    <div className="d-flex flex-column">
                                      <label className="font-size-4 text-black-2 font-weight-semibold line-height-reset d-block">
                                        Paid
                                      </label>
                                      <div className="d-flex">
                                        <label className="container1">
                                          <span className="radio-text">
                                            Yes
                                          </span>
                                          <input
                                            type="radio"
                                            checked={job.isPaid}
                                            name="isPaid"
                                            value={true}
                                            onChange={() =>
                                              setJob({ ...job, isPaid: true })
                                            }
                                          />
                                          <span className="checkmark"></span>
                                        </label>
                                        <label className="container1 ml-4">
                                          <span className="radio-text no">
                                            No
                                          </span>
                                          <input
                                            type="radio"
                                            name="isPaid"
                                            value={false}
                                            onChange={() =>
                                              setJob({ ...job, isPaid: false })
                                            }
                                            checked={!job.isPaid}
                                          />
                                          <span className="checkmark"></span>
                                        </label>
                                      </div>
                                    </div>

                                    {(job.isPaid === 'true' ||
                                      job.isPaid === true) && (
                                        <div className="row">
                                          <div className="col-lg-5">
                                            <div className="form-group position-relative">
                                              <label
                                                htmlFor="my-input"
                                                className="font-size-4 text-black-2 font-weight-semibold line-height-reset d-block"
                                              >
                                                Pay (Hourly Rate)
                                              </label>
                                              <input
                                                id="my-input"
                                                className="form-control"
                                                type="text"
                                                name="hourlyRate"
                                                value={job.hourlyRate}
                                                onChange={handleChange}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      )}

                                    <div className="row">
                                      <div className="col-lg-5">
                                        <div className="form-group position-relative">
                                          <label
                                            htmlFor="my-select"
                                            className="font-size-4 text-black-2 font-weight-semibold line-height-reset d-block"
                                          >
                                            Available spaces
                                          </label>
                                          <input
                                            id="my-input"
                                            className="form-control"
                                            type="number"
                                            name="noOfOpenings"
                                            value={job.noOfOpenings}
                                            min={1}
                                            onChange={handleChange}
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div className="form-group">
                                      <div className="d-flex justify-content-end">
                                        <button
                                          type="button"
                                          className="btn btn-post btn-job ml-4"
                                          onClick={() => handleUpdateJobInfo()}
                                        >
                                          Update Job Info
                                        </button>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                                {/* dssds */}
                              </div>
                            </div>
                          </div>
                        </TabPane>

                        {/* start dates  */}
                        <TabPane tabId="2">
                          <>
                            <div className="row">
                              <div className="col-md-12 col-lg-12 col-sm-12">
                                <div className="card_pj">
                                  <div className="post-job-body">
                                    {job.startDates.map((itm, index) => (
                                      <StartDate
                                        startDate={itm}
                                        key={index}
                                        onRemove={() =>
                                          deleteStartDate(itm.id, index)
                                        }
                                      />
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-12 col-lg-12 col-sm-12">
                                <div className="card_pj">
                                  <div className="post-job-body">
                                    {/* <AddStartDate
                                      excludedDates={excludedDates}
                                      onAdd={(date) => addStartDate(date)}
                                    /> */}

                                    <div className="d-flex">
                                      <span
                                        style={{
                                          width: '250px',
                                          marginRight: '20px',
                                        }}
                                      >
                                        <DatePicker
                                          style={{ marginRight: '1em' }}
                                          timeFormat="HH:mm"
                                          selected={startDate.date}
                                          onChange={(date) => {
                                            setstartDate({
                                              ...startDate,
                                              date: date,
                                            })
                                          }}
                                          excludeDates={excludedDates}
                                          dateFormat="MMMM d, yyyy"
                                          dropDownMode="select"
                                          placeholderText="Select date"
                                          value={startDate.date}
                                          className="form-control"
                                        />
                                      </span>
                                      <span
                                        style={{
                                          width: '250px',
                                          marginRight: '20px',
                                        }}
                                      >
                                        <DatePicker
                                          showTimeSelect
                                          timeFormat="HH:mm"
                                          selected={startDate.time}
                                          showTimeSelectOnly
                                          onChange={(e) =>
                                            setstartDate({
                                              ...startDate,
                                              time: e,
                                            })
                                          }
                                          dateFormat="h:mm aa"
                                          dropDownMode="select"
                                          placeholderText={'Select time'}
                                          value={startDate.time}
                                          className="form-control"
                                        />
                                      </span>
                                      <button
                                        disabled={
                                          !startDate.date || !startDate.time
                                        }
                                        onClick={() => {
                                          addStartDate({
                                            ...startDate,
                                            time: convertToTime(startDate.time),
                                          })
                                          setstartDate({
                                            date: null,
                                            time: null,
                                          })
                                        }}
                                        className="btn btn-primary btn-lg"
                                      >
                                        Add
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        </TabPane>

                        {/* job skills  */}
                        <TabPane tabId="3">
                          <>
                            <div className="row">
                              <div className="col-md-12 col-lg-12 col-sm-12">
                                <div className="card_pj">
                                  <div className="post-job-body">
                                    {job.skills.map((itm, index) => (
                                      <SkillPreview
                                        key={index}
                                        skill={itm}
                                        onClose={() => deleteSkill(itm.id)}
                                      />
                                    ))}

                                    <form onSubmit={addSkill}>
                                      <label>Add New Skill</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        value={skill}
                                        onChange={(e) =>
                                          setskill(e.target.value)
                                        }
                                      />
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        </TabPane>

                        {/* job photos  */}
                        <TabPane tabId="4">
                          <>
                            <div className="row">
                              <div className="col-12 col-lg-12 col-md-12">
                                <div className="card_pj">
                                  <div className="post-job-body">
                                    {job.photos.length > 0 &&
                                      job.photos.map((itm, index) => (
                                        <>
                                          <JobPhoto
                                            jobPhoto={itm}
                                            onClose={() =>
                                              deleteJobPhoto(itm.id, index)
                                            }
                                          />
                                        </>
                                      ))}
                                    <div className="form-group">
                                      <label style={{ display: 'block' }}>
                                        Add New Workplace Photos
                                      </label>
                                      <input
                                        type="file"
                                        style={{ display: 'block' }}
                                        onChange={(e) => {
                                          uploadFile(e)
                                        }}
                                      />
                                      {loading && (
                                        <i className="fa fa-spinner-pulse fa-spinner"></i>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        </TabPane>
                        <TabPane tabId="5">
                          <>
                            <div className="row">
                              <div className="col-12 col-lg-12 col-md-12">
                                <div className="card_pj">
                                  <div className="post-job-body">
                                    <form action="" method="post">
                                      <div className="questionaire-list">
                                        <ol className="list-unstyled">
                                          {job.questions.map((q, qindex) => {
                                            console.log("qyesion ", q)
                                            return (
                                              <li key={qindex}>
                                                <div className="questionaire-list-description">
                                                  <p>{q.question.question}</p>
                                                  {['Yes', 'No'].map(
                                                    (op, opIndex) => (
                                                      <div
                                                        className="d-flex"
                                                        key={opIndex}
                                                      >
                                                        <label className="container1">
                                                          <span className="radio-text">
                                                            {op}
                                                          </span>
                                                          <input
                                                            type="radio"
                                                            name={q.id}
                                                            checked={
                                                              q.answer == op || (op == "Yes" ? q.answer == "true" || q.answer == true : q.answer == "false" || q.answer == false)
                                                            }
                                                            value={op}
                                                            onChange={(event) =>
                                                              handleQuestionsChange(
                                                                event.target
                                                                  .value,
                                                                q.id,
                                                                qindex,
                                                                event
                                                              )
                                                            }
                                                            id={qindex}
                                                          />
                                                          <span className="checkmark"></span>
                                                        </label>
                                                      </div>
                                                    )
                                                  )}
                                                </div>
                                              </li>
                                            )
                                          })}
                                        </ol>
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        </TabPane>
                      </TabContent>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  )
}

export default EditJob

export const EditStartDate = ({ startDates = [], duration }) => {
  const [excludedDates, setexcludedDates] = useState([])
  const [_stDates, set_stDates] = useState(startDates)

  useEffect(() => {
    let dates = createExcludedDates(_stDates, duration)
    setexcludedDates([...dates])
    // alert("added ... st dates ..");
  }, [_stDates])

  const addStartDate = (date) => {
    jobApi
      .addStartDate(date.date, date.time, 4)
      .then((res) => {
        console.log(res)
        set_stDates([..._stDates, res.data.data])
        // alert("added job start date");
      })
      .catch((err) => {
        console.log('--- add start date err -----')
        console.log(err)
        console.log('--- add start date err -----')
      })
  }

  const deleteStartDate = (id, index) => {
    jobApi
      .removeStartDate(id)
      .then(() => {
        toast('Removed Start Date', { type: 'success' })
        // alert("removed ");
        _stDates.splice(index, 1)
        set_stDates([..._stDates])
      })
      .catch((err) => toast(err.message, { type: 'error' }))
  }

  return (
    <>
      <div className="row">
        <div className="col-md-12 col-lg-12 col-sm-12">
          <div className="card_pj">
            <div className="post-job-body">
              {_stDates.map((itm, index) => (
                <StartDate
                  startDate={itm}
                  key={index}
                  onRemove={() => deleteStartDate(itm.id, index)}
                />
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12 col-lg-12 col-sm-12">
          <div className="card_pj">
            <div className="post-job-body">
              <AddStartDate
                excludedDates={excludedDates}
                onAdd={(date) => addStartDate(date)}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export const StartDate = ({ startDate, onRemove }) => {
  return (
    <div className="d-flex">
      <span className="form-control badge-date">
        {moment(startDate.date).format('MMM DD, YYYY')}
      </span>
      <span className="form-control badge-date">
        {moment(startDate.date).format('hh:mm A')}
      </span>
      <span onClick={onRemove} className="badge-date-danger badge-date">
        Delete
      </span>
    </div>
  )
}

export const AddStartDate = ({ onAdd, excludedDates }) => {
  const [startDate, setstartDate] = useState({ date: '', time: '' })
  return (
    <div className="d-flex">
      {/* <p>{JSON.stringify(excludedDates)}</p> */}
      {/* <input
        className="form-control"
        value={startDate.date}
        type="date"
        onChange={(e) => setstartDate({ ...startDate, date: e.target.value })}
      /> */}
      <DatePicker
        timeFormat="HH:mm"
        selected={startDate.date}
        onChange={(date) => {
          setstartDate({ ...startDate, date: date })
        }}
        excludeDates={excludedDates}
        dateFormat="MMMM d, yyyy"
        dropDownMode="select"
        placeholderText="Select date"
        value={startDate.time}
        className="form-control"
      />
      <DatePicker
        showTimeSelect
        timeFormat="HH:mm"
        selected={startDate.time}
        showTimeSelectOnly
        onChange={(e) => setstartDate({ ...startDate, time: e })}
        dateFormat="h:mm aa"
        dropDownMode="select"
        placeholderText={'Select time'}
        value={startDate.time}
        className="form-control"
      />
      {/* <input
        className="form-control"
        type="time"
        value={startDate.date}
        onChange={(e) => setstartDate({ ...startDate, time: e.target.value })}
      /> */}
      <button
        disabled={!startDate.date || !startDate.time}
        onClick={() => {
          onAdd({ ...startDate, time: convertToTime(startDate.time) })
          setstartDate({ date: null, time: null })
        }}
        className="btn btn-primary btn-lg"
      >
        Add
      </button>
    </div>
  )
}

export const SkillPreview = ({ onClose, skill }) => {
  return (
    <div style={{ position: 'relative', display: 'inline-block' }}>
      <span
        style={{
          position: 'absolute',
          right: '1em',
          top: '10px',
          cursor: 'pointer',
          color: 'red',
        }}
        onClick={onClose}
      >
        {/* X */}
        <i className="fa fa-times-circle"></i>
      </span>
      <span
        // style={{ padding: "1em", margin: "2em", "margin-left": "0" }}
        className="badge-date"
      >
        {skill.skill}
      </span>
    </div>
  )
}

export const SkillList = ({ jobSkills, jobID }) => {
  const [skill, setskill] = useState('')
  const [skills, setSkills] = useState([...jobSkills])
}

export const EditJobPhotos = ({ job, jobID }) => {
  const [loading, setloading] = useState(false)
  const [jobPhotos, setjobPhotos] = useState(job.photos)

  const uploadFile = (e) => {
    setloading(true)
    console.log(e)
    const { files } = e.target
    let formdata = new FormData()
    formdata.append('image', files[0])
    fileApi
      .upload(formdata)
      .then((res) => {
        console.log(res)
        setloading(false)
        return jobApi.addJobPhoto(jobID, res.data.image)
      })
      .then(
        (jobphotoRes) => {
          setjobPhotos([...jobPhotos, jobphotoRes.data.data])
        },
        (err) => {
          console.log('-check res err -')
          console.log(err)
          console.log('-check res err -')
          toast(err.response.data.message, { type: 'error' })
        }
      )
      .catch((err) => {
        console.log(err)
        setloading(false)
        toast(err.message, { type: 'error' })
      })
  }

  const deleteJobPhoto = (id, index) => {
    jobApi
      .deleteJobPhoto(id)
      .then(
        (res) => {
          jobPhotos.splice(index, 1)
          toast(res.data.message, { type: 'success' })
        },
        (err) => {
          toast(err.response.data.message, { type: 'error' })
        }
      )
      .catch((err) => {
        console.log(err)
        toast(err.message, { type: 'error' })
      })
  }

  return (
    <>
      <div className="row">
        <div className="col-12 col-lg-12 col-md-12">
          <div className="card_pj">
            <div className="post-job-body">
              {jobPhotos.map((itm, index) => (
                <>
                  <JobPhoto
                    jobPhoto={itm}
                    onClose={() => deleteJobPhoto(itm.id, index)}
                  />
                </>
              ))}
              <div className="form-group">
                <label style={{ display: 'block' }}>Add new jobphoto</label>
                <input
                  type="file"
                  style={{ display: 'block' }}
                  onChange={(e) => {
                    uploadFile(e)
                  }}
                />
                {loading && <i className="fa fa-spinner-pulse fa-spinner"></i>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export const JobPhoto = ({ onClose, jobPhoto }) => {
  return (
    <>
      <div
        style={{
          position: 'relative',
          display: 'inline-block',
          background: 'black',
          border: '1px solid gray',
          margin: '1em',
          marginLeft: '0',
        }}
      >
        <span
          style={{
            fontSize: '20px',
            color: 'red',
            position: 'absolute',
            right: -10,
            top: -10,
            lineHeight: 1,
            background: '#fff',
            cursor: 'pointer',
          }}
          onClick={onClose}
        >
          <i className="fa fa-times-circle"></i>
        </span>
        <img
          style={{ border: '2px solid gray' }}
          src={BASE_URL + jobPhoto.image}
          width="75"
          alt=""
        />
      </div>
    </>
  )
}
