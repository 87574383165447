import {
  EDUCATION_LOADED_FAILED,
  EDUCATION_LOADED_SCCESS,
  EDUCATION_LOADING,
} from "../actions/education";

import industryApi from "../../Shared/services/industry";

const initialState = {
  loading: false,
  educations: [],
};

// : { login: false, roleId:1, user: null };

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case EDUCATION_LOADING:
      return {
        ...state,
        loading: true,
      };

    case EDUCATION_LOADED_SCCESS:
      return {
        ...state,
        loading: false,
        educations: payload,
      };
    default:
      return state;
  }
}
