import React, { useEffect, useState } from "react";
import menuApi from "../../Shared/services/menu";
import { useSelector } from "react-redux";

import "./footer.css";
import { useHistory } from "react-router";

const Footer = () => {
  const [menus, setMenus] = useState([]);
  const { auth, common } = useSelector((state) => state);
  const { login } = auth;
  useEffect(() => {
    setMenus(common.menu);
  }, [common.menu]);

  const history = useHistory();
  /*   useEffect(() => {
    menuApi
      .get()
      .then((res) => {
        setmenus([...res.data.data]);
      })
      .catch((err) => console.log(err));
  }, []); */

  return (
    <footer className="footer bg-ebony-clay dark-mode-texts">
      <div className="cs-container">
        <div className="py-10 py-lg-14 border-bottom border-width-1 border-default-color-2">
          <div className="text-center">
            <h2 className="text-white font-size-8 mb-0 font-weight-normal">
              The Comprehensive Portal for Workforce Training
            </h2>
            <p className="text-hit-gray font-size-4 mb-6">
              Ride the wave of innovation in workforce.
            </p>
          </div>
          <div className="row justify-content-center">
            {!login && (
              <div
                className="col-xl-5 col-lg-12 aos-init aos-animate"
                data-aos="fade-left"
                data-aos-duration="800"
                data-aos-once="true"
              >
                <div className="btns d-flex justify-content-xl-end justify-content-center align-items-xl-center flex-wrap h-100  mx-n4">
                  <a
                    className="btn btn-outline-gallery btn-xl mx-4 mt-6 text-uppercase"
                    onClick={() => history.push("/login")}
                    style={{ height: "50px" }}
                  >
                    Log in
                  </a>
                  <a
                    className="btn btn-success btn-h-60 btn-xl mx-4 mt-6 text-uppercase"
                    style={{ height: "50px" }}
                    onClick={() => history.push("/signup")}
                  >
                    Register
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="cs-container pt-10 pt-lg-14 pb-7">
        <div className="row">
          <div className="col-lg-4 col-sm-6 mb-lg-0 mb-9">
            <img
              src="./images/svgs/logo/workabl_trans_img.png"
              alt=""
              className="footer-logo mb-11"
              width="200px"
            />
            <div className="media mb-9">
              <img
                src="image/l1/png/message.png"
                className="align-self-center mr-3"
                alt=""
              />
              <div className="media-body pl-5">
                <p className="mb-0 font-size-4 text-white">Contact us at</p>
                <a
                  className="mb-0 font-size-4 font-weight-bold"
                  href="mailto:support@workabl.co"
                >
                  support@workabl.co
                </a>
              </div>
            </div>
            <div className="social-icons">
              <ul className="pl-0 list-unstyled d-flex align-items-end ">
                <li className="d-flex flex-column justify-content-center px-3 mr-3 font-size-4 text-footer">
                  Follow us on:
                </li>
                <li className="d-flex flex-column justify-content-center px-3 mr-3">
                  <a
                    href="https://www.linkedin.com/company/workabl/?viewAsMember=true"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="hover-color-primary text-footer"
                  >
                    <i className="fab fa-facebook-f font-size-6 pt-2"></i>
                  </a>
                </li>
                
                {/* we do not currently have a twitter so this is not active */}
                
                {/* <li className="d-flex flex-column justify-content-center px-3 mr-3">
                  <a
                    href="home-2.html#"
                    className="hover-color-primary text-footer"
                  >
                    <i className="fab fa-twitter font-size-6 pt-2"></i>
                  </a>
                </li> */} 
                <li className="d-flex flex-column justify-content-center px-3 mr-3">
                  <a
                    href="https://www.linkedin.com/company/trendlines-usa/?viewAsMember=true"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="hover-color-primary text-footer"
                  >
                    <i className="fab fa-linkedin-in font-size-6 pt-2"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-8 col-md-6">
            <div className="row">
              {menus.map((category, index) => (
                <FooterMenuCategory key={index} menuItem={category} />
              ))}
            </div>
          </div>
        </div>
        <div className="copy text-center">
          <p className="text-hit-gray font-size-4 mb-0 mt-10">
            Made in{" "}
            <img src="./images/flagUs-min.png" alt="us flag" width="24" />
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

export const FooterMenuCategory = ({ menuItem }) => {
  return (
    <div className="col-lg-3 col-md-6 col-sm-3 col-xs-6">
      <div className="footer-widget widget2 mb-md-0 mb-13">
        <p className="widget-title font-size-4 text-white mb-md-8 mb-7">
          {menuItem.title}
        </p>
        <ul className="widget-links pl-0 list-unstyled list-hover-primary footer-list">
          {[...menuItem.menus]
            .sort((a, b) => a.index - b.index)
            .map((itm, index) => (
              <FooterMenuItem key={index} Item={itm} />
            ))}
        </ul>
      </div>
    </div>
  );
};

export const FooterMenuItem = ({ Item }) => {
  const slugify = (title, ID) => {
    return title.replace(" ", "-") + "-" + ID;
  };
  return (
    <li className="mb-6">
      <a
        href={`/static/${slugify(Item.title, Item.id)}`}
        className="heading-default-color font-size-4 font-weight-normal"
      >
        {Item.title}
      </a>
    </li>
  );
};
