export const findAddress = (_address, _addresses) => {
  const address_components = _addresses;
  for (let i = 0; i < address_components.length; i++) {
    if (address_components[i].types.indexOf(_address) > -1) {
      return address_components[i].long_name;
    }
  }
  return "un named";
};

export const findIn = (address_components, query, val) => {
  for (const attr of address_components) {
    for (const type of attr.types) {
      if (type === query) {
        return val ? attr[val] : attr;
      }
    }
  }
  return null;
};

export const convertToAddress = (places) => {
  const street = findAddress("route", places);
  const city = findAddress("locality", places);
  const state = findAddress("administrative_area_level_1", places);
  const country = findAddress("country", places);
  const postal_code = findAddress("postal_code", places);
  return {
    street,
    city,
    state,
    country,
    zipCode: postal_code,
    address: places.formatted_address,
  };
};
