import moment from 'moment'
import { CACHE_EXPIRY_DAY } from '../constants'

export const getFromCache = (key) => {
  console.log('/*******************************************************/')
  console.log(`reading  data from cache for ${key}`)

  const cacheItemString = localStorage.getItem(key)
  console.log('cacheItemString ', cacheItemString)
  if (cacheItemString) {
    console.log('cacheItem exist')
    const cacheItem = JSON.parse(cacheItemString)
    console.log('cacheItemObject ', cacheItem)
    const { date, data } = cacheItem
    console.log('date ', date)
    console.log('data ', data)
    const isCacheExpired = moment().isAfter(new Date(date))
    console.log('isCacheExpired', isCacheExpired)
    if (isCacheExpired) {
      console.log('cache  has expired')
      return null
    }
    console.log('cache had not expired')
    return data
  }
  console.log('cacheItem does not exist')
  return null
}

export const insertIntoCache = (key, data) => {
  console.log('/*******************************************************/')
  console.log(`storing data into cache for ${key}`)
  console.log('data object', data)

  const expiryDate = moment().add(CACHE_EXPIRY_DAY, 'days')
  const dataToStoreInCache = JSON.stringify({
    key,
    data,
    date: expiryDate,
  })
  console.log('dataToStoreInCache', dataToStoreInCache)
  console.log('expiryDate', expiryDate)
  localStorage.setItem(key, dataToStoreInCache)
  console.log('/*******************************************************/')
}
