import { RoutesArray } from "./routes";
export { RoutesArray };

export const BASE_URL = 'https://api.workabl.co/'

/*export const BASE_URL =
  process.env.REACT_APP_BASE_URL || "http://localhost:9000/";
*/
export const ASSET_URL = BASE_URL + "files/";

export const GOOGLE_MAP_KEY = "AIzaSyAQk21uT0J-MwlxuReHeers3BqXK1hV1nc";
export const ANALYTICS_LABELS = {
  userRegistered: "USER_REG",
  emailConfirmed: "USER_CONFIRMED",
  jobPreviewed: "JOB_VIEW",
  applicationAccept: "APPLICATION_ACCEPTED",
};

export const ANALYTICS_ACTIONS = {
  userRegistered: "User Registered",
  userVerifiedEmail: "User Verified Email",
  userPreviewedJob: "User Previewed Job",
  empAcceptedApplication: "Users application accepted",
};

export const ROLES = {
  applicant: 1,
  employer: 2,
  career_counsellor: 5,
};

export const CACHE_EXPIRY_DAY = 7;

export const GENERIC_ERROR_MESSAGE =
  "Something went wrong while saving the list, please try again or contact support@workabl.co";
