export const createExcludedDates = (dates, duration) => {
  let _dates = [];
  for (let i = 0; i < dates.length; i++) {
    // _dates.push(dates[i].date);
    let d = new Date(dates[i].date);
    d.setDate(d.getDate() - duration);
    _dates.push(d);
    for (let j = 1; j < 2 * duration; j++) {
      d.setDate(d.getDate() + 1);
      _dates.push(new Date(d));
    }
  }

  return _dates;
};
