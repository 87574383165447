import api from "./instance";

const trainingTypes = {
  get: () => api.get("jobs/trtypes"),
  getDurations: (id) => api.get("jobs/trdurations/" + id),
  add: (data) => api.post("ad", data),
  update: (data, id) => api.put(`ad/${id}`, data),
  delete: (id) => api.delete(`ad/${id}`),
};
export default trainingTypes;
