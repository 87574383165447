import React, { Suspense, useEffect, useState } from "react";

import { Switch, Route, useHistory } from "react-router-dom";
import LogRocket from "logrocket";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";

import EmpRoutes, {
  employerRoutes,
  userRoutes,
  commonRoutes,
  employerEmailNotVerifiedRoutes,
  userEmailNotVerifiedRoutes,
} from "./Routes";

import { Header, Footer } from "./components";

import "./App.css";

import {
  loadEmployerProfile,
  loadUserProfile,
} from "./store/actions/user-action";

import "react-toastify/dist/ReactToastify.css";

import { fetchLatestTenNotifications } from "./store/reducers/notification-reducer";
import { setShouldNavigateToLogin } from "./store/reducers/app.reducer";
import FullScreenLoading from "./components/FullScreenLoading";
import user from "./Shared/services/user";
import { fetchCommon } from "./store/reducers/common-reducer";
import { ROLES } from "./constants";

LogRocket.init("trendlines-usa/workable");

function App() {
  const [show, setshow] = useState(true);
  const [resendLoading, setResendLoading] = useState(false);

  const dispatch = useDispatch();
  const { login, hasProfile, isUser, isVerified, empProfile } = useSelector(
    (state) => state.auth
  );
  useEffect(() => {
    dispatch(fetchCommon());
    if (login) {
      if (isUser && hasProfile) {
        dispatch(loadUserProfile);
      }
      dispatch(fetchLatestTenNotifications());
      if (!isUser && hasProfile) {
        dispatch(loadEmployerProfile)
          .then(() => {
            // socket.on(empProfile.id, (data) => {
            //   dispatch(notificationArrived(data));
            // });
          })
          .catch(() => {});
      }
    }
  }, []);

  const handleResendClick = async () => {
    setResendLoading(true);

    try {
      await user.resendVerificationEmail();
    } catch (err) {
      toast("Something went wrong", { type: "error" });
    }
    setResendLoading(false);
  };

  return (
    <div>
      <ToastContainer />

      <Header />
      {show && login && !isVerified && (
        <div
          style={{
            position: "fixed",
            bottom: "0px",
            right: "0px",
            zIndex: "11111",
          }}
          className="text-danger alert alert-warning fade show"
          role="alert"
        >
          <strong className="text-danger" style={{ marginRight: "10px" }}>
            Notice!
          </strong>
          <strong className="text-danger">Your account is not verified!</strong>{" "}
          We sent you a verification email to confirm your account. If you are
          having trouble finding it, please be sure to check your spam folder.{" "}
          <button
            className="btn btn-warning btn-small"
            onClick={() => user.resendEmail()}
          >
            RESEND
          </button>
        </div>
      )}

      <Suspense fallback={<FullScreenLoading />}>
        <RoutesLayout login={login} />
      </Suspense>
      <Footer />
    </div>
  );
}

const RoutesLayout = () => {
  const { hasProfile, isVerified, roleId, login } = useSelector(
    (state) => state.auth
  );
  const [routes, setRoutes] = useState([]);
  const dispatch = useDispatch();
  const { shouldNavigateToLogin } = useSelector((state) => state.app);

  useEffect(() => {
    if (shouldNavigateToLogin) {
      history.push(shouldNavigateToLogin);

      dispatch(setShouldNavigateToLogin(false));
      localStorage.clear();
      dispatch({ type: "LOGOUT" });
      // store.
    }
  }, [shouldNavigateToLogin]);

  const history = useHistory();
  useEffect(() => {
    if (!hasProfile && isVerified) {
      history.push("/create-profile");
    }
    if (roleId == ROLES.applicant) {
      if (isVerified) {
        setRoutes([...userRoutes]);
      } else {
        setRoutes([...userEmailNotVerifiedRoutes]);
      }
    } else {
      if (isVerified) {
        setRoutes([...employerRoutes]);
      } else {
        setRoutes([...employerEmailNotVerifiedRoutes]);
      }
    }
  }, [isVerified, login]);

  if (!routes) {
    return null;
  }

  if (routes.length == 0) {
    return <FullScreenLoading />;
  }

  return (
    <Switch>
      {routes.map((route, key) => {
        return (
          <Route
            exact={route.exact}
            path={route.path}
            key={key}
            component={route.component}
          />
        );
      })}
      {commonRoutes.map((route, key) => {
        return (
          <Route
            exact={route.exact}
            path={route.path}
            key={key}
            component={route.component}
          />
        );
      })}
    </Switch>
  );
};

export default App;
