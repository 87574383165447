import React from 'react'
import { toast } from 'react-toastify'

export const errorToast = (msg) => {
  toast.error(msg)
}

export const successToast = (msg) => {
  toast.success(msg)
}

export const warningToast = (msg) => {
  toast.warn(msg)
}

export const infoToast = (msg) => {
  toast.info(msg)
}
