import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, ListGroupItem, ListGroupItemHeading, ListGroupItemText, ListGroup } from 'reactstrap';

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from 'react-toastify'
import jobApi from '../../../Shared/services/job'
import moment from 'moment';
import axios from 'axios';
import { BASE_URL } from '../../../constants';
import { GENERIC_ERROR_MESSAGE } from '../../../constants';
import instance from 'Shared/services/instance';


export const ViewPrivateNotes = ({toggle, isOpen, applicationId}) => {
    const [privateNotes, setPrivateNotes] = useState([])
    const [showAddModal, setShowAddModal] = useState(false)
    const [activeTab, setActiveTab] = useState("view")
    const [addLoading, setAddLoading] = useState(false)
    const [loading, setLoading]  = useState(true)
    const [notes, setNotes] = useState("")
    useEffect(() => {
        getPrivateNotes()
    },[])
    const getPrivateNotes = async () =>{
        try {
            const res = await instance.get(BASE_URL + "jobapplication-private-notes/" + applicationId)
            if (res.data.success) {
                setPrivateNotes(res.data.data)
            }
            else {
                toast(GENERIC_ERROR_MESSAGE, { type: "error" });
            }
            }
        catch(error) {
            toast(GENERIC_ERROR_MESSAGE, { type: "error" });
        }
        setLoading(false)
    }

    const saveNotes = async () =>{
        if (!notes) {
            toast("Notes cannot be emptu", { type: "error" });
            return
        }
        setAddLoading(true)
        try {
            const res = await instance.post(BASE_URL + "jobapplication-private-notes", {
                notes,
                applicationId
            })
            if (res.data.success) {
                setPrivateNotes( prev =>  [...prev, res.data.data])
                setNotes("")
                toast("Note has been added", {type:"success"})
            }
            else {
                toast(GENERIC_ERROR_MESSAGE, { type: "error" });
            }
            }
        catch(error) {
            toast(GENERIC_ERROR_MESSAGE, { type: "error" });
        }
        setAddLoading(false)
    }

    

    const tabStyle = {flex:1,textAlign:"center",paddingBottom:0, marginBottom:0, cursor:"pointer" }
    const tabStyleActive = {...tabStyle,borderBottom: "4px solid #00b074" }
    return (
    <div>
   
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>Notes</ModalHeader>
        
        <ModalBody>
            
            <div style={{display:"flex", borderBotom:"2px solid #ddd", marginBottom:"1rem"}}>
                <p onClick={()=>setActiveTab("view")} style={activeTab == "view" ? tabStyleActive:tabStyle}>View Notes</p>
                <p onClick={()=>setActiveTab("add")} style={activeTab == "add" ? tabStyleActive:tabStyle}>Add Notes</p>
            </div>
            {activeTab == "view" && (
                <>
                {loading && <div style={{display:"flex",  justifyContent:"center"}}>
                    <ClipLoader
                  color={"#50bfa5"}
                  loading={true}
                  cssOverride={{ maringLeft: "1rem" }}
                  size={30}
                />
                    </div>}
                  <ListGroup>
                  {privateNotes.length == 0 && (
                      <ListGroupItemText>
                          No Notes found
                      </ListGroupItemText>
                  )}
              {privateNotes.map((item, index) => {
                 return (
                 <>
                 <ListGroupItemText style={{fontSize:"1rem", marginLeft:"1rem"}}>
                    <div style={{display:"flex"}}>
                         <p style={{flex:1}}><b>{index + 1}</b> - {item.notes}</p>
                      <span style={{fontSize:"0.8rem", marginLeft:"1rem"}}>{moment(item.createdAt).format("MM-DD-YYYY hh:mm")}</span>
                    </div>
                    
                  </ListGroupItemText>
                 </>
                 ) 
              })}
            </ListGroup>
            </>
                
                )
            }
            {activeTab == "add" && (
                <>
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Notes <span style={{fontSize:"0.7rem"}}>(Required *)</span></label>
                        <textarea  placeholder="Enter Notes"   value={notes} onChange={(e) => setNotes(e.target.value)}   rows={5} type="text" className="form-control"  />
                    </div>
                    <div style={{display:"flex", justifyContent:"center"}}>
                    <button
              type="button"
              onClick={() => saveNotes()}
              
              className="btn btn-post btn-listening mr-4 mb-4"
            >
              {addLoading ? (
                <ClipLoader
                  color={"#50bfa5"}
                  loading={true}
                  cssOverride={{ maringLeft: "1rem" }}
                  size={30}
                />
              ) : (
                "Save Private Note"
              )}
            </button>
            </div>
                </>
            )}
      
  
 


         </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

