import React, { useEffect, useState } from "react";
import jobApi from "../../../Shared/services/job";
import Loading from "../../../components/Loading";
import JobList from "pages/users/JobSearch/JobList/JobList";
import axios from "axios";
import { BASE_URL } from "../../../constants";

import apiInstance from "../../../Shared/services/instance";
import { Link } from "react-router-dom";
import { formatToMMDDYY } from "Shared/utils";
import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
  Tooltip,
} from "reactstrap";
import DataTable from "react-data-table-component";
import moment from "moment";
import { errorToast, warningToast } from "components/Toast";
import tagsApi from "Shared/services/tags";
import { setTag } from "@sentry/react";
import { Chat } from "../chat";
import messageApi from "Shared/services/message";
import principalCounsellorNotesApi from "Shared/services/principalCounsellorNotes";

export default function ViewChildApplicants() {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFIlteredUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tagLoading, setTagLoading] = useState(true);
  const [isFilteredByTag, setIsFilteredByTag] = useState(false);

  const [tags, setTags] = useState([]);
  const [error, setError] = useState(false);
  useEffect(() => {
    getUsers("");
    getAllTags();
  }, []);
  const getUsers = async () => {
    try {
      const { data } = await apiInstance.get("user/get-child-users");
      setUsers(data.data);

      setError(false);
    } catch (err) {
      setError("Something went wrong, please try again");
    }
    setLoading(false);
  };

  const getAllTags = async () => {
    setTagLoading(true);
    try {
      const response = await tagsApi.getTags();
      console.log("data", response);
      setTags(response.data);
    } catch (error) {
      console.log("error", error);
      setError("Something went wrong, please try again");
    }
    setTagLoading(false);
  };

  return (
    <div>
      {loading ? (
        <Loading size={50} color="#00b074" />
      ) : (
        <div style={{ borderRadius: "12px" }}>
          {error && (
            <div
              style={{
                background: "red",
                padding: "1rem",
                borderRadius: "12px",
                margin: "0.5rem",
                color: "white",
              }}
            >
              {error}
            </div>
          )}

          <Filter
            setFIlteredUsers={setFIlteredUsers}
            isFilteredByTag={isFilteredByTag}
            setIsFilteredByTag={setIsFilteredByTag}
            tags={tags}
            setUsers={setUsers}
            users={users}
          />
          <div className="d-flex align-items-center justify-content-between mb-6">
            <h5 className="font-size-4 font-weight-normal text-gray">
              <span className="heading-default-color mr-1">
                {users.length == 0 && (
                  <p style={{ padding: "1rem", textAlign: "center" }}>
                    No Users found.
                  </p>
                )}
              </span>
            </h5>
          </div>

          <div className="row">
            {(isFilteredByTag ? filteredUsers : users).map((item, index) => {
              return (
                <div
                  key={index}
                  className="col-6"
                  style={{ marginBottom: "1rem" }}
                >
                  <div className="card-display">
                    <header>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <h2>{item.name}</h2>
                        <div>
                          <PrivateNotesCounsellor user={item} />
                          <Message user={item} />
                          <Tag setUsers={setUsers} user={item} />
                          <Resume link={item?.profile?.resume} />
                          <UnLink
                            userId={item.id}
                            updateUsers={() => {
                              setUsers((prev) =>
                                prev.filter((u) => u.id != item.id)
                              );
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className={
                          "labels d-flex align-items-start justify-content-between wrapped"
                        }
                      >
                        <p>
                          Type &ndash;{" "}
                          {item?.profile?.empStatus
                            ? item?.profile?.empStatus + " at"
                            : ""}{" "}
                          {item.organization.name}
                        </p>

                        <div className="status badge-info">
                          Caseload since {formatToMMDDYY(item.createdAt)}
                        </div>
                      </div>
                    </header>
                    <div
                      className={
                        "labels d-flex align-items-start justify-content-between wrapped"
                      }
                    >
                      <div className="item">
                        <h5>Applications</h5>
                        <p>
                          {item.applications.length > 0 && (
                            <Link
                              to={{
                                pathname:
                                  "/manage-application-by-counsellor/" +
                                  item.id,
                              }}
                            >
                              Show ({item.applications.length})
                            </Link>
                          )}
                          {item.applications.length === 0 && (
                            <span>No Application</span>
                          )}
                        </p>
                      </div>
                      <div>
                        <div className="status badge-info">
                          <p
                            style={{
                              color: "white",
                              paddingBottom: 0,
                              marginBottom: 0,
                            }}
                          >
                            Tag - {item.tags?.tagName || "Un Tagged"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}

const Message = ({ user }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  const getAllTags = async () => {
    setLoading(true);
    /* try {
      const response = await tagsApi.getTags();
      console.log("data", response);
      setTags(response.data);
    } catch (error) {
      console.log("error", error);
      setError("Something went wrong, please try again");
    } */
    setLoading(false);
  };

  const save = async () => {
    /*  if (!selectedTag) {
      setError("Please select a tag before saving");
      return;
    }
    setSaveLoading(true);
    try {
      const response = await tagsApi.updateUserTag(user.id, selectedTag);
      updateUserTag(tags.filter((item) => item.id == selectedTag)[0]);
    } catch (error) {
      console.log("error", error);
      setError("Something went wrong, please try again");
    } */
    setSaveLoading(false);
  };
  return (
    <>
      <i
        onClick={() => setIsOpen(true)}
        id="TagTooltip"
        style={{ marginRight: "0.5rem", cursor:"pointer" }}
        className="fa fa-envelope"
      ></i>

      <Tooltip isOpen={tooltipOpen} target="TagTooltip" toggle={toggle}>
        Click to view messages
      </Tooltip>
      {isOpen && (
        <Modal isOpen={isOpen} toggle={() => setIsOpen(false)}>
          <ModalHeader toggle={() => setIsOpen(false)}>Messages </ModalHeader>
          <ModalBody>
            {error && (
              <div
                style={{
                  background: "red",
                  padding: "1rem",
                  borderRadius: "12px",
                  margin: "0.5rem",
                  color: "white",
                }}
              >
                {error}
              </div>
            )}
            {loading ? (
              <Loading size={30} />
            ) : (
              <div>
                <Chat toUserId={user.id} />
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => setIsOpen(false)}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};

const Tag = ({ user, setUsers }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [tags, setTags] = useState([]);
  const [tagName, setTagName] = useState("");
  const [addTagLoading, setAddTagLoading] = useState(false);
  const [error, setError] = useState(false);
  const [selectedTag, setSelectedTag] = useState("");
  const [saveLoading, setSaveLoading] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);
  useEffect(() => {
    getAllTags();

    if (user.tags) {
      setSelectedTag(user?.tags?.id);
    }
  }, [user.tags]);
  const getAllTags = async () => {
    setLoading(true);
    try {
      const response = await tagsApi.getTags();
     
      setTags(response.data);
    } catch (error) {
      console.log("error", error);
      setError("Something went wrong, please try again");
    }
    setLoading(false);
  };
  const addTag = async () => {
    if (!tagName) {
      setError("Please enter tag name");
      return;
    }
    setAddTagLoading(true);
    try {
      const response = await tagsApi.createTag({ tagName });
      console.log("created", response);
      setTags((prev) => [response.data.data, ...prev]);
      //setTag(response.data);
    } catch (error) {
      console.log("error", error);
      setError("Something went wrong, please try again");
    }
    setAddTagLoading(false);
  };
  const save = async () => {
    if (!selectedTag) {
      setError("Please select a tag before saving");
      return;
    }
    setSaveLoading(true);
    try {
      const response = await tagsApi.updateUserTag(user.id, selectedTag);
      setUsers((prev) =>
        prev.map((item) =>
          item.id == user.id
            ? {
                ...item,
                tags: item.tags ? { ...item.tags, id: selectedTag } : null,
              }
            : item
        )
      );
    } catch (error) {
      console.log("error", error);
      setError("Something went wrong, please try again");
    }
    setSaveLoading(false);
  };
  return (
    <>
      <i
        onClick={() => setIsOpen(true)}
        id="MessageTooltip"
        style={{ marginRight: "0.5rem",cursor:"pointer" }}
        className="fa fa-tags"
      ></i>
      <Tooltip isOpen={tooltipOpen} target="MessageTooltip" toggle={toggle}>
        Click this icon to manage tag of this user
      </Tooltip>
      {isOpen && (
        <Modal isOpen={isOpen} toggle={() => setIsOpen(false)}>
          <ModalHeader toggle={() => setIsOpen(false)}>Tags </ModalHeader>
          <ModalBody>
            {error && (
              <div
                style={{
                  background: "red",
                  padding: "1rem",
                  borderRadius: "12px",
                  margin: "0.5rem",
                  color: "white",
                }}
              >
                {error}
              </div>
            )}
            {loading ? (
              <Loading size={30} />
            ) : (
              <div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Input
                    value={tagName}
                    onChange={(e) => setTagName(e.target.value)}
                    placeholder="Enter tag name"
                  />
                  <Button onClick={addTag} style={{ marginLeft: "0.5rem" }}>
                    {addTagLoading ? <Loading size={20} /> : "Add"}
                  </Button>
                </div>

                <FormGroup style={{ marginTop: "2rem" }}>
                  <Label for="exampleSelect">Current User Selected Tag</Label>
                  {tags.length > 0 ? (
                    <Input
                      value={selectedTag}
                      onChange={(e) => setSelectedTag(e.target.value)}
                      id="exampleSelect"
                      name="select"
                      type="select"
                    >
                      <option value=""></option>
                      {tags.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.tagName}
                        </option>
                      ))}
                    </Input>
                  ) : (
                    <p>
                      You don't have any tags created, please create a tag to
                      add it to user
                    </p>
                  )}
                </FormGroup>
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={save}>
              {saveLoading ? <Loading size={20} /> : "Save"}
            </Button>

            <Button color="secondary" onClick={() => setIsOpen(false)}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};

const UnLink = ({ userId, updateUsers }) => {
  const [loading, setLoading] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  const unlinkUsers = async () => {
    setLoading(true);
    try {
      const { data } = await apiInstance.post("user/unlink-users/" + userId);
      console.log("data", data);
      if (data.success) {
        updateUsers(userId);
      } else {
        errorToast(data.message || "Something went wrong, please try again");
      }
    } catch (err) {
      errorToast("Something went wrong, please try again");
    }
    setLoading(false);
  };
  if (loading) {
    return <Loading size={20} />;
  }
  return (
    <>
      <i
        id="UnlinkTooltip"
        onClick={unlinkUsers}
        className="fa fa-unlink"
        style={{ cursor: "pointer", marginLeft: "0.5rem" }}
      ></i>
      <Tooltip isOpen={tooltipOpen} target="UnlinkTooltip" toggle={toggle}>
        Clicking this icon will unlink the user from your profile, i.e you will
        no longer be able to see any details about this user. This operation
        cannot be reversed
      </Tooltip>
    </>
  );
};

UnLink.displayName = "UnLink";

const Resume = ({ link }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  const open = () => {
    if (!link) {
      warningToast("User has not uploaded resume");
    } else {
      window.open(BASE_URL + link, "_blank");
    }
  };
  return (
    <>
      <a id="ResumeLinkTooltip" style={{ cursor: "pointer" }} onClick={open}>
        <i className="fa fa-file-pdf"></i>
      </a>
      <Tooltip isOpen={tooltipOpen} target="ResumeLinkTooltip" toggle={toggle}>
        Clicking this icon will download the resume of this applicant on your
        computer
      </Tooltip>
    </>
  );
};

const PrivateNotesCounsellor = ({ user }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [notes, setNotes] = useState([]);
  const [noteInput, setNoteInput] = useState("");
  const [addNoteLoading, setAddNoteLoading] = useState(false);
  const [error, setError] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  useEffect(() => {
    getALlPrivateNotesCounsellor();
  }, []);

  const getALlPrivateNotesCounsellor = async () => {
    setLoading(true);
    try {
      const response = await principalCounsellorNotesApi.getAllNotes(user.id);
      console.log("notes", response.data.data);
      setNotes(response.data.data);
      console.log();
    } catch (error) {
      setError("Something went wrong, please try again");
    }
    setLoading(false);
  };
  const savePrivateNoteCounsellor = async () => {
    if (!noteInput) {
      setError("Please enter note");
      return;
    }
    setError(false);
    setAddNoteLoading(true);
    try {
      const response = await principalCounsellorNotesApi.createNotes({
        notes: noteInput,
        userId: user.id,
      });
      console.log("created", response);
      setNotes((prev) => [response.data.data, ...prev]);
      console.log(response.data.data);
      //setTag(response.data);
      console.log("notes", notes);
    } catch (error) {
      console.log("error", error);
      setError("Something went wrong, please try again");
    }
    setAddNoteLoading(false);
  };

  return (
    <>
      <a
        id="PrivateNoteCounsellorTooltip"
        style={{ cursor: "pointer", marginRight: "0.5rem" }}
        onClick={() => setIsOpen(true)}
      >
        <i className="fa fa-sticky-note"></i>
      </a>
      <Tooltip
        isOpen={tooltipOpen}
        target="PrivateNoteCounsellorTooltip"
        toggle={toggle}
      >
        Add private note to applicant(Only visisble to counsellor) computer
      </Tooltip>
      {isOpen && (
        <Modal isOpen={isOpen} toggle={() => setIsOpen(false)}>
          <ModalHeader toggle={() => setIsOpen(false)}>Tags </ModalHeader>
          <ModalBody>
            {error && (
              <div
                style={{
                  background: "red",
                  padding: "1rem",
                  borderRadius: "12px",
                  margin: "0.5rem",
                  color: "white",
                }}
              >
                {error}
              </div>
            )}
            {loading ? (
              <Loading size={30} />
            ) : (
              <div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Input
                    value={noteInput}
                    onChange={(e) => setNoteInput(e.target.value)}
                    placeholder="Enter note text"
                  />
                  <Button
                    onClick={savePrivateNoteCounsellor}
                    style={{ marginLeft: "0.5rem" }}
                  >
                    {addNoteLoading ? <Loading size={20} /> : "Add"}
                  </Button>
                </div>
                <div style={{ marginTop: "1rem" }}>
                  {notes.map((item) => (
                    <p key={item.id}>{item.notes}</p>
                  ))}

                  {notes.length == 0 && (
                    <p>You don't have any Private notes created</p>
                  )}
                </div>
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => setIsOpen(false)}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};

const Filter = ({
  setUsers,
  users,
  tags,
  isFilteredByTag,
  setIsFilteredByTag,
  setFIlteredUsers,
}) => {
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortBy, setSortBy] = useState("name");
  const [selectedTag, setSelectedTag] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState([]);

  useEffect(() => {
    if (tags.length > 0) {
      setSelectedTag(tags[1].id);
    }
  }, []);
  const [saveMessageLoading, setSaveMessageLoading] = useState(false);
  const [error, setError] = useState(false);

  const sendMessage = async () => {
    if (!message) {
      setError("Please enter message");
      return;
    }
    setError(false);
    setSaveMessageLoading(true);
    try {
      const response = await tagsApi.sendGroupMessage(selectedTag, message);
      console.log("created", response);
      setIsOpen(false);
      setMessage("");

      //setTag(response.data);
    } catch (error) {
      console.log("error", error);
      setError("Something went wrong, please try again");
    }
    setSaveMessageLoading(false);
  };
  const onSortByChange = (e) => {
    const value = e.target.value;
    console.log("chage", value);
    setSortBy(value);
    sort(value, sortOrder);
  };

  const onOrderByChange = (e) => {
    const value = e.target.value;
    setSortOrder(value);
    sort(sortBy, value);
  };

  const sort = (propertyToSortBy, propertyToOrderBy) => {
    console.log(propertyToOrderBy, propertyToSortBy);
    users = users.map((item) => ({
      ...item,
      numberofapplications: item.applications.length,
    }));

    users.sort((a, b) => {
      if (propertyToOrderBy == "asc") {
        return a[propertyToSortBy] > b[propertyToSortBy]
          ? 1
          : b[propertyToSortBy] > a[propertyToSortBy]
          ? -1
          : 0;
      } else {
        return b[propertyToSortBy] > a[propertyToSortBy]
          ? 1
          : a[propertyToSortBy] > b[propertyToSortBy]
          ? -1
          : 0;
      }
    });

    console.log("uss", users);
    setUsers((prev) => [...users]);
  };
  const filterByTag = (e) => {
    if (!e.target.value) {
      setIsFilteredByTag(false);
    } else {
      setIsFilteredByTag(true);
      setFIlteredUsers(users.filter((item) => item.tags?.id == e.target.value));
    }
  };

  return (
    <div style={{ display: "flex", justifyContent: "flex-end" }}>
      <FormGroup onChange={onSortByChange} style={{ marginRight: "1rem" }}>
        <Label for="exampleSelect">Filter By Tag</Label>
        <Input
          onChange={filterByTag}
          id="exampleSelect"
          name="select"
          type="select"
        >
          <option value=""></option>
          {tags.map((item) => (
            <option key={item.id} value={item.id}>
              {item.tagName}
            </option>
          ))}
        </Input>
      </FormGroup>
      <FormGroup onChange={onSortByChange} style={{ marginRight: "1rem" }}>
        <Label for="exampleSelect">Sort By</Label>
        <Input id="exampleSelect" name="select" type="select">
          <option value="name">Name</option>
          <option value="email">Email</option>
          <option value="createdAt">Created At</option>
          <option value="numberofapplications">Number Of Application</option>
        </Input>
      </FormGroup>
      <FormGroup onChange={onOrderByChange}>
        <Label for="exampleSelect">Sort Order</Label>
        <Input id="exampleSelect" name="select" type="select">
          <option value={"asc"}>Asc</option>
          <option value={"dsc"}>Desc</option>
        </Input>
      </FormGroup>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-end",
        }}
      >
        <Button
          onClick={() => setIsOpen(true)}
          style={{
            marginBottom: "1.3rem",
            height: "2.8rem",
            marginLeft: "1rem",
            background: "#aaa",
          }}
        >
          Group Message{" "}
        </Button>
      </div>
      <Modal isOpen={isOpen} toggle={() => setIsOpen(false)}>
        <ModalHeader toggle={() => setIsOpen(false)}>Tags </ModalHeader>
        <ModalBody>
          {error && (
            <div
              style={{
                background: "red",
                padding: "1rem",
                borderRadius: "12px",
                margin: "0.5rem",
                color: "white",
              }}
            >
              {error}
            </div>
          )}

        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={sendMessage}>
            {saveMessageLoading ? <Loading size={20} /> : "Save"}
          </Button>
          <Button color="secondary" onClick={() => setIsOpen(false)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
