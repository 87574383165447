import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { BASE_URL } from "../../../constants/index";
import applicantApi from "../../../Shared/services/applicant";
import { formatDate, formatToMMDDYY } from "../../../Shared/utils";

const Applicant = ({ history, match }) => {
  const [applicant, setApplicant] = useState(null);
  useEffect(() => {
    applicantApi
      .get(match.params.id)
      .then((res) => {
        setApplicant(res.data.data);
      })
      .catch((err) => toast(err.message, { type: "error" }));
  }, []);

  if (!applicant) return null;
  return (
    <div className="cs-container">
      <button
        className="bg-transparent border-0 d-flex align-items-center mb-7"
        onClick={() => history.goBack()}
      >
        <i className="icon icon-small-left bg-white circle-40 mr-5 font-size-7 text-black font-weight-bold shadow-8"></i>
        <span className="text-uppercase font-size-4 font-weight-bold text-gray">
          Back
        </span>
      </button>
      <div className="bg-white shadow-9 rounded-4 p-9">
        <div className="overflow-hidden">
          <div className="row">
            <div className="col-lg-3 col-md-5 text-center">
              <p className="d-inline-block mb-4">
                <img
                  className="circle-54"
                  src="image/l3/png/pro-img.png"
                  alt=""
                />
              </p>
              <h4 className="font-size-6 font-weight-semibold mb-0">
                {applicant.name}
              </h4>
              <p className="mb-6">
                <a
                  target="_blank"
                  rel="noreferrer"
                  className="text-gray font-size-4"
                  href={applicant.profile.resume}
                >
                  View Resume
                </a>
              </p>
              <div className="mb-6">
                <a
                  className="circle-32 btn-secondary mx-auto"
                  target="_blank"
                  rel="noreferrer"
                  href={applicant.profile.linkedIn}
                >
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </div>
              <div className="mb-7">
                <p className="font-size-4 mb-0">About</p>
                <h5 className="font-size-6 font-weight-normal mb-0">
                  {applicant.profile.about}
                </h5>
              </div>
            </div>
            <div className="col-lg-9 col-md-7 pl-md-8 border-left text-center text-md-left">
              <div className="">
                <h5 className="text-black-2 mb-8 font-size-5">
                  Applicant Info
                </h5>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="mb-7">
                      <p className="font-size-4 mb-0">Address</p>
                      <h5 className="font-size-5 font-weight-normal mb-0">
                        {applicant.profile.address}
                      </h5>
                    </div>
                    <div className="mb-7">
                      <p className="font-size-4 mb-0">E-mail</p>
                      <h5 className="font-size-5 font-weight-normal mb-0">
                        <a href={`mailto: ${applicant.email}`}>
                          {applicant.email}
                        </a>
                      </h5>
                    </div>
                    <div className="mb-7">
                      <p className="font-size-4 mb-0">Phone</p>
                      <h5 className="font-size-5 font-weight-normal mb-0">
                        <a href={`tel:${applicant.profile.phone}`}>
                          {" "}
                          {applicant.profile.phone}
                        </a>
                      </h5>
                    </div>
                    <div className="mb-7">
                      <p className="font-size-4 mb-0">Employment Status</p>
                      <h5 className="font-size-5 font-weight-normal mb-0">
                        {applicant.profile.empStatus}
                      </h5>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="mb-7">
                      <p className="font-size-4 mb-0">Annual Income</p>
                      <h5 className="font-size-5 font-weight-normal mb-0">
                        {applicant.profile.anIncome}
                      </h5>
                    </div>
                    <div className="mb-7">
                      <p className="font-size-4 mb-0">Education</p>
                      <h5 className="font-size-5 font-weight-normal mb-0">
                        {applicant.profile.education}
                      </h5>
                    </div>
                    <div className="mb-7">
                      <p className="font-size-4 mb-0">Gender</p>
                      <h5 className="font-size-5 font-weight-normal mb-0">
                        {applicant.profile.gender === 1 ? "Male" : "Female"}
                      </h5>
                    </div>
                    <div className="mb-7">
                      <p className="font-size-4 mb-0">Organization</p>
                      <h5 className="font-size-5 font-weight-normal mb-0">
                        {applicant.organization.name}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Applicant;
