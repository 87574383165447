import jobApi from '../../Shared/services/job';

export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const showMessage = (message) => (dispatch) => {
    dispatch({
        type: SHOW_MESSAGE,
        payload: message
    })
}

export const clearMessage = (dispatch) => {
    dispatch({
        type: CLEAR_MESSAGE,
        
    })
}

