import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ImportData } from "../common/ImportData";
import ViewChildUsers from "../principal-employer/ViewEmployers";
import { BASE_URL } from "../../../constants";
import ViewChildApplicants from "./view-applicant";

export default function PrincipalCounsellor() {
  const sideBarList = ["Upload Applicants", "View Applicants"];
  const [selectedTab, setSelectedTab] = useState(0);
  return (
    <section className="bg-pattern pt-16 pt-lg-25 pb-lg-27">
      <div className="container">
        <div className="row">
          <div className="col-3 ">
            <Sidebar
              onClick={(index) => setSelectedTab(index)}
              sidebarList={sideBarList}
              selectedTab={selectedTab}
            />
          </div>
          <div className="col-9">
            {selectedTab == 0 && (
              <ImportData
                apiPath={"importAll-by-principal-counsellor"}
                excelFileDownloadLink={BASE_URL + "files/applicant.xlsx"}
              />
            )}
            {selectedTab == 1 && <ViewChildApplicants />}
          </div>
        </div>
      </div>
    </section>
  );
}

function Sidebar({ sidebarList = [], onClick, selectedTab }) {
  return (
    <div>
      {sidebarList.map((item, index) => (
        <button
          style={
            selectedTab == index
              ? {
                  background: "white",
                  color: "#00b074",
                  width: "100%",
                  marginBottom: "1rem",
                }
              : { width: "100%", marginBottom: "1rem" }
          }
          key={index}
          onClick={() => onClick(index)}
          className="btn big-btn btn-success logbtn text-uppercase ml-xl-4"
          to="/login"
        >
          {item}
        </button>
      ))}
    </div>
  );
}
