import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ImportData } from "../common/ImportData";
import ViewChildUsers from "./ViewEmployers";
import ViewJobs from "./ViewJobs";
import { BASE_URL } from "../../../constants";

export default function PrincipalEmployer() {
  const sideBarList = [
    "Upload Jobs",
    "Upload Employers",
    "View Employers Jobs",
    "View Employers",
  ];
  const [selectedTab, setSelectedTab] = useState(0);
  return (
    <section className="bg-pattern pt-16 pt-lg-25 pb-lg-27">
      <div className="container">
        <div className="row">
          <div className="col-3 ">
            <Sidebar
              selectedTab={selectedTab}
              onClick={(index) => setSelectedTab(index)}
              sidebarList={sideBarList}
            />
          </div>
          <div className="col-9">
            {selectedTab == 0 && (
              <ImportData
                excelFileDownloadLink={BASE_URL + "files/job.xlsx"}
                apiPath={`importAll-by-principal-employer?offset=${new Date().getTimezoneOffset()}`}
                apiDataToPost={{ type: "job" }}
              />
            )}
            {selectedTab == 1 && (
              <ImportData
                excelFileDownloadLink={BASE_URL + "files/employer.xlsx"}
                apiPath={"importAll-by-principal-employer"}
                apiDataToPost={{ type: "employee" }}
              />
            )}
            {selectedTab == 2 && (
              <ViewJobs
                apiPath={"importAll-by-principal-employer"}
                apiDataToPost={{ type: "employee" }}
              />
            )}
            {selectedTab == 3 && <ViewChildUsers />}
          </div>
        </div>
      </div>
    </section>
  );
}

function Sidebar({ sidebarList = [], onClick, selectedTab }) {
  return (
    <div>
      {sidebarList.map((item, index) => (
        <button
          style={
            selectedTab == index
              ? {
                  background: "white",
                  color: "#00b074",
                  width: "100%",
                  marginBottom: "1rem",
                }
              : { width: "100%", marginBottom: "1rem" }
          }
          key={index}
          onClick={() => onClick(index)}
          className="btn big-btn btn-success logbtn text-uppercase ml-xl-4"
          to="/login"
        >
          {item}
        </button>
      ))}
    </div>
  );
}
