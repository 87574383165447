import React, { useState, useEffect } from "react";
import { Label } from "reactstrap";

import { toast } from "react-toastify";

import api from "../../../Shared/services/instance";
import media from "../../../Shared/services/media";
import Loading from "components/Loading";
import { ErrorMessageHeader } from "./RenderErrorHeader";
import { RenderMessage } from "./RenderError";

export const ImportData = ({
  excelFileDownloadLink,
  note,
  apiPath,
  apiDataToPost,
}) => {
  const [loading, setLoading] = useState(false);
  const [zipfile, setZipfile] = useState(null);
  const [excelFile, setExcelFile] = useState(null);
  const [apiresponse, setApiresponse] = useState(null);
  const [error, setError] = useState(false);

  const onImportClick = async () => {
    if (!excelFile) {
      toast.error("Please upload both excel and zio fuke");
    }
    if (loading) return;
    setLoading(true);
    try {
      let formdata2 = new FormData();
      formdata2.append("image", excelFile);
      let zipfileuploadData = null;

      if (zipfile) {
        let formdata1 = new FormData();
        formdata1.append("image", zipfile);
        zipfileuploadData = await media.upload(formdata1);
      }

      const excelfileuploadData = await media.upload(formdata2);

      console.log("zip file data", zipfileuploadData, excelfileuploadData);
      //toast.success('Question has been updated')
      let d = new Date()
      let offset = -1 * d.getTimezoneOffset()
      const response = await api.post("import-export/" + apiPath + "?offset="+offset, {
        zipFilePath: zipfileuploadData?.data?.image,
        excelFilePath: excelfileuploadData?.data.image,
        ...apiDataToPost,
      });
      if (response.data.error) {
        setError(response.data.message || response.data.error);
      } else {
        setError(false);
        setApiresponse({ ...response.data });
      }
      console.log("response", response.data);
    } catch (error) {
      console.log("Errrr", error);
      setError("Something went wrong, please try again");
    }
    setLoading(false);
  };
  console.log("appa", apiresponse);

  return (
    <div>
      {error && (
        <div
          style={{
            background: "red",
            padding: "1rem",
            borderRadius: "12px",
            margin: "0.5rem",
            color: "white",
          }}
        >
          {error}
        </div>
      )}
      {apiresponse && (
        <div
          style={{
            padding: "1rem",
          }}
        >
          {apiresponse.successItems && (
            <div
              style={{
                background: "orange",
                padding: "1rem",
                borderRadius: "12px",
                margin: "0.5rem",
              }}
            >
              <h5 style={{ color: "white" }}>
                {apiresponse?.successItems?.length} items were succesfully
                imported
              </h5>
            </div>
          )}

          {apiresponse?.duplicateItems &&
            apiresponse?.duplicateItems?.length != 0 && (
              <div
                style={{
                  background: "red",
                  padding: "1rem",
                  borderRadius: "12px",
                  margin: "0.5rem",
                }}
              >
                <ErrorMessageHeader
                  message={
                    "Following items were not imported because they were duplicates"
                  }
                />

                <RenderMessage messages={apiresponse.duplicateItems} />
              </div>
            )}
          {apiresponse?.imageError && apiresponse?.imageError?.length != 0 && (
            <div
              style={{
                background: "red",
                padding: "1rem",
                borderRadius: "12px",
                margin: "0.5rem",
              }}
            >
              <ErrorMessageHeader
                message={
                  "Following items were not imported because of image download error"
                }
              />

              <RenderMessage messages={apiresponse.imageError} />
            </div>
          )}
          {apiresponse?.validationError &&
            apiresponse?.validationError?.length != 0 && (
              <div
                style={{
                  background: "red",
                  padding: "1rem",
                  borderRadius: "12px",
                  margin: "0.5rem",
                }}
              >
                <ErrorMessageHeader
                  message={
                    "Following items were not imported because of validation errors"
                  }
                />

                <RenderMessage messages={apiresponse?.validationError} />
              </div>
            )}

          {apiresponse?.locationError &&
            apiresponse?.locationError?.length != 0 && (
              <div
                style={{
                  background: "red",
                  padding: "1rem",
                  borderRadius: "12px",
                  margin: "0.5rem",
                }}
              >
                <ErrorMessageHeader
                  message={`Following jobs were imported but the latitude and longitude could not be seeded because of the error descried below`}
                />
                <RenderMessage messages={apiresponse?.locationError} />
              </div>
            )}
          {apiresponse?.employeeNotFound &&
            apiresponse?.employeeNotFound?.length != 0 && (
              <div
                style={{
                  background: "red",
                  padding: "1rem",
                  borderRadius: "12px",
                  margin: "0.5rem",
                }}
              >
                <ErrorMessageHeader
                  message={`Following items were not imported because the employer does not exist, please import the eployer first`}
                />
                <RenderMessage messages={apiresponse?.employeeNotFound} />
              </div>
            )}
          {apiresponse?.errorItems && apiresponse?.errorItems?.length != 0 && (
            <div
              style={{
                background: "red",
                padding: "1rem",
                borderRadius: "12px",
                margin: "0.5rem",
              }}
            >
              <ErrorMessageHeader
                message={`Following items were not imported because they were some errors`}
              />
              <RenderMessage messages={apiresponse?.errorItems} />
            </div>
          )}
        </div>
      )}

      <div>
        {note}
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Label for="exampleSelect" style={{ whiteSpace: "nowrap" }}>
            Excel File&nbsp;&nbsp;(
            <a
              style={{ color: "blue" }}
              target="blank"
              href={excelFileDownloadLink}
            >
              &nbsp;Download Sample Excel File&nbsp;
            </a>
            )
          </Label>
          <input
            onChange={(e) => setExcelFile(e.target.files[0])}
            style={{ border: "1px solid #ccc", padding: "0.5rem" }}
            type="file"
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "1rem",
          }}
        >
          <Label for="exampleSelect" style={{ whiteSpace: "nowrap" }}>
            Zip File
          </Label>
          <input
            onChange={(e) => setZipfile(e.target.files[0])}
            style={{ border: "1px solid #ccc", padding: "0.5rem" }}
            type="file"
          />
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <button
            style={{ marginTop: "1rem", width: "8rem" }}
            disabled={!excelFile && !loading}
            onClick={onImportClick}
            type="button"
            className="btn btn-sm btn-success py-2 px-3"
          >
            {" "}
            {loading ? <Loading size={20} /> : "Start Import"}
          </button>
        </div>
      </div>
    </div>
  );
};
