import React from 'react'
import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import surveyApi from '../../Shared/services/survey'
import Loading from '../../components/Loading'

/* const createSurveyForm = (questions) => {
  let form = []
  questions.forEach((itm) => {
    form.push({
      srqId: itm.id,
      answer: itm.answer,
    })
  })
  return form
}
const validateForm = (questions) => {
  let ok = true
  questions.forEach((q) => {
    if (q.answer === null) {
      ok = false
    }
  })

  return ok
} */

const badrequestimage =
  'https://www.pngitem.com/pimgs/m/79-792061_400-bad-request-cartoon-hd-png-download.png'
const Survey = ({ location, match, history }) => {
  const [questions, setQuestions] = useState([])
  const [form, setForm] = useState({
    isSubmitted: false,
    isInvalid: null,
    isComplete: false,
  })
  const [error, setError] = useState(false)
  const [answers, setAnswers] = useState([])
  const [initialLoading, setInitialLoading] = useState(true)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    surveyApi
      .getQuestion(Number(match.params.ID))
      .then(({ data }) => {
        setInitialLoading(false)
        if (data.success) {
          const _answers = data.data.map((question) => ({
            questionId: question.id,
            answer: '',
          }))
          setError(false)
          setAnswers([..._answers])
          setQuestions(data.data)
        } else {
          setError(data.message)
        }
      })
      .catch((err) => {
        setError('Something went wrong please try again later')
        setInitialLoading(false)
        console.log(err)
      })
  }, [])

  const handleSubmit = async (event) => {
    setLoading(true)

    let isInvalid = false
    answers.map((answerObject) => {
      if (!answerObject.answer) {
        isInvalid = true
      }
    })

    if (isInvalid) {
      setForm({ ...form, isSubmitted: true, isInvalid: true })
    } else {
      setForm({ ...form, isInvalid: false })
      try {
        const { data } = await surveyApi.submitSurvey(answers, match.params.ID)
        setForm({ ...form, isComplete: true })
      } catch (error) {
        toast.error('Something went  wrong, please try again ')
        // setError(true)
      }
    }
    setLoading(false)
  }

  /*   const handleQuestionsChange = (value, questionID, index) => {
    questions[index].answer = value
    setform({ ...form, isInvalid: false })
    setquestions([...questions])
  } */
  return (
    <div style={{ height: '70vh' }}>
      {' '}
      {!form.isComplete && (
        <div
          className="cs-container"
          style={{ marginTop: '130px', marginBottom: '50px' }}
        >
          {initialLoading && <p className="text-center"> Loading ...</p>}

          {!initialLoading && (
            <div className="post-job-body bg-athens pt-7 pb-7 rounded">
              <div className="post-job-header">
                <h2 className="circular-normal font-size-8 font-weight-semibold mb-0">
                  Survey
                </h2>
                {!error && (
                  <p className="font-size-4  pt-lg-0">
                    Please help us by answering the questions below
                  </p>
                )}
                {form.isSubmitted && form.isInvalid && (
                  <p className="text-danger">
                    Please attempt all the questions.
                  </p>
                )}
              </div>
              {error ? (
                <p
                  style={{
                    color: 'white',
                    background: 'orangered',
                    padding: '1rem',
                    textAlign: 'center',
                  }}
                >
                  {error}
                </p>
              ) : (
                <form style={{ marginBottom: '1rem' }}>
                  <div className="questionaire-list">
                    <ol className="list-unstyled">
                      {questions.map((q, qindex) => (
                        <li key={qindex}>
                          <div className="questionaire-list-description">
                            <p
                              className={
                                form.isSubmitted && answers[qindex] === ''
                                  ? 'text-danger'
                                  : ''
                              }
                            >
                              {q.question}
                            </p>
                            <DisplayOptions
                              answers={answers}
                              question={q}
                              setAnswers={setAnswers}
                              qIndex={qindex}
                            />
                          </div>
                        </li>
                      ))}
                    </ol>
                  </div>
                  <div className="d-flex justify-content-center">
                    <button
                      onClick={handleSubmit}
                      type="button"
                      className="btn btn-rgs btn-next  text-uppercase"
                    >
                      {loading ? <Loading size={30} color="#fff" /> : 'Submit'}
                    </button>
                  </div>
                </form>
              )}
            </div>
          )}
        </div>
      )}
      {form.isComplete && <MessageComponent />}
    </div>
  )
}

export default Survey

const imageThanks =
  'https://www.thebalancecareers.com/thmb/r_Sbkh07g5_SAqPH_5qliD0vG-U=/300x200/filters:no_upscale():max_bytes(150000):strip_icc():saturation(0.2):brightness(10):contrast(5)/shutterstock_474299809-5a3843019e942700373ca46b.png'
export const MessageComponent = () => {
  return (
    <div className="d-flex" style={{ marginTop: '100px' }}>
      <div className="m-auto">
        <img className="" src={imageThanks} />
        <p>Thanks, For taking the survey!.</p>
      </div>
    </div>
  )
}

const DisplayOptions = ({ question, qIndex, answers, setAnswers }) => {
  const { questionType } = question
  const handleChange = (event) => {
    answers[qIndex].answer = event.target.value
    setAnswers([...answers])
  }
  console.log('answers', answers)
  if (questionType === 'date') {
    return (
      <div className="d-flex">
        <input
          style={{ width: '15rem' }}
          className="form-control"
          placeholder="Your answer"
          type="date"
          value={answers[qIndex].answer}
          onChange={handleChange}
        />
      </div>
    )
  } else if (questionType === 'boolean') {
    return (
      <div>
        <div
          className="d-flex align-item-center"
          style={{ alignItems: 'center' }}
        >
          <label style={{ marginRight: '1rem' }} htmlFor="male">
            Yes
          </label>
          <input
            type="radio"
            style={{ marginBottom: '1rem' }}
            name={question.question}
            value="Yes"
            checked={answers[qIndex].answer === 'Yes'}
            onChange={handleChange}
          />
          <label
            style={{ marginLeft: '2rem', marginRight: '1rem' }}
            htmlFor="female"
          >
            No
          </label>
          <input
            style={{ marginBottom: '1rem' }}
            type="radio"
            onChange={handleChange}
            name={question.question}
            value="No"
            checked={answers[qIndex].answer === 'No'}
          />
        </div>
      </div>
    )
  } else {
    return (
      <div className="d-flex">
        <input
          style={{ width: '15rem' }}
          className="form-control"
          placeholder="Your answer"
          type="text"
          value={answers[qIndex].answer}
          onChange={handleChange}
        />
      </div>
    )
  }
}
