import { errorToast } from '../components/Toast'

export const checkPasswordStrength = (password) => {
  if (hasNumber(password) && hasMixed(password) && password.length >= 7) {
    return true
  }
  return false
}

const hasNumber = (value) => {
  return new RegExp(/[0-9]/).test(value)
}
const hasMixed = (value) => {
  return new RegExp(/[a-z]/).test(value) && new RegExp(/[A-Z]/).test(value)
}

export const handleError = (error) => {
  const message = getMessageFromAxiosError(error)
  errorToast(message)
}

export const getMessageFromAxiosError = (error) => {
  let message = ''
  if (typeof error?.response?.data === 'object') {
    message = error?.response?.data.message
  } else if (error?.response?.data) {
    message = error?.response?.data
  } else if (error?.message) {
    message = error.message
  } else {
    message = 'Something went wrong, please try again after sometime'
  }
  return message
}
