import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import common from '../../Shared/services/common'
import notification from '../../Shared/services/notification'
import { getFromCache, insertIntoCache } from '../../utils'

export const fetchCommon = createAsyncThunk('common', async () => {
  const dataFromCache = getFromCache('common')

  if (dataFromCache) {
    return dataFromCache
  } else {
    const response = await common.get()
    console.log('dtwdfedfewdfwefa', response.data)
    insertIntoCache('common', response.data)
    return response.data
  }
})

export const commonReducer = createSlice({
  name: 'counter',
  initialState: {
    educations: [],
    industeries: [],
    menu: [],
    error: false,
    loading: true,
  },
  reducers: {},
  extraReducers: {
    [fetchCommon.fulfilled]: (state, action) => {
      const { industry, menu, qualification } = action.payload
      state.error = false
      state.loading = false
      state.menu = menu
      state.educations = qualification
      state.industeries = industry
    },
    [fetchCommon.pending]: (state, action) => {
      state.loading = true
    },
    [fetchCommon.rejected]: (state, action) => {
      state.loading = false
      state.error = 'Something went wrong'
    },
  },
})

// Action creators are generated for each case reducer function
/* export const { setUnseenNotificationCount } = notificationSlice.action */
export default commonReducer.reducer
