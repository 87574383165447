import React from 'react'
import { Spinner } from 'reactstrap'

export default function FullScreenLoading() {
  return (
    <div
      style={{
        minHeight: '75vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Spinner
        color="#00b074"
        style={{ color: '#00b074', width: '50px', height: '50px' }}
        size="xl"
      />
    </div>
  )
}
