import { id } from "date-fns/locale";
import { getFromCache, insertIntoCache } from "../../utils";
import api from "./instance";

const user = {
  register: (name, email, password, organizationId, roleId, profile) => {
    let d = new Date();
    let offset = -1 * d.getTimezoneOffset();
    return api.post("user/signup?of=" + offset, {
      name,
      email,
      password,
      organizationId,
      roleId,
      profile,
    });
  },
  empRegister: (name, email, password, roleId) => {
    let d = new Date();
    let offset = -1 * d.getTimezoneOffset();
    return api.post("user/emp/signup?of=" + offset, {
      name,
      email,
      password,
      roleId,
    });
  },
  sendDeniedAccessEmail: (email, accessId) => {
    return api.post("user/deniedAccess", { email, accessId });
  },
  login: (email, password) => api.post("user/login", { email, password }),
  getDurations: (id) => api.get("jobs/trdurations/" + id),
  resendVerificationEmail: () => api.get("user/resend/email"),

  createProfile: (profile) => api.post("userprofile", profile),
  addUserSkill: async (skill, profileId) => {
    const skills = getFromCache("skills");
    const { data } = await api.put("userprofile/add/skill", {
      skill,
      profileId,
    });
    skills.push(data.data);
    insertIntoCache("skills", skills);
    return { data };
  },
  deleteUserSkill: async (id, pfid) => {
    const res = await api.delete(
      "userprofile/delete/skill?id=" + id + "&pfid=" + pfid
    );
    const skills = getFromCache("skills");
    insertIntoCache(
      "skills",
      skills.filter((skill) => skill.id != id)
    );
    return res;
  },
  createEmpProfile: (profile) => api.post("company", profile),
  getProfile: async () => {
    const profile = getFromCache("userprofile");
    const skills = getFromCache("skills");

    if (profile && skills) {
      return {
        data: { data: { ...profile, skills } },
      };
    }
    const { data } = await api.get("userprofile/get");
    console.log("data", data);
    insertIntoCache("userprofile", data.data);
    insertIntoCache("skills", data.data.skills);
    return { data };
  },
  getEmpProfile: async () => {
    const empIndustry = getFromCache("empIndustry");
    const empAddress = getFromCache("empAddress");
    const empProfile = getFromCache("empProfile");
    if (empProfile) {
      return {
        data: {
          data: {
            ...empProfile,
            industry: empIndustry,
            address: empAddress,
          },
        },
      };
    } else {
      const { data } = await api.get("company/get/profile");
      insertIntoCache("empIndustry", data.data.industry);
      insertIntoCache("empAddress", data.data.address);
      insertIntoCache("empProfile", data.data);
      return { data };
    }
  },
  updateEmployerInfo: async (info, industry) => {
    console.log("indusry from updateeee", industry);
    const res = await api.put("company/action/update", info);
    const empProfile = getFromCache("empProfile");
    insertIntoCache("empIndustry", industry);
    insertIntoCache("empProfile", { ...empProfile, ...info });
    return res;
  },
  updateEmployerAddress: async (address) => {
    const res = await api.put("company/update/address", address);
    const empAddress = getFromCache("empAddress");
    insertIntoCache("empAddress", { ...empAddress, ...address });
  },
  updateUserProfile: async (prof) => {
    const res = await api.put("userProfile/update", prof);
    const profileFromCache = getFromCache("userprofile");
    insertIntoCache("userprofile", { ...profileFromCache, ...prof });
    return res;
  },
  VerifyEmail: (hashSearch) => api.get("user/email/verify" + hashSearch),
  forgotPassword: (email) => api.post("user/forgot/password", { email }),
  resetPassword: (password, hash) =>
    api.put("user/reset/password", { hash, password }),
  updateCompanyLogo: async (logo, id) => {
    const res = await api.post("company/update/logo", { logo, id });
    const empProfile = getFromCache("empProfile");
    insertIntoCache("empProfile", { ...empProfile, logo });
    return res;
  },
  resendEmail: async () => {
    api.get("user/resend/email");
  },
  updateUser: (userData) => api.put("user/updateUser", userData),
  sendOtpEmail: (email) => api.put("user/sendOtpEmail", { email }),
};
export default user;
