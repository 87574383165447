export const EmplistCols = [
  { title: 'Title', src: './images/svgs/icons/user-table.svg' },
  { title: 'Date Posted', src: './images/svgs/icons/calendar(1).svg' },
  {
    title: 'Status',
    src: './images/svgs/icons/Icon awesome-check-circle.svg',
  },
  { title: 'Type', src: './images/svgs/icons/jobType.svg' },
  { title: 'Applications', src: './images/svgs/icons/user-table.svg' },
]

export const UserlistCols = [
  { title: 'Company profile', src: './images/svgs/icons/user-table.svg' },
  { title: 'Date applied', src: './images/svgs/icons/calendar(1).svg' },
  {
    title: 'Status',
    src: './images/svgs/icons/Icon awesome-check-circle.svg',
  },
  { title: 'Type', src: './images/svgs/icons/jobType.svg' },
  { title: '', src: '' },
]

export const UserDrawerLinks = [
  { title: 'Start', type: 0 },
  { title: 'Dashboard', link: '/user/dashboard' },
  { title: 'For User', type: 0 },
  { title: 'Manage Applied Jobs', link: '/user/manage-applications' },
  { title: 'Manage Jobs', link: '/user/manage-jobs' },
  { title: 'Account', type: 0 },
  { title: 'Profile', link: '/profile' },
  // { title: "Logout", link: "/logout" },
]

export const EmpDrawerLinks = [
  { title: 'Start', type: 0 },
  { title: 'Dashboard', link: '/employee/dashboard' },
  { title: 'For Employer', type: 0 },
  { title: 'Manage Jobs', link: '/employee/manage-applications' },
  { title: 'Manage Applications', link: '/employee/manage-jobs' },
  { title: 'Post Job', link: '/employee/postJob' },
  { title: 'Account', type: 0 },
  { title: 'Profile', link: '/employee/profile' },
  // { title: "Logout", link: "/logout" },
]
