import api from "./instance";

const messageApi = {
  getAllGroups: () => api.get("message/getAllGroups"),
  createMessage: (body) => api.post("message", body),
  getMessagesByGroupId: (groupId) =>
    api.get("message/getMessagesByGroupId/" + groupId),
  deleteMessage: (mesageId) => api.delete("message/deleteMessage/" + mesageId),
};
export default messageApi;
