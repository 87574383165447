import axios from 'axios'
// import { API_WEBSITE, ACTIVATE_LOADER, DEACTIVATE_LOADER } from "./../config/config";
import { BASE_URL } from '../../constants'
import { toast } from 'react-toastify'
import createAuthRefreshInterceptor from 'axios-auth-refresh'
import { createBrowserHistory } from 'history'
import store from '../../store/store'
import { setShouldNavigateToLogin } from '../../store/reducers/app.reducer'

// const activateLoaderEvent = new CustomEvent(ACTIVATE_LOADER);
// const deActivateLoaderEvent = new CustomEvent(DEACTIVATE_LOADER);

const getAccessToken = () => {
  return localStorage.getItem('token')
}
const getRefreshToken = () => {
  return localStorage.getItem('refreshToken') || ''
}
const instance = axios.create({
  baseURL: `${BASE_URL}`,
})

instance.interceptors.request.use((config) => {
  if (localStorage.getItem('user')) {
    config.headers['x-auth-token'] = getAccessToken()
  }

  if (!(config.method === 'GET' || config.method === 'get')) {
    // document.dispatchEvent(activateLoaderEvent);
  }

  return config
})

const refreshAuthLogic = async (failedRequest) => {
  localStorage.removeItem('token')
  const refreshToken = getRefreshToken()
  if (refreshToken) {
    console.log('refresh ')
    try {
      console.log('Callinga api')
      const { data } = await instance.post('/user/refresh-token', {
        refreshToken: getRefreshToken(),
      })
      console.log('refresh ', data)
      console.log('dataa', data)
      localStorage.setItem('token', data.token)
      localStorage.setItem('refreshToken', data.refreshToken)
      Promise.resolve()
    } catch (error) {
      console.log(
        'not afewfwefwfwefewffffffffffffffffffffffffffffffffffffffffffffffffffffffffffuthorized in refreh stoken'
      )
      //token renew has failed or expired , send user to login page
      //localStorage.clear()
      // store.dispatch({ type: 'LOGOUT' })
      store.dispatch(setShouldNavigateToLogin('/login'))

      return Promise.resolve()
    }
  } else {
    Promise.resolve()
  }
}

createAuthRefreshInterceptor(instance, refreshAuthLogic, {
  pauseInstanceWhileRefreshing: false,
})

// instance.interceptors.response.use(
//     (response) => {
//         // document.dispatchEvent(deActivateLoaderEvent);
//         return response.data;
//     },
//     (error) => {
//         // document.dispatchEvent(deActivateLoaderEvent);
//         if (error.response) {
//             toast(error.response.data.message, {
//                 type: "error",
//             });
//         }

//         return Promise.reject(error);
//     }
// );

export default instance
