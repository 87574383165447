import { createSlice } from '@reduxjs/toolkit'

export const searchSlice = createSlice({
  name: 'counter',
  initialState: {
    jobs: [],
    jobLoading: false,
    jobFilterLoading: false,
    zoomWithRadius: [
      {
        zoom: 7,
        value: 320,
        step: 320,
      },
      {
        zoom: 8,
        value: 160,
        step: 160,
      },
      {
        zoom: 9,
        value: 80,
        step: 80,
      },

      {
        zoom: 10,
        value: 40,
        step: 40,
      },
      {
        zoom: 11,
        value: 20,
        step: 20,
      },
      {
        zoom: 12,
        value: 10,
        step: 10,
      },
      {
        zoom: 13,
        value: 5,
        step: 5,
      },
    ],
    zoom: 11,
    isMapLoaded: false,

    pagination: {
      limit: 10,
      page: 1,
      totalCount: 0,
    },

    query: {
      keyword: '',
      address: '',
      geolocation: { lat: 0, lng: 0 },
      radius: 20,
      actualRadius: 20,
      trainingDuration: { min: -1, max: 1825 },
      trainingType: [],
      hourlyRate: { min: 0, max: 30 },
      dateRange: {
        start: new Date(),
        end: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
      },
      sort: 'hourlyRate',
    },
    currentJob: null, //when mouse is  hovered any job, it is stored in current job
  },
  reducers: {
    setQuery: (state, action) => {
      state.query = { ...state.query, ...action.payload }
    },

    setJobLoading: (state, action) => {
      state.jobLoading = action.payload
    },
    setJobFilterLoading: (state, action) => {
      state.jobFilterLoading = action.payload
    },
    setPagination: (state, action) => {
      state.pagination = { ...state.pagination, ...action.payload }
    },
    setJobs: (state, action) => {
      state.jobs = action.payload
    },
    setZoom: (state, action) => {
      state.zoom = action.payload
    },
    setIsMapLoaded: (state, action) => {
      console.log('sttn ismap loade', action)
      state.isMapLoaded = action.payload
      console.log('Sate', state)
    },
    setZoomWithRadius: (state, action) => {
      state.zoomWithRadius = action.payload
      state.isMapLoaded = true
    },
    setCurrentJob: (state, action) => {
      state.currentJob = action.payload
    },
    clearFilters: (state, action) => {
      console.log('clearin filters')
      state.query.dateRange = {
        start: new Date(),
        end: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
      }
      state.query.trainingDuration = { min: -1, max: 1825 }
      state.query.trainingType = []
      state.query.hourlyRate = { min: 0, max: 30 }
      state.query.sort = 'hourlyRate'
      state.zoom = 11
      state.query.actualRadius = state.zoomWithRadius[4].actualRadius || 20
      state.query.radius = 20
    },
    resetState: (state) => ({
      jobs: [],
      jobLoading: false,
      jobFilterLoading: false,
      zoomWithRadius: [
        {
          zoom: 7,
          value: 320,
          step: 320,
        },
        {
          zoom: 8,
          value: 160,
          step: 160,
        },
        {
          zoom: 9,
          value: 80,
          step: 80,
        },

        {
          zoom: 10,
          value: 40,
          step: 40,
        },
        {
          zoom: 11,
          value: 20,
          step: 20,
        },
        {
          zoom: 12,
          value: 10,
          step: 10,
        },
        {
          zoom: 13,
          value: 5,
          step: 5,
        },
      ],
      zoom: 11,
      isMapLoaded: false,

      pagination: {
        limit: 10,
        page: 1,
        totalCount: 0,
      },

      query: {
        keyword: '',
        address: '',
        geolocation: { lat: 0, lng: 0 },
        radius: 20,
        actualRadius: 20,
        trainingDuration: { min: -1, max: 1825 },
        trainingType: [],
        hourlyRate: { min: 0, max: 30 },
        dateRange: {
          start: new Date(),
          end: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
        },
        sort: 'hourlyRate',
      },
    }),
  },
})

// Action creators are generated for each case reducer function
export const {
  setQuery,
  setJobLoading,
  setJobFilterLoading,
  setPagination,
  setJobs,
  setZoom,
  setIsMapLoaded,
  setZoomWithRadius,
  resetState,
  setCurrentJob,
  clearFilters,
} = searchSlice.actions

export default searchSlice.reducer
