/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from "react";
import "../../dashboard/Dashboard";
import { formatDate, formatToMMDDYY } from "../../../Shared/utils";
import { BASE_URL } from "../../../constants/index";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Popover,
  PopoverHeader,
  PopoverBody,
} from "reactstrap";
import AcceptApplication from "../accept application/acceptApplicat";
import { DashboardListHead } from "../../../components";
import "./style.css";
import jobApi from "../../../Shared/services/job";
import { toast } from "react-toastify";
import { Link, matchPath } from "react-router-dom";
import { data } from "./data";
import { useSelector } from "react-redux";
import moment from "moment";
import Tooltip from "../../../components/Tooltip";
import { HRWorkflow } from "./HRWorkflow";
import { HRActionModal } from "./HRActionModal";
import { ViewNotes } from "./ViewNotesModal";
import { ViewAttachments } from "./ViewAttachmentsModal";
import { ViewPrivateNotes } from "./ViewPrivateNotes";

const ManageJobApplications = ({
  application = {},
  onAccept,
  location,
  history,
  match,
}) => {
  const index = 1;

  // const { jobTitle } = { jobTitle: location ? location.state.jobTitle : "" };
  const [acceptModal, setacceptModal] = useState(false);
  const [selectedID, setselectedID] = useState({ id: -1 });
  const [applications, setapplications] = useState([]);
  const [selectedApplication, setSelectedApplication] = useState({});
  const { empProfile, loading } = useSelector((state) => state.auth);
  const [steps, setSteps] = useState([]);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [showHRWorkflowModal, setShowHRWorkflowModal] = useState(false);
  const [data, setData] = useState({});
  const [dataLoading, setDataLoading] = useState(true);
  const [showHRActionModal, setShowHRActionModal] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [showNotesModal, setShowNotesModal] = useState(false);
  const [hasWorkflow, setHasWorkflow] = useState(false)
  const [showAttachmentModal, setShowAttachmentModal] = useState(false)
  const [showPrivateNotesModal, setShowPrivateNotesModal] = useState(false)
  const toggle = () => setTooltipOpen(!tooltipOpen);

  useEffect(() => {
    getInitialData()
  }, []);

  const getInitialData = async () => {
    try {
      const res = await jobApi.getWorkflow(match.params.jobID);

      const workflowData = res.data.data
      
      if (!workflowData) {
        const applicationsForNoWorkflow = await jobApi.getApplicationsByJob(
          match.params.jobID
        );
        console.log("akdjwdkj", applicationsForNoWorkflow.data.data)
        setapplications(applicationsForNoWorkflow.data.data);
      } else {
        setHasWorkflow(true)
        setSteps(JSON.parse(workflowData.steps));
        setapplications(
          workflowData.job.applications.map((item) => ({ ...item, job: workflowData.job }))
        );
        setData(workflowData)
      }

      setDataLoading(false);

      // setapplications(res.data.data)
    } catch (error) {
      toast("Something went wrong, please try again", { type: "error" }); 
    }
  };

  const acceptApplication = (event, __id) => {
    setselectedID({ ...selectedID, id: __id });
    setTimeout(() => {
      setacceptModal(!acceptModal);
    }, 100);
  };

  const rejectApplication = (id) => {
    // alert(applicationID);
    event.preventDefault();
    jobApi
      .rejectApplication(id)
      .then((res) => {
        console.log(res);
        toast("Application Rejected Successfully", { type: "success" });
      })
      .catch((err) => {
        toast(err.message, { type: "error" });
      });
  };

  const acceptToggle = (id) => {
    setacceptModal(!acceptModal);
  };

  const onAccepted = (data) => {
    for (let i = 0; i < applications.length; i++) {
      if (applications[i].id === selectedID.id) {
        applications[i].status = 1;
      }
    }

    setapplications([...applications]);
  };

  const getCurrentStep = (application) => {
    let currentStep = null;

    steps.forEach((item) => {
      if (item.step == application.currentStepIndex) {
        currentStep = item;
      }
    });
    return (
      currentStep?.name ||
      (application.status == 1 ? "All steps completed" : "------")
    );
  };

  const shouldHRWorkflowButtonBeDisabled = () => {
    let hasAnyApplicantProceededToNextStep = false;
    console.log("app", applications)
    applications.forEach((item) => {
      console.log("ittt", item.status)
      if (item.currentStepIndex == 1001 || item.currentStepIndex > 1 || item.status == 1 || item.status == 2)
        hasAnyApplicantProceededToNextStep = true;
    });
    return hasAnyApplicantProceededToNextStep
  };

const getTableHeader = () => {
  let headers = [
    { title: "Applicant Name", style: { width: "10%" } },
    { title: "Selected Start Date" },
    { title: "Address" },
    { title: "Messages" },
    { title: "Applied On", style: { width: "10%" } },
    { title: "Résumé" },
    { title: "Status" },
   
  ]
  if (hasWorkflow) {
    headers.push( { title: "Current Step", style: { width: "12%" } })
  
  }
  headers.push( { title: "Actions", style: { width: "10%" } })
  return headers
}

  return (
    <div className="pb-14">
      {(loading || dataLoading) && (
        <div className="d-flex">
          <i className="fa fa-spinner"></i>
        </div>
      )}
      {!loading && (
        <>
          <Modal isOpen={acceptModal} toggle={acceptToggle}>
            <ModalHeader>Opportunity Info</ModalHeader>
            <ModalBody>
              <AcceptApplication
                address={empProfile.address}
                onAccepted={onAccepted}
                applicationID={selectedID.id}
                toggle={acceptToggle}
                selectedApplication={selectedApplication}
              ></AcceptApplication>
            </ModalBody>
          </Modal>
          <HRWorkflow
            onSaved={(updatedSteps) => setSteps((prev) => updatedSteps)}
            workflowId={data.id}
            steps={steps}
            isOpen={showHRWorkflowModal}
            toggle={() => setShowHRWorkflowModal(!showHRWorkflowModal)}
          />
          {showNotesModal ? (
            <ViewNotes
              steps={steps}
              notes={selectedApplication.jobApplicationNotes}
              toggle={() => setShowNotesModal(false)}
              isOpen={true}
            />
          ) : null}
           {showAttachmentModal ? (
            <ViewAttachments
              steps={steps}
              attachments={selectedApplication.jobApplicationAttachments}
              toggle={() => setShowAttachmentModal(false)}
              isOpen={true}
            />
          ) : null}
            {showPrivateNotesModal ? (
            <ViewPrivateNotes
             
              applicationId={selectedApplication.id}
              toggle={() => setShowPrivateNotesModal(false)}
              isOpen={true}
            />
          ) : null}
          {showHRActionModal ? (
            <HRActionModal
            addAttachments={addedAttachments => {
              setapplications(prev => {
               return prev.map(item =>{
                  if (item.id == selectedApplication.id) {
                    return {...item, jobApplicationAttachments:[...item.jobApplicationAttachments, ...addedAttachments]
                    }
                  }
                  return item
                })
              })
            }}
              addNotes={(addedNotes) =>
                setapplications((prev) =>
                  prev.map((item) => {
                    if (item.id == selectedApplication.id) {
                      let notesExist = false;
                      const jobApplicationNotes = item.jobApplicationNotes;
                      const jobApplicationNotesUpdated =
                        jobApplicationNotes.map((n) => {
                          if (n.id == addedNotes.id) {
                            notesExist = true;
                            return addedNotes;
                          }
                          return n;
                        });
                      if (!notesExist) {
                        jobApplicationNotesUpdated.push(addedNotes);
                      }
                      return {
                        ...item,
                        jobApplicationNotes: jobApplicationNotesUpdated,
                      };
                    } else {
                      return item;
                    }
                  })
                )
              }
              updateApplication={(data) => {
                setapplications((prev) =>
                  prev.map((item) => {
                    if (item.id == selectedApplication.id) {
                      setSelectedApplication({ ...item, ...data });
                      return {
                        ...item,
                        ...data,
                      };
                    }
                    return item;
                  })
                );
              }}
              toggle={() => setShowHRActionModal(!showHRActionModal)}
              jobApplication={selectedApplication}
              steps={steps}
              isOpen={showHRActionModal}
            />
          ) : null}
        </>
      )}
      <div className="row mb-11 align-items-center">
        <div className="col-lg-6 mb-lg-0 mb-4">
          <h3 className="font-size-6 mb-0">
            Applicant Review Dashboard ({applications.length}){" "}
            <span className={"text-secondary"}></span>{" "}
          </h3>
        </div>
        <div className="col-lg-6">
          {/* <div class="d-flex flex-wrap align-items-center justify-content-lg-end">
            <p class="font-size-4 mb-0 mr-6 py-2">Filter by Job:</p>
            <div class="h-px-48">
              <select
                name="country"
                id="country"
                class="nice-select pl-7 h-100 arrow-3 arrow-3-black min-width-px-273 font-weight-semibold text-black-2"
                style={{ display: "none" }}
              >
                <option value="" data-display="Product Designer">
                  Product Designer
                </option>
                <option value="">Graphics Designer</option>
                <option value="">Web Deverloper</option>
                <option value="">Front End Developer</option>
                <option value="">Backend Developer</option>
              </select>
              <div
                class="nice-select pl-7 h-100 arrow-3 arrow-3-black min-width-px-273 font-weight-semibold text-black-2"
                tabindex="0"
              >
                <span class="current">Product Designer</span>
                <ul class="list">
                  <li
                    data-value=""
                    data-display="Product Designer"
                    class="option selected"
                  >
                    Product Designer
                  </li>
                  <li data-value="" class="option">
                    Graphics Designer
                  </li>
                  <li data-value="" class="option">
                    Web Deverloper
                  </li>
                  <li data-value="" class="option">
                    Front End Developer
                  </li>
                  <li data-value="" class="option">
                    Backend Developer
                  </li>
                </ul>
              </div>
            </div>
          </div>
         */}
        </div>
      </div>
      {hasWorkflow && (

     
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "1rem",
          alignItems: "center",
          position: "relative",
        }}
      >
        <button
          disabled={shouldHRWorkflowButtonBeDisabled()}
          onClick={() => setShowHRWorkflowModal(true)}
          style={{ padding: "1.5rem", fontSize: "1.2rem" }}
          className="btn ml-2 btn-success"
        >
          <span className="">Edit HR Workflow </span>
        </button>
        <a id="TooltipExample">
          {" "}
          <i
            onMouseEnter={() => toggle()}
            onMouseLeave={() => toggle()}
            style={{ marginLeft: "1rem", cursor: "pointer" }}
            className="fa fa-question"
          ></i>
        </a>
        <div
          style={{
            position: "absolute",
            top: "-3rem",
            color: "white",
            background: "black",
            display: tooltipOpen ? "block" : "none",
            padding: "1rem",
            borderRadius: "8px",
          }}
        >
          {shouldHRWorkflowButtonBeDisabled()
            ? "Edit HR Workflow has been disabled since one of your applicants has already been advanced to next steps"
            : "Edit HR Workflow will be disabled if any of your applicants are advanced to next steps"}
        </div>
      </div>
       )}
      <div className="bg-white shadow-8 rounded p-4 p-lg-8">
        <div className="table-responsive data-table">
          <table className="table table-striped">
            <thead className="table-success">
              <DashboardListHead
                columns={getTableHeader()}
              />
            </thead>
            <tbody>
              {applications.map((application, index) => {
                console.log("applcucation", application);
                console.log(
                  "wefweff",
                  moment(application.startDate).format("MM/DD/YY HH:mm a"),
                  application.startDate
                );
                const note = application.acceptedApplication?.notes;
                const { JobAddress } = application.job;
                const jobAddressString = `${JobAddress.line1}, ${JobAddress.city}, ${JobAddress.state}, ${JobAddress.zipcode}`;
                return (
                  // <JobRow job={job} index={index} />
                  <tr key={index} className="border border-color-2">
                    <th scope="row" width={290}>
                      <Link
                        to={`/dashboard/manage/applications/applicant/${application.userId}`}
                      >
                        <h4
                          className="font-size-5 mb-0 font-weight-normal pointer"
                          style={{ color: "#00b074" }}
                        >
                          {application.applicant.name}
                        </h4>
                      </Link>
                    </th>
                    <td>
                      <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
                        <span className="d-xl-none">Meeting Date:</span>{" "}
                        {moment(application.startDate).format(
                          "MM-DD-YYYY hh:mm A"
                        )}{" "}
                        (local time)
                      </h3>
                    </td>
                    <td>
                      <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
                        <span className="d-xl-none">Address:</span>{" "}
                        {jobAddressString}
                      </h3>
                    </td>
                    <td>
                      {application.currentStepIndex == null ? (
                        <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
                          <span className="d-xl-none">Messages:</span>{" "}
                          <Tooltip
                            key={index}
                            text={note ? note.slice(0, 10) + "..." : "No Notes"}
                            toolTipText={note}
                          />
                        </h3>
                      ) : (
                        <p
                          onClick={() => {
                            setSelectedApplication(application);
                            setShowNotesModal(true);
                          }}
                          style={{ color: "blue", cursor: "pointer" }}
                        >
                          Messages
                        </p>
                      )}
                    </td>
                    <td>
                      <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
                        <span className="d-xl-none">Applied At:</span>{" "}
                        {formatDate(application.createdAt)}
                      </h3>
                    </td>
                    <td>
                      <a
                        href={`${BASE_URL}userprofile/resume/${application.id}`}
                        target="_blank"
                        className="font-size-4 font-weight-normal mb-0"
                      >
                        View Resume
                      </a>
                    </td>
                    <td>
                      {application.status === 0 && (
                        <span className="font-size-4 font-weight-normal text-black-2 mb-0">
                          <span className="d-xl-none">Status:</span> Pending
                        </span>
                      )}
                      {application.status === 1 && (
                        <span className="font-size-4 font-weight-normal text-black-2 mb-0">
                          Accepted On {formatToMMDDYY(application.updatedAt)}
                        </span>
                      )}
                      {application.status === 2 && (
                        <span className="font-size-4 font-weight-normal text-black-2 mb-0">
                          Rejected On {formatToMMDDYY(application.updatedAt)}
                        </span>
                      )}
                      {application.status === 4 && (
                        <span className="font-size-4 font-weight-normal text-black-2 mb-0">
                          Expired On {formatToMMDDYY(application.updatedAt)}
                        </span>
                      )}
                      {application.status === 3 && (
                        <span className="font-size-4 font-weight-normal text-black-2 mb-0">
                          Canceled On {formatToMMDDYY(application.updatedAt)}
                        </span>
                      )}
                    </td>

                 {hasWorkflow && (
                   <td>{getCurrentStep(application)}</td>
                 )}   
                    <td width={290}>
                      {application.currentStepIndex ? (
                        <div
                          style={{ display: "flex", justifyContent: "center", flexDirection:"column" }}
                        >
                          <button
                            disabled={application.status != 0}
                            onClick={() => {
                              setSelectedApplication(application);
                              setShowHRActionModal(true);
                            }}
                            style={{marginBottom:"1rem"}}
                            className="btn  btn-success"
                          >
                            <span className="p">HR Actions</span>
                          </button>
                          <button
                            disabled={application.status != 0}
                            onClick={() => {
                              setSelectedApplication(application);
                              setShowAttachmentModal(true)
                            }}
                            className="btn  btn-success"
                          >
                            <span className="p">View Attachments</span>
                          </button>

                          <button
                          style={{marginTop:"1rem"}}
                            disabled={application.status != 0}
                            onClick={() => {
                              setSelectedApplication(application);
                             setShowPrivateNotesModal(true)
                            }}
                            className="btn  btn-success"
                          >
                            <span className="p">View My Notes</span>
                          </button>
                          

                        </div>
                      ) : (
                        <>
                        {application.status == 0 ? (
                          <>

<div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
                          <button
                          style={{marginBottom:"1rem"}}
                            disabled={application.status !== 0}
                            onClick={() => rejectApplication(application.id)}
                            className="btn  btn-danger"
                          >
                            <span className="p">Reject</span>
                          </button>
                          <button
                            disabled={application.status !== 0}
                            onClick={(event) => {
                              setSelectedApplication({ ...application });
                              acceptApplication(event, application.id);
                            }}
                            className="btn ml-2 btn-success"
                          >
                            <span className="">Accept </span>
                          </button>
                          </div>
                          </>
                        ):"No Actions required"}
                       
                        </>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <button
            onClick={() => history.goBack()}
            className="btn btn-outline-info"
          >
            <i className="fa fa-arrow-left" style={{ marginRight: "1em" }}></i>
            Go Back
          </button>
        </div>

        {/*          
         
        <div class="pt-2">
          <nav aria-label="Page navigation example">
            <ul class="pagination pagination-hover-primary rounded-0 ml-n2">
              <li class="page-item rounded-0 flex-all-center">
                <a
                  class="page-link rounded-0 border-0 px-3active"
                  href="#"
                  aria-label="Previous"
                >
                  <i class="fas fa-chevron-left"></i>
                </a>
              </li>
              <li class="page-item">
                <a
                  class="page-link border-0 font-size-4 font-weight-semibold px-3"
                  href="#"
                >
                  1
                </a>
              </li>
              <li class="page-item">
                <a
                  class="page-link border-0 font-size-4 font-weight-semibold px-3"
                  href="#"
                >
                  2
                </a>
              </li>
              <li class="page-item">
                <a
                  class="page-link border-0 font-size-4 font-weight-semibold px-3"
                  href="#"
                >
                  3
                </a>
              </li>
              <li class="page-item disabled">
                <a
                  class="page-link border-0 font-size-4 font-weight-semibold px-3"
                  href="#"
                >
                  ...
                </a>
              </li>
              <li class="page-item ">
                <a
                  class="page-link border-0 font-size-4 font-weight-semibold px-3"
                  href="#"
                >
                  7
                </a>
              </li>
              <li class="page-item rounded-0 flex-all-center">
                <a
                  class="page-link rounded-0 border-0 px-3"
                  href="#"
                  aria-label="Next"
                >
                  <i class="fas fa-chevron-right"></i>
                </a>
              </li>
            </ul>
          </nav>
        </div>
       */}
      </div>
    </div>
  );
};

export default ManageJobApplications;
