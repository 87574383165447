export const data = [
  {
    id: 15,
    userId: 142,
    status: 3,
    resume:
      "files/1612427507980pngtree-labor-day-labour-is-the-honour-housekeeper-service-industry-png-image_3924991.jpg",
    jobId: 4,
    startDate: "2020-11-18T00:00:00.000Z",
    createdAt: "2021-02-04T08:31:49.000Z",
    updatedAt: "2021-02-04T09:32:00.000Z",
    applicant: {
      id: 142,
      name: "Vu Student",
      email: "shah@vu.edu.pk",
      organization: {
        id: 2,
        domain: "@adobe.com",
        name: "Adobe Inc",
        email: "abc@adobe.com",
        phone: "0123843434",
        shortCode: null,
        logo: null,
        createdAt: "2021-02-03T08:40:23.000Z",
        updatedAt: "2021-02-05T13:08:40.000Z",
      },
    },
  },
];
