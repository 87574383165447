import React from "react";
import { useLocation } from "react-router-dom";
import MenuItem from "../menuItem/MenuItem";

import "./drawer.css";

const userMenu = [
  {
    title: "Manage Applications",
    link: "/dashboard/manage/application",
    icon: "icon icon-layout-11",
  },
  { title: "Profile", link: "/profile", icon: "fas fa-user" },
  { title: "Logout", link: "/logout", icon: "fas fa-sign-out-alt" },
];
const empMenu = [
  {
    title: "Manage Jobs",
    link: "/dashboard/manage/jobs",
    icon: "icon icon-layout-11",
  },
  { title: "Profile", link: "/profile", icon: "fas fa-user" },
  // { title: "Logout", link: "/logout", icon: "fas fa-sign-out-alt" },
  {
    title: "Settings",
    link: "/dashboard/settings",
    icon: "fa fa-setting",
  },
];
// eslint-disable-next-line no-unused-vars
const Drawer = ({ isUser = false, menu = [] }) => {
  const { pathname } = useLocation();

  return (
    //  <div style={{"border":"1px solid red"}} class="dashboard-sidebar-wrapper pt-11" id="sidebar">
    <div className="user-left" id="sidebar">
      <ul className="list-unstyled dashboard-layout-sidebar">
        {isUser &&
          userMenu.map((itm, index) => (
            <MenuItem
              key={index}
              onClick={() => {
                localStorage.clear();
                window.location.href = "/";
              }}
              className={pathname == itm.link ? "active" : ""}
              title={itm.title}
              icon={"icon-layout-11"}
              path={itm.link}
            />
          ))}
        {!isUser &&
          empMenu.map((itm, index) => (
            <MenuItem
              key={index}
              onClick={() => {
                localStorage.clear();
                window.location.href = "/";
              }}
              className={pathname == itm.link ? "active" : ""}
              title={itm.title}
              icon={"icon-layout-11"}
              path={itm.link}
            />
          ))}
      </ul>
    </div>
  );
};

export default Drawer;
