import educationApi from "../../Shared/services/education";

export const EDUCATION_LOADING = "EDUCATION_LOADED";
export const EDUCATION_LOADED_SCCESS = "EDUCATION_LOADED_SUCCESS";
export const EDUCATION_LOADED_FAILED = "EDUCATION_LOADED_FAILED";

export const loadEducations = (dispatch) => {
  educationApi
    .get()
    .then((res) => {
      dispatch({
        type: EDUCATION_LOADED_SCCESS,
        payload: res.data.data,
      });
    })
    .catch(() => {
      dispatch({
        type: EDUCATION_LOADED_FAILED,
      });
    });
};
