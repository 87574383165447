import React from 'react'

const AcceptedApplicationModal = ({ renderModalBody = () => {} }) => {
  return (
    <>
      <div
        className="modal fade show modal-registration"
        id="exampleModal"
        tabIndex="-1"
        role="dialog"
        style={{ display: 'block' }}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          {renderModalBody()}
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </>
  )
}

export default AcceptedApplicationModal
