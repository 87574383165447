import React from "react";
import FileUpload from "../../Shared/services/media";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
// import EventBus from '../../Helpers/Request/Services/event-bus';
import "./style.css";

const style = {
  width: "85px",
  opacity: 1,
  margin: ".7em",
  border: "1px solid lightgray",
  borderRadius: "12px",
};
const fileBtn = React.createRef();
// const onImageChange = () => {}
class ImageUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: "",
      imagePreviewUrl: "",
      imageShow: false,
      inputFile: {},
      loading: false,
      fileBtn: {},
      onImageChange: () => {},
    };

    this.onSentMessage = this.props.onSentMessage;
    this._handleImageChange = this._handleImageChange.bind(this);
    this._handleSubmit = this._handleSubmit.bind(this);
    this.onClose = this.onClose.bind(this);
    this._handleFile = this._handleFile.bind(this);
    // EventBus.on('message-sent', () => {
    //   console.log('message recieved in child using even bus');
    //   this.setState({imageShow: false});
    //   this.setState({imagePreviewUrl: ""});
    //   this.setState({loading: false});
    // })
  }

  onSentMessage(data) {}

  _handleSubmit(e) {
    e.preventDefault();
    // TODO: do something with -> this.state.file
  }

  _handleFile(e) {
    e.preventDefault();
    this.state.fileBtn.current?.click();
  }

  _handleImageChange(e) {
    //   e.preventDefault();

    let reader = new FileReader();
    this.setState({ inputFile: e.target });
    this.setState({ loading: true });
    let file = e.target.files[0];
    let formData = new FormData();
    formData.append("image", file);

    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result,
        imageShow: true,
      });
    };

    reader.readAsDataURL(file);
    this.props.imageUploadStarts();
    FileUpload.upload(formData).then((res) => {
      this.props.onImageChange(res.data.image, file);
      this.setState({ loading: false });
    });
  }

  onClose() {
    this.setState({ inputFile: null });
    this.setState({ imagePreviewUrl: "" });
    this.setState({ imageShow: false });
    this.setState({ loading: false });
    this.props.onImageClosed("");
  }
  render() {
    //   let imagePreviewUrl = this.state.imagePreviewUrl;
    //   let $imagePreview = null;
    //   if (this.state.imagePreviewUrl) {
    //     $imagePreview = (<img src={imagePreviewUrl} />);
    //   }

    return (
      <div className="image--uploader--container flex align-items-start">
        {!this.state.imageShow ? (
          ""
        ) : (
          <div className="full--width">
            {" "}
            <i
              onClick={this.onClose}
              className="fa image--uploader--close fa-times-circle-o text-danger"
            ></i>{" "}
          </div>
        )}
        {this.state.loading ? (
          <div className="image--uploader--loading">
            <i className="centered fa fa-spinner fa-pulse"></i>
          </div>
        ) : (
          ""
        )}
        {this.state.imagePreviewUrl ? (
          <img style={style} src={this.state.imagePreviewUrl} alt="" />
        ) : (
          ""
        )}
        {!this.state.imageShow ? (
          <>
            {/* <button className="btn btn-default"> */}
            {/* <i  onClick={this._handleFile} className="fa fa-picture-o lg-fonts"></i> */}
            <FontAwesomeIcon
              icon={faPlusCircle}
              className={"lg-fonts"}
              onClick={this._handleFile}
            />
            {/* </button> */}
            <input
              ref={this.state.fileBtn}
              accept=".png, .jpg"
              id="image--uploader--button"
              className="class--hidden"
              type="file"
              onChange={this._handleImageChange}
            />
          </>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default ImageUpload;
