import React from 'react'

export default function Error({ message }) {
  if (message)
    return (
      <div
        style={{
          textAlign: 'center',
          padding: '1rem',
          background: '#976710',
          color: 'white',
          margin: '1rem',
          borderRadius: '11px',
        }}
      >
        {message}
      </div>
    )
  return null
}
