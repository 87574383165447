const convertDaysToUnits = (value) => {
  var year, months, week, days

  year = value >= 365 ? Math.floor(value / 365) : 0
  value = year ? value - year * 365 : value

  months = value >= 30 ? Math.floor((value % 365) / 30) : 0
  value = months ? value - months * 30 : value

  week = value >= 7 ? Math.floor((value % 365) / 7) : 0
  value = week ? value - week * 7 : value

  days = value < 7 ? Math.floor((value % 365) % 7) : 0

  return {
    days: checkZeroAndConvertToSingularPlural(days, 'day'),
    years: checkZeroAndConvertToSingularPlural(year, 'year'),
    months: checkZeroAndConvertToSingularPlural(months, 'month'),
    weeks: checkZeroAndConvertToSingularPlural(week, 'week'),
  }
}
const checkZeroAndConvertToSingularPlural = (value, unit) => {
  if (value == 0) {
    return ''
  }
  if (value == 1) {
    return `1 ${unit}`
  }
  return `${value} ${unit}s`
}
export const getDuration = (duration) => {
  let durationInDays = parseInt(duration) / 24

  let units = convertDaysToUnits(durationInDays)

  if (duration < 24) {
    return duration + ' hours'
  } else if (durationInDays % 365 == 0) {
    return durationInDays / 365 + ' year(s)'
  } else if (durationInDays % 30 == 0) {
    return durationInDays / 30 + ' months(s)'
  } else if (durationInDays % 7 == 0) {
    return durationInDays / 7 + ' week(s)'
  } else {
    return durationInDays + ' day(s)'
  }
}
