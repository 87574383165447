import React, { Component, Fragment, useState } from 'react'
import { Link } from 'react-router-dom'
import userApi from '../../Shared/services/user'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
import { logout } from '../../store/actions/user-action'
import { checkPasswordStrength } from '../../utils'
import Error from '../../components/Error'

const Resetpass = ({ onPressHide }) => {
  const [password, setpassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [error, setError] = useState(false)
  const dispatch = useDispatch()

  const history = useHistory()
  const resetPassword = () => {
    if (password === confirmPassword) {
      if (checkPasswordStrength(password)) {
        setError(false)
        console.log(history.location.search.replace('?', ''))
        const hash = history.location.search.replace('?hash=', '')
        console.log(hash, history.location)
        userApi
          .resetPassword(password, hash)
          .then((res) => {
            localStorage.clear()
            dispatch(logout)
            toast('Your password reset successfully,', { type: 'success' })
            history.push('/login')
          })
          .catch((err) => {
            toast(err.response.data.message, { type: 'warning' })
          })
      } else {
        setError(
          'Password must contain one capital letter, one numeric and be 8 characters in length'
        )
      }
    } else {
      toast('Password does not match', { type: 'warning' })
    }
  }

  return (
    <div
      className="container"
      style={{ marginTop: '130px', marginBottom: '50px' }}
    >
      <div className="rp-container">
        <div className="rep-wrap">
          <div className="d-flex flex-column">
            <h2 className="heading2 text-darker circular-normal">
              Reset your password
            </h2>
          </div>
          <div>
            <p className="p2-text text-greyz circular-medium mb-0">
              Please enter a new password
            </p>
          </div>
          {error && <Error message={error} />}
          <div className="pt-2 pb-4">
            <form action="" method="post">
              <div className="form-group position-relative">
                <label htmlFor="password" className="form-login-label">
                  <img src="./images/svgs/icons/lock.svg" />
                </label>
                <input
                  id="password"
                  className="form-control form-input1"
                  type="password"
                  name="password"
                  onChange={(e) => setpassword(e.target.value)}
                  placeholder="Password"
                  value={password}
                />
              </div>
              <div className="form-group position-relative">
                <label htmlFor="confirmpassword" className="form-login-label">
                  <img src="./images/svgs/icons/lock.svg" />
                </label>
                <input
                  id="confirmpassword"
                  className={`form-control form-input1 ${
                    password !== confirmPassword ? 'bdr-danger' : ''
                  }  `}
                  type="password"
                  name="confirmpassword"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  placeholder="Confirm Password"
                  value={confirmPassword}
                />
              </div>
              <div className="d-flex justify-content-between">
                <button
                  onClick={() => resetPassword()}
                  type="button"
                  className="btn btn-rgs btn-next mx-100 text-uppercase"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Resetpass
