import Moment from 'moment';

export const formatDate = (d) => {
  return Moment(d).format('MM-DD-YYYY')
};
export const formatToMMDDYY = (d) => {
  return Moment(d).format('MM/DD/YY')
};

export const toCalendarDate = (d) => {
  let date = new Date(d);
  let month = date.getMonth() + 1;
  month = month < 10 ? 0 + "" + month : month;
  let day = date.getDate();
  day = day < 10 ? "0" + day : day;
  let year = date.getFullYear();
  return `${month}/${day}/${year}`;
};

export const formatForInput = (_d) => {
  return _d.slice(0, 10);
};
