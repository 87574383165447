import React, { useEffect, useState } from "react";
import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";

import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  Search,
  Conversation,
  ConversationList,
  Avatar,
  ConversationHeader,
  VideoCallButton,
  VoiceCallButton,
  MessageSeparator,
  Sidebar,
  ExpansionPanel,
  InfoButton,
  TypingIndicator,
  Loader,
} from "@chatscope/chat-ui-kit-react";
import messageApi from "Shared/services/message";
import { useSelector } from "react-redux";
import moment from "moment";
import Loading from "components/Loading";
import { errorToast } from "components/Toast";

export const Chat = ({ toUserId, isUser }) => {
  const [messages, setMessages] = useState([]);
  const [messageInputValue, setMessageInputValue] = useState(
    ""
  );
  const [loading, setLoading] = useState(true);
  const [saveLoading, setSaveLoading] = useState(false);

  const { empProfile, user } = useSelector((state) => state.auth);
  const groupId = isUser
    ? "message" + toUserId + user.ID
    : "message" + user.ID + toUserId;
  useEffect(() => {
    getMessages();
  }, []);
  const getMessages = async () => {
    try {
      const response = await messageApi.getMessagesByGroupId(groupId);
      console.log("sendd", response);
      setMessages(response.data);
    } catch (error) {
      console.log("error", error);
      errorToast("Something went wrong retrieveing messages, please try again");
    }
    setLoading(false);
  };

  const sendMessage = async () => {
    setSaveLoading(true);
    try {
      const response = await messageApi.createMessage({
        groupId: groupId,
        toUserId,
        message: messageInputValue,
      });

      setMessages((prev) => [...prev, response.data.data]);
      setMessageInputValue("");
    } catch (error) {
      console.log("error", error);
      errorToast("Something went wrong sending the message, please try again");
    }
    setSaveLoading(false);
  };

  return (
    <div style={{ position: "relative", height: "500px" }}>
      {loading ? (
        <Loading size={40} />
      ) : (
        <MainContainer responsive>
          <ChatContainer>
            <MessageList>
              {/* <MessageSeparator content="Saturday, 30 November 2019" /> */}

              {messages.map((item) => {
                return (
                  <Message
                    key={item.id}
                    model={{
                      message: item.message,
                      sentTime: "15 mins ago",
                      sender: "Rahul",
                      direction:
                        item.fromUserId == toUserId ? "incoming" : "outgoing",
                      position: "single",
                    }}
                  >
                    <Message.Header
                      sentTime={moment(item.createdAt).format(
                        "ddd DD MMMM hh:mm"
                      )}
                    />
                  </Message>
                );
              })}
              {saveLoading && (
                <Message
                  model={{
                    type: "custom",
                    direction: "outgoing",
                  }}
                >
                  <Message.CustomContent>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div
                        className="cs-message__html-content"
                        style={{ margin: 0, marginRight: "0.5rem" }}
                      >
                        {messageInputValue}
                      </div>
                      <Loading
                        style={{ margin: 0, color: "white" }}
                        size={20}
                        color="white"
                      />
                    </div>
                  </Message.CustomContent>
                </Message>
              )}
            </MessageList>
            <MessageInput
              placeholder="Type message here"
              value={messageInputValue}
              onChange={(val) => setMessageInputValue(val)}
              onSend={sendMessage}
            />
          </ChatContainer>

          {/* <Sidebar position="right">
   <ExpansionPanel title="MEDIA">
     <p>No Media</p>
   </ExpansionPanel>
 </Sidebar> */}
        </MainContainer>
      )}
    </div>
  );
};
